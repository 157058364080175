import * as types from '../constants/actionTypes';
const initialState = {
	datePicker: {
		data: [],
	},
};

const DatePicker = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.HANDLE_DATE_PICKER:
			return {
				...state,
				datePicker: { data: payLoad },
			};

		default:
			return state;
	}
};

export default DatePicker;
