import * as types from '../constants/actionTypes';

export const getTrackingOptions = () => {
	return {
		type: types.GET_TRACKING_OPTIONS,
	};
};
export const getTrackingOptionsSuccess = data => {
	return {
		type: types.GET_TRACKING_OPTIONS_SUCCESS,
		payLoad: data,
	};
};
export const getTrackingOptionsFailure = () => {
	return {
		type: types.GET_TRACKING_OPTIONS_FAILURE,
	};
};

export const saveTrackingOptions = data => {
	return {
		type: types.SAVE_TRACKING_OPTIONS,
		payLoad: data,
	};
};
export const saveTrackingOptionsSuccess = data => {
	return {
		type: types.SAVE_TRACKING_OPTIONS_SUCCESS,
		payLoad: data,
	};
};
export const saverackingOptionsFailure = () => {
	return {
		type: types.SAVE_TRACKING_OPTIONS_FAILURE,
	};
};

export const getTrialExamOptions = (data) => {
	return {
		type: types.GET_TRIAL_EXAM_OPTIONS,
		payLoad: data,
	};
};
export const getTrialExamOptionsSuccess = data => {
	return {
		type: types.GET_TRIAL_EXAM_OPTIONS_SUCCESS,
		payLoad: data,
	};
};
export const getTrialExamOptionsFailure = () => {
	return {
		type: types.GET_TRIAL_EXAM_OPTIONS_FAILURE,
	};
};

export const clearTrialExamOptions = () => {
	return {
		type: types.CLEAR_TRIAL_EXAM_OPTIONS,
	};
};

export const getTrialExamRanking = (data) => {
	return {
		type: types.GET_TRIAL_EXAM_RANKING,
		payLoad: data,
	};
};
export const getTrialExamRankingSuccess = data => {
	return {
		type: types.GET_TRIAL_EXAM_RANKING_SUCCESS,
		payLoad: data,
	};
};
export const getTrialExamRankingFailure = () => {
	return {
		type: types.GET_TRIAL_EXAM_RANKING_FAILURE,
	};
};

export const getTrialExamSummarySessions = (data) => {
	return {
		type: types.GET_TRIAL_EXAM_SUMMARY_SESSIONS,
		payLoad: data,
	};
};
export const getTrialExamSummarySessionsSuccess = data => {
	return {
		type: types.GET_TRIAL_EXAM_SUMMARY_SESSIONS_SUCCESS,
		payLoad: data,
	};
};
export const getTrialExamSummarySessionsFailure = () => {
	return {
		type: types.GET_TRIAL_EXAM_SUMMARY_SESSIONS_FAILURE,
	};
};
export const emptyTrialExamSummarySessions = (data) => {
	return {
		type: types.EMPTY_TRIAL_EXAM_SUMMARY_SESSIONS,
		payLoad: data,
	};
};

export const getTrialExamsRank = (data) => {
	return {
		type: types.GET_TRIAL_EXAMS_RANK,
		payLoad: data,
	};
};
export const getTrialExamsRankSuccess = data => {
	return {
		type: types.GET_TRIAL_EXAMS_RANK_SUCCESS,
		payLoad: data,
	};
};
export const getTrialExamsRankFailure = () => {
	return {
		type: types.GET_TRIAL_EXAMS_RANK_FAILURE,
	};
};

export const getTrialExamSummary = (data) => {
	return {
		type: types.GET_TRIAL_EXAM_SUMMARY,
		payLoad: data,
	};
};
export const getTrialExamSummarySuccess = data => {
	return {
		type: types.GET_TRIAL_EXAM_SUMMARY_SUCCESS,
		payLoad: data,
	};
};
export const getTrialExamSummaryFailure = () => {
	return {
		type: types.GET_TRIAL_EXAM_SUMMARY_FAILURE,
	};
};

export const clearTrialExamSummary = () => {
	return {
		type: types.CLEAR_TRIAL_EXAM_SUMMARY,
	};
};

export const getUserPoints = (data) => {
	return {
		type: types.GET_USER_POINTS,
		payLoad: data,
	};
};

export const getUserPointsSuccess = data => {
	return {
		type: types.GET_USER_POINTS_SUCCESS,
		payLoad: data,
	};
};

export const getUserPointsFailure = () => {
	return {
		type: types.GET_USER_POINTS_FAILURE,
	};
};

export const clearUserPoints = () => {
	return {
		type: types.CLEAR_USER_POINTS,
	};
};

export const getTrackingList = () => {
	return {
		type: types.GET_TRACKING_LIST,
	};
};

export const getTrackingListSuccess = data => {
	return {
		type: types.GET_TRACKING_LIST_SUCCESS,
		payLoad: data,
	};
};

export const getTrackingListFailure = () => {
	return {
		type: types.GET_TRACKING_LIST_FAILURE,
	};
};

export const setOnlineTrialExamStatus = (data) => {
	return {
		type: types.SET_ONLINE_TRIAL_EXAM_STATUS,
		payLoad: data,
	};
}

export const setOnlineTrialExamStatusSuccess = data => {
	return {
		type: types.SET_ONLINE_TRIAL_EXAM_STATUS_SUCCESS,
		payLoad: data,
	};
};

export const setOnlineTrialExamStatusFailure = () => {
	return {
		type: types.SET_ONLINE_TRIAL_EXAM_STATUS_FAILURE,
	};
};

export const getOnlineTrialExamPdfs = (data) => {
	return {
		type: types.GET_ONLINE_TRIAL_EXAM_PDFS,
		payLoad: data,
	};
}

export const getOnlineTrialExamPdfsSuccess = data => {
	return {
		type: types.GET_ONLINE_TRIAL_EXAM_PDFS_SUCCESS,
		payLoad: data,
	};
};

export const setShowBookletListModal = data => {
	return {
		type: types.SET_SHOW_BOOKLET_LIST_MODAL,
		payLoad: data,
	};
}


export const getOnlineTrialExamPdfsFailure = () => {
	return {
		type: types.GET_ONLINE_TRIAL_EXAM_PDFS_FAILURE,
	};
};
