import * as React from "react";
const NoIdea = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		{...props}
	>
		<path
			opacity={0.1}
			d="M16.0001 29.3338C23.3334 29.3338 29.3334 23.3338 29.3334 16.0004C29.3334 8.66711 23.3334 2.66711 16.0001 2.66711C8.66675 2.66711 2.66675 8.66711 2.66675 16.0004C2.66675 23.3338 8.66675 29.3338 16.0001 29.3338Z"
			fill="#FDA420"
		/>
		<path
			d="M12.6823 13.5587C11.9734 13.1574 11.7736 12.156 12.2892 11.5009C12.8982 10.7223 13.9681 9.93003 15.7759 10.0055C18.0831 10.1049 20.0843 11.7958 19.9973 14.1041C19.936 15.7092 18.7792 16.6421 17.2775 17.2526C16.7458 17.4755 16.7136 17.9351 16.691 18.4736C16.6717 18.9812 16.2721 19.3756 15.7952 19.355L14.9799 19.3208C14.503 19.3002 14.1324 18.8749 14.1518 18.3673L14.184 17.4995C14.2098 16.7793 14.2645 16.1928 14.9735 15.7469C15.937 15.1467 16.8135 15.1364 16.8586 13.9737C16.8876 13.1883 16.3559 12.6087 15.6631 12.5778C15.0347 12.5504 14.5803 12.8488 14.2645 13.2363C13.8714 13.7234 13.2173 13.864 12.6823 13.5622V13.5587ZM17.0423 22.2086C17.0036 23.241 16.1883 24.0401 15.2023 23.999C14.2323 23.9578 13.4815 23.0901 13.5202 22.0577C13.5588 21.0116 14.3741 20.2091 15.3441 20.2502C16.3269 20.2914 17.081 21.1591 17.0423 22.2086Z"
			fill="#FDA420"
		/>
	</svg>
);
export default NoIdea;
