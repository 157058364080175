import { useSelector } from "react-redux";
export const useCourseSelector = () => {
    const reducer = useSelector((state) => state.courseReducer);
    return reducer;
  };
  
  export const useStatisticSelector = () => {
    const reducer = useSelector((state) => state.solvedQuestionsReducer);
    return reducer;
  };
  
  export const useKttSelector = () => {
    const reducer = useSelector((state) => state.KttReducer);
    return reducer;
  };

export const createHomeworkPopupActiveTab = state => state.SchoolPanel.createHomeworkPopupActiveTab;
