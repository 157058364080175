import axios from 'axios';
import { History } from './history';
import { clearLocalStorage } from './storage';
import { deleteCookie } from './cookie';

export const apiCaller = (config, isTeacher) => {
	let needToken = true;
	const studentToken = localStorage.getItem("studentToken");
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	let token;
	if(isTeacher) {
		token = userInfo?.JwtToken ?? userInfo;
	} else {
		if(studentToken) {
			token = studentToken;
		} else {
			token = userInfo?.JwtToken ?? userInfo;
		}
	}
	const isErrorHandled = config.isErrorHandled;
	if (config) {
		// needToken is true by default
		needToken = config.needToken === undefined ? true : config.needToken;
	}

	const axiosInstance = axios.create({
		headers: {
			'Content-Type': 'application/json-patch+json',
			'Access-Control-Allow-Origin': '*',
		},
		responseType:'json',
	});

	axiosInstance.interceptors.request.use(
		config => {
			if (needToken && !token) {
				History.replace('/login');
				return false;
			}
			if (token && needToken) {
				config.headers['Authorization'] = `Bearer ${token}`;
			}
			return config;
		},
		error => {
			Promise.reject(error);
		},
	);
	axiosInstance.interceptors.response.use(
		response => {
			return response;
		},
		error => {
			if (error) {
				if (error.response && error.response.status === 401) {
					deleteCookie('pakodemyToken', [
						".pakodemy.com",
						".web.pakodemy.com",
						".devweb.pakodemy.com",
						"localhost",
					]);
					clearLocalStorage();
					History.push('/login');
				}
				const { response, config, data } = error;
				const originalRequest = config;
				const validPages = ['/login', '/register', '/forget-password','/codeLogin'];
				if (
					!token &&
					originalRequest &&
					!originalRequest._retry &&
					validPages.indexOf(window.location.pathname) === -1
				) {
					deleteCookie('pakodemyToken', [
						".pakodemy.com",
						".web.pakodemy.com",
						".devweb.pakodemy.com",
						"localhost",
					]);
					clearLocalStorage();
					History.push('/login');
				}
				if (response) {
					if (isErrorHandled) return Promise.resolve(response);
					return Promise.reject(response);
				}
				if (data) {
					if (isErrorHandled) return Promise.resolve(data);
					return Promise.reject(data);
				}
				if (isErrorHandled) return Promise.resolve(error);
				return Promise.reject(error);
			}
		},
	);

	return axiosInstance;
};
