import styled from 'styled-components';

export const NotFoundWrapper = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.not-found-img {
		width: 500px;
		height: 308px;
		background: #fff url('/assets/404.svg') center center;
	}

	.not-found-title {
		font-size: 22px;
		font-weight: 600;
		color: #38327c;
		width: 100%;
		text-align: center;
	}

	.not-found-description {
		font-size: 15px;
		font-weight: 500;
		color: #6e6db3;
		width: 100%;
		text-align: center;
		max-width: 586px;
		margin: 24px 0 31px 0;
		height: 52px;
	}
`;
