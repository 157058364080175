// reducers/mediaFileReducer.js
import * as types from '../constants/actionTypes';
import { CLEAR_FILE } from '../constants/actionTypes';

const initialState = {
	uploadFile: {
		loading: false,
		data: [],
		failed: false,
	},
};

const MediaFile = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.UPLOAD_FILE:
			return { ...state, uploadFile: { ...state.uploadFile, loading: true } };
		case types.UPLOAD_FILE_SUCCESS:
			return { ...state, uploadFile: { data: payload, loading: false, failed: false } };
		case types.UPLOAD_FILE_FAILURE:
			return { ...state, uploadFile: { ...state.uploadFile, loading: false, failed: true } };
		case types.CLEAR_UPLOAD_FILE:
			return { ...state, uploadFile: { data: [], loading: false, failed: false } };
		default:
			return state;
	}
};

export default MediaFile;
