import * as AccountAction from './account';
import * as DashboardAction from './dashboard';
import * as UserStatisticAction from './userStatistic';
import * as BookAction from './book';
import * as TestSectionAction from './testSection';
import * as QuestionAction from './question';
import * as PublisherAction from './publishers';
import * as AccountProfileAction from './accountProfile';
import * as LectureAction from './lecture';
import * as LectureSectionAction from './lectureSection';
import * as DailyTaskAction from './dailyTask';
import * as SchoolPanelAction from './schoolPanel';
import * as DatePickerAction from './datePicker';
import * as ExamTypeAction from './examType';
import * as NavBarAction from './navBar';
import * as GptResponseAction from './gptResponse';
import * as AdmissionAction from './admission';
import * as TrialExamAction from './trialExam';
import * as CoachingAction from './coaching';
import * as PhoneConfirmationAction from './phoneConfirmation';
import * as TrialExamsAction from './trialExams';
import * as TrackingAction from './tracking';
import * as ContentAction from './content';
import * as PrintableTestAction from './printableTest';
import * as CoursesAction from './courses';
import * as MediaFileAction from './mediaFile';

export {
	AccountAction,
	DashboardAction,
	UserStatisticAction,
	BookAction,
	TestSectionAction,
	QuestionAction,
	PublisherAction,
	AccountProfileAction,
	LectureAction,
	LectureSectionAction,
	DailyTaskAction,
	SchoolPanelAction,
	DatePickerAction,
	ExamTypeAction,
	NavBarAction,
	GptResponseAction,
	AdmissionAction,
	TrialExamAction,
	CoachingAction,
	PhoneConfirmationAction,
	TrialExamsAction,
	TrackingAction,
	ContentAction,
	PrintableTestAction,
	CoursesAction,
	MediaFileAction,
};
