import { PAYMENT_ACTIONS } from '../constants/actionTypes';
const initialState = {
	activeCredits: [],
	creditCountFromAd: 0,
};

const paymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case PAYMENT_ACTIONS.SetActiveCredits:
			let credits = action.data.ResponseData;
			if (action.data.ResponseStatus === 0) credits = [];
			return {
				...state,
				activeCredits: credits,
			};
		default:
			return state;
	}
};

export default paymentReducer;
