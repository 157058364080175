export const homeworkStatusTitle = {
	1: 'Taslak',
	2: 'Hazır',
	3: 'Gönderildi',
	4: 'Süresi Doldu',
	5: 'İncelendi',
	6: 'Kontrol Edildi',
};

export const FileUploadStatus = {
	Uploading: 1,
	Uploaded: 2,
	Failed: 3,
};

export const TabsStep = {
	HomeworkInfo: 1,
	SelectStudent: 2,
	Deadline: 3,
	Summary: 4,
	Send: 5,
};

export const HomeworkType = {
	None: 0,
	Pdf: 1,
	Free: 2,
	File: 3,
	Description: 4,
};

export const HomeworkUpdateType = {
	None: 0,
	Content: 1,
	Student: 2,
	EndDate: 3,
};

export const HomeworkStatus = {
	None: 0,
	Drafts: 1,
	Ready: 2,
	Sent: 3,
	Expired: 4,
	Reviewed: 5,
};

export const SelectableItemListType = {
	Course: 1,
	Topic: 2,
	SubTopic: 3,
	Count: 4,
};

export const OrderHomework = {
	None: 0,
	Asc: 1,
	Desc: 2,
};

export const OrderTypes = {
	None: 0,
	Correct: 1,
	TotalQuestion: 2,
};

export const SendHomeworkTabs = {
	SendOption: 1,
	NotificationOption: 2,
};