export default {
	colors: {
		white: '#fff',
		black: '#000',
		orange: {
			400: '#F2A83B',
		},
		gray: {
			100: '#F2F2F2',
			150: '#E8E8E8',
			200: '#AEAEAE',
			300: '#777A89',
			400: '#686868',
			500: '#4D4D4D',
			600: '#424242',
		},
		green: {
			100: '#F3FCF8',
			200: '#c6f6d5',
			300: '#9ae6b4',
			400: '#63D8A6',
			500: '#48bb78',
			600: '#38a169',
			700: '#2f855a',
			800: '#276749',
			900: '#22543d',
		},
		blue: {
			300: '#90cdf4',
			800: '#1e42d3',
		},
		red: {
			100: '#F6F8FF',
			200: '#FFF2F9',
			300: '#feb2b2',
			400: '#fc8181',
			500: '#FF5A4C',
			600: '#e53e3e',
			700: '#c53030',
			800: '#9b2c2c',
			900: '#742a2a',
		},
	},
	fontSize: {
		ss: '0.5rem',
		xs: '0.75rem',
		sm: '0.875rem',
		base: '1rem',
		lg: '1.125rem',
		xl: '1.25rem',
		'2xl': '1.5rem',
		'3xl': '1.875rem',
		'4xl': '2.25rem',
		'5xl': '3rem',
		'6xl': '4rem',
	},
	fontWeight: {
		hairline: '100',
		thin: '200',
		light: '300',
		normal: '400',
		medium: '500',
		semibold: '600',
		bold: '700',
		extrabold: '800',
		black: '900',
	},
	height: {
		height: '83vh',
	},
};
