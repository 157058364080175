export const initialState = {
	TYTCourses: [],
	AYTCourses: [],
	LGSCourses: [],
	KPSSCourses: [],
	getDetails: {
		loading: false,
		data: [],
	},
	getUserSolvedProgress: {
		loading: false,
		data: [],
	},
};

const Courses = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_TYT_COURSES':
			return {
				...state,
				TYTCourses: action.data,
			};
		case 'SET_AYT_COURSES':
			return {
				...state,
				AYTCourses: action.data,
			};
		case 'SET_LGS_COURSES':
			return {
				...state,
				LGSCourses: action.data,
			};
		case 'SET_KPSS_COURSES':
			return {
				...state,
				KPSSCourses: action.data,
			};
		case 'GET_DETAILS':
			return {
				...state,
				getDetails: { ...state.getDetails, loading: true },
			};
		case 'GET_DETAILS_SUCCESS':
			return {
				...state,
				getDetails: { data: action.data, loading: false },
			};
		case 'GET_DETAILS_FAIL':
			return {
				...state,
				getDetails: { ...state.getDetails, loading: false },
			};
		case 'GET_USER_SOLVED_PROGRESS':
			return {
				...state,
				getUserSolvedProgress: { ...state.getUserSolvedProgress, loading: true },
			};
		case 'GET_USER_SOLVED_PROGRESS_SUCCESS':
			return {
				...state,
				getUserSolvedProgress: { data: action.data, loading: false },
			};
		case 'GET_USER_SOLVED_PROGRESS_FAIL':
			return {
				...state,
				getUserSolvedProgress: { ...state.getUserSolvedProgress, loading: false },
			};
		default:
			return state;
	}
};

export default Courses;
