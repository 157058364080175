import { apiCaller } from '../helpers/apiCaller';

export const getDashboardInfo = postData => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/Dashboard/gettodayscheduletasks`,
		postData
	);
};
export const whatsAppInfo = () => {
	return apiCaller({ needToken: false }).get(`${process.env.REACT_APP_API_URL}/api/whatsApp`);
};
