import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as MediaFileApi from '../apis/mediaFile';
import { uploadFileSuccess, uploadFileFailure } from '../actions/mediaFile';
import { toast } from 'react-toastify';

export function* watcherMediaFile() {
	yield takeLatest(types.UPLOAD_FILE, workerUploadFile);
}

function* workerUploadFile(action) {
	try {
		const { formData } = action.payload;
		const response = yield call(MediaFileApi.uploadFile, formData);
		yield put(uploadFileSuccess(response.data?.responseData));
	} catch (error) {
		toast.error('Dosya yüklenirken bir hata oluştu');
		yield put(uploadFileFailure(error.message));
	}
}
