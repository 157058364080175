import * as React from "react";
const LikeOutlined = (props) => (
	<svg
		width={24}
		height={26}
		viewBox="0 0 12 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3.37371 5.35423L1.97605 5.27805C1.71661 5.26391 1.49482 5.46276 1.48068 5.7222L1.26968 9.59315C1.25554 9.85259 1.4544 10.0744 1.71384 10.0885L3.1115 10.1647C3.37094 10.1788 3.59272 9.97999 3.60686 9.72055L3.81786 5.84959C3.832 5.59015 3.63315 5.36837 3.37371 5.35423Z"
			fill="#00DBA2"
		/>
		<path
			d="M9.65228 6.15479L9.5693 6.14976C9.97822 6.17156 10.3311 5.85652 10.3534 5.44598C10.3752 5.03705 10.0601 4.68416 9.64957 4.66193L7.26453 4.53228C7.58418 4.08441 8.02408 3.36758 8.05682 2.76366C8.1084 1.80682 7.61456 1.24768 7.27595 1.16899C6.93733 1.09031 6.67724 1.25812 6.5636 1.5409C6.44997 1.82368 6.59344 2.38016 6.51643 2.57121C6.39615 2.86598 5.84828 3.24065 5.08351 4.11654C4.40216 4.89947 4.43987 5.86992 4.43987 5.86992L4.36847 7.18975L4.29707 8.50958L4.26335 9.12357C4.2502 9.3782 4.44612 9.59763 4.70075 9.61079L9.13117 9.85307C9.4281 9.86895 9.68398 9.63978 9.69944 9.34446C9.71532 9.04753 9.48614 8.79165 9.19082 8.77619L9.26695 8.7811C9.62995 8.80092 9.94275 8.51988 9.96257 8.15689C9.98239 7.79389 9.70134 7.48109 9.33835 7.46127L9.58088 7.47462C9.94387 7.49444 10.2567 7.2134 10.2765 6.8504C10.2963 6.48741 10.0153 6.17461 9.65228 6.15479Z"
			fill="#00DBA2"
		/>
	</svg>
);
export default LikeOutlined;
