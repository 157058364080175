import QuestionMarkerType from '../../../enums/QuestionMarkerType';
import {
	FavoriteOutlined,
	LikeOutlined,
	NoIdeaOutlined, NoneOutlined,
	PassDirectOutlined, TakesTimeOutlined,
} from '../../../components/Icons/markedQuestions';
import React from 'react';

export const getQuestionMarkIconOutlined = (markTypeId) => {
	switch (markTypeId) {
		case QuestionMarkerType.Done:
			return <LikeOutlined />;
		case QuestionMarkerType.Skip:
			return <PassDirectOutlined />;
		case QuestionMarkerType.Favorite:
			return <FavoriteOutlined />;
		case QuestionMarkerType.NoIdea:
			return <NoIdeaOutlined />;
		case QuestionMarkerType.TakeTime:
			return <TakesTimeOutlined />;
		default:
			return <NoneOutlined />;
	}
};