import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as lectureApi from '../apis/lecture';
import { LectureAction, LectureSectionAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherLecture() {
	yield takeLatest(types.GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_REQUEST, workerGetLectureBySubCourseTopicId);
	yield takeLatest(types.GET_LECTURE_INFO_BY_LECTURE_ID_REQUEST, workerGetLectureInfoByLectureId);
}

function getLectureBySubCourseTopicId(subCourseTopicId) {
	return lectureApi.getLectureBySubCourseTopicId(subCourseTopicId);
}
function* workerGetLectureBySubCourseTopicId(action) {
	try {
		const response = yield call(getLectureBySubCourseTopicId, action.subCourseTopicId);
		yield put(LectureAction.getLectureBySubCourseTopicIdSuccess(response.data));
		// yield put(LectureSectionAction.setLastSeenLectureOrder(response.data.LastSeenLectureOrder));
		sessionStorage.setItem('lastSeenLectureOrder', response.data.LastSeenLectureOrder);
		sessionStorage.setItem(response.data.SubCourseTopicId+'totalDuration', response.data.TotalDuration);
		let firstLectureData = {};
		if (response.data.LastSeenLectureOrder === 0) {
			firstLectureData = response.data.Lectures[0];
		} else {
			firstLectureData = response.data.Lectures.find(item => item.OrderNumber === response.data.LastSeenLectureOrder);
		}
		yield put(
			LectureSectionAction.getLecture({
				LectureId: firstLectureData.LectureId,
				OrderNumber: firstLectureData.OrderNumber,
			})
		);
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(LectureAction.getLectureBySubCourseTopicIdFailure());
	}
}

function getLectureInfoByLectureId(lectureId) {
	return lectureApi.getLectureInfoByLectureId(lectureId);
}
function* workerGetLectureInfoByLectureId(action) {
	try {
		const response = yield call(getLectureInfoByLectureId, action.lectureId);
		yield put(LectureAction.getLectureInfoByLectureIdSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(LectureAction.getLectureInfoByLectureIdFailure());
	}
}
