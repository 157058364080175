// START account
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const UPDATE_EMAIL_REQUEST = 'UPDATE_EMAIL_REQUEST';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAILURE = 'UPDATE_EMAIL_FAILURE';

export const SET_COUPON = 'SET_COUPON';
export const SET_LOTTERY = 'SET_LOTTERY';

export const SET_ACTIVE_CREDITS = 'SET_ACTIVE_CREDITS';
// END account

// START auth
export const GET_VERIFY_CODE_REQUEST = 'GET_VERIFY_CODE_REQUEST';
export const GET_VERIFY_CODE_SUCCESS = 'GET_VERIFY_CODE_SUCCESS';
export const GET_VERIFY_CODE_FAILURE = 'GET_VERIFY_CODE_FAILURE';
// END auth

// START dashboard
export const GET_DASHBOARD_INFO_REQUEST = 'GET_DASHBOARD_INFO_REQUEST';
export const GET_DASHBOARD_INFO_SUCCESS = 'GET_DASHBOARD_INFO_SUCCESS';
export const GET_DASHBOARD_INFO_FAILURE = 'GET_DASHBOARD_INFO_FAILURE';
// END dashboard

// START UserStatistic
export const GET_STUDY_STATISTICS_REQUEST = 'GET_STUDY_STATISTICS_REQUEST';
export const GET_STUDY_STATISTICS_SUCCESS = 'GET_STUDY_STATISTICS_SUCCESS';
export const GET_STUDY_STATISTICS_FAILURE = 'GET_STUDY_STATISTICS_FAILURE';

export const GET_USER_SOLVED_REQUEST = 'GET_USER_SOLVED_REQUEST';
export const GET_USER_SOLVED_SUCCESS = 'GET_USER_SOLVED_SUCCESS';
export const GET_USER_SOLVED_FAILURE = 'GET_USER_SOLVED_FAILURE';

export const GET_USER_SOLVED_SUMMARY_REQUEST = 'GET_USER_SOLVED_SUMMARY_REQUEST';
export const GET_USER_SOLVED_SUMMARY_SUCCESS = 'GET_USER_SOLVED_SUMMARY_SUCCESS';
export const GET_USER_SOLVED_SUMMARY_FAILURE = 'GET_USER_SOLVED_SUMMARY_FAILURE';

export const GET_USER_DAILY = 'GET_USER_DAILY';
export const GET_USER_DAILY_SUCCESS = 'GET_USER_DAILY_SUCCESS';

export const GET_ELEMENT_COUNT = 'GET_ELEMENT_COUNT';
export const GET_ELEMENT_COUNT_SUCCESS = 'GET_ELEMENT_COUNT_SUCCESS';
export const GET_ELEMENT_COUNT_FAILURE = 'GET_ELEMENT_COUNT_FAILURE';
export const GET_ELEMENT_SUMMARY = 'GET_ELEMENT_SUMMARY';
export const GET_ELEMENT_SUMMARY_SUCCESS = 'GET_ELEMENT_SUMMARY_SUCCESS';
export const GET_ELEMENT_SUMMARY_FAILURE = 'GET_ELEMENT_SUMMARY_FAILURE';
export const SET_SHOW_SUMMARY_QUESTIONS = 'SET_SHOW_SUMMARY_QUESTIONS';
// END UserStatistic

// START dailyTask
export const GET_DAILY_TASK_REQUEST = 'GET_DAILY_TASK_REQUEST';
export const GET_DAILY_TASK_SUCCESS = 'GET_DAILY_TASK_SUCCESS';
export const GET_DAILY_TASK_FAILURE = 'GET_DAILY_TASK_FAILURE';
export const ADD_DAILY_TASK_REQUEST = 'ADD_DAILY_TASK_REQUEST';
export const ADD_DAILY_TASK_SUCCESS = 'ADD_DAILY_TASK_SUCCESS';
export const ADD_DAILY_TASK_FAILURE = 'ADD_DAILY_TASK_FAILURE';
export const DELETE_DAILY_TASK_REQUEST = 'DELETE_DAILY_TASK_REQUEST';
export const DELETE_DAILY_TASK_SUCCESS = 'DELETE_DAILY_TASK_SUCCESS';
export const DELETE_DAILY_TASK_FAILURE = 'DELETE_DAILY_TASK_FAILURE';
export const GET_IS_ADDED_PLAN = 'GET_IS_ADDED_PLAN';
export const GET_IS_ADDED_PLAN_SUCCESS = 'GET_IS_ADDED_PLAN_SUCCESS';
export const GET_IS_ADDED_PLAN_FAILURE = 'GET_IS_ADDED_PLAN_FAILURE';
// END dailyTask

// START testSection
export const GET_TEST_SECTION_V4_REQUEST = 'GET_TEST_SECTION_V4_REQUEST';
export const GET_TEST_SECTION_V4_SUCCESS = 'GET_TEST_SECTION_V4_SUCCESS';
export const GET_TEST_SECTION_V4_FAILURE = 'GET_TEST_SECTION_V4_FAILURE';

export const UPDATE_TEST_SECTION_ELEMENT_REQUEST = 'UPDATE_TEST_SECTION_ELEMENT_REQUEST';
export const UPDATE_TEST_SECTION_ELEMENT_SUCCESS = 'UPDATE_TEST_SECTION_ELEMENT_SUCCESS';
export const UPDATE_TEST_SECTION_ELEMENT_FAILURE = 'UPDATE_TEST_SECTION_ELEMENT_FAILURE';

export const UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_REQUEST =
	'UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_REQUEST';
export const UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_SUCCESS =
	'UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_SUCCESS';
export const UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_FAILURE =
	'UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_FAILURE';

export const FINISH_SECTION_V2_REQUEST = 'FINISH_SECTION_V2_REQUEST';
export const FINISH_SECTION_V2_SUCCESS = 'FINISH_SECTION_V2_SUCCESS';
export const FINISH_SECTION_V2_FAILURE = 'FINISH_SECTION_V2_FAILURE';

export const CREATE_TEST_SECTION_V6_REQUEST = 'CREATE_TEST_SECTION_V6_REQUEST';
export const CREATE_TEST_SECTION_V6_SUCCESS = 'CREATE_TEST_SECTION_V6_SUCCESS';
export const CREATE_TEST_SECTION_V6_FAILURE = 'CREATE_TEST_SECTION_V6_FAILURE';

export const ADD_SOLVED_TEST_FROM_OUTSIDE_REQUEST = 'ADD_SOLVED_TEST_FROM_OUTSIDE_REQUEST';
export const ADD_SOLVED_TEST_FROM_OUTSIDE_SUCCESS = 'ADD_SOLVED_TEST_FROM_OUTSIDE_SUCCESS';
export const ADD_SOLVED_TEST_FROM_OUTSIDE_FAILURE = 'ADD_SOLVED_TEST_FROM_OUTSIDE_FAILURE';

export const CREATE_TEST_SECTION_ELEMENT_V2_REQUEST = 'CREATE_TEST_SECTION_ELEMENT_V2_REQUEST';
export const CREATE_TEST_SECTION_ELEMENT_V2_SUCCESS = 'CREATE_TEST_SECTION_ELEMENT_V2_SUCCESS';
export const CREATE_TEST_SECTION_ELEMENT_V2_FAILURE = 'CREATE_TEST_SECTION_ELEMENT_V2_FAILURE';

export const GET_TEST_SECTION_ELEMENT_REQUEST = 'GET_TEST_SECTION_ELEMENT_REQUEST';
export const GET_TEST_SECTION_ELEMENT_SUCCESS = 'GET_TEST_SECTION_ELEMENT_SUCCESS';
export const GET_TEST_SECTION_ELEMENT_FAILURE = 'GET_TEST_SECTION_ELEMENT_FAILURE';

export const SET_QUESTION_TO_STORE = 'SET_QUESTION_TO_STORE';
export const SET_ANSWER_TO_STORE = 'SET_ANSWER_TO_STORE';
export const GET_QUESTION_FROM_STORE = 'GET_QUESTION_FROM_STORE';
export const GET_ALL_QUESTION_FROM_STORE = 'GET_ALL_QUESTION_FROM_STORE';
export const CLEAR_QUESTION_FROM_STORE = 'CLEAR_QUESTION_FROM_STORE';

export const GET_FINISHED_TEST_SECTION_SUMMARY_REQUEST = 'GET_FINISHED_TEST_SECTION_SUMMARY_REQUEST';
export const GET_FINISHED_TEST_SECTION_SUMMARY_SUCCESS = 'GET_FINISHED_TEST_SECTION_SUMMARY_SUCCESS';
export const GET_FINISHED_TEST_SECTION_SUMMARY_FAILURE = 'GET_FINISHED_TEST_SECTION_SUMMARY_FAILURE';

export const GET_TEST_SECTION_COURSE_SUMMARY = 'GET_TEST_SECTION_COURSE_SUMMARY';
export const GET_TEST_SECTION_COURSE_SUMMARY_SUCCESS = 'GET_TEST_SECTION_COURSE_SUMMARY_SUCCESS';
export const GET_TEST_SECTION_COURSE_SUMMARY_FAILURE = 'GET_TEST_SECTION_COURSE_SUMMARY_FAILURE';

export const GET_TEST_SECTION_SUB_COURSE_SUMMARY = 'GET_TEST_SECTION_SUB_COURSE_SUMMARY';
export const GET_TEST_SECTION_SUB_COURSE_SUMMARY_SUCCESS = 'GET_TEST_SECTION_SUB_COURSE_SUMMARY_SUCCESS';
export const GET_TEST_SECTION_SUB_COURSE_SUMMARY_FAILURE = 'GET_TEST_SECTION_SUB_COURSE_SUMMARY_FAILURE';

export const GET_TEST_SECTION_SUMMARY_REQUEST = 'GET_TEST_SECTION_SUMMARY_REQUEST';
export const GET_TEST_SECTION_SUMMARY_SUCCESS = 'GET_TEST_SECTION_SUMMARY_SUCCESS';
export const GET_TEST_SECTION_SUMMARY_FAILURE = 'GET_TEST_SECTION_SUMMARY_FAILURE';
export const GET_TEST_SECTION_SUMMARY_REQUEST_V3 = 'GET_TEST_SECTION_SUMMARY_REQUEST_V3';
export const GET_TEST_SECTION_SUMMARY_SUCCESS_V3 = 'GET_TEST_SECTION_SUMMARY_SUCCESS_V3';
export const GET_TEST_SECTION_SUMMARY_FAILURE_V3 = 'GET_TEST_SECTION_SUMMARY_FAILURE_V3';

export const UPDATE_TEST_SECTION_V2_REQUEST = 'UPDATE_TEST_SECTION_V2_REQUEST';
export const UPDATE_TEST_SECTION_V2_SUCCESS = 'UPDATE_TEST_SECTION_V2_SUCCESS';
export const UPDATE_TEST_SECTION_V2_FAILURE = 'UPDATE_TEST_SECTION_V2_FAILURE';

export const DELETE_TEST_SECTION_REQUEST = 'DELETE_TEST_SECTION_REQUEST';
export const DELETE_TEST_SECTION_SUCCESS = 'DELETE_TEST_SECTION_SUCCESS';
export const DELETE_TEST_SECTION_FAILURE = 'DELETE_TEST_SECTION_FAILURE';

export const GET_USER_SCORE_NET = "GET_USER_SCORE_NET"
export const GET_USER_SCORE_NET_SUCCESS = "GET_USER_SCORE_NET_SUCCESS"
export const GET_USER_SCORE_NET_FAILURE = "GET_USER_SCORE_NET_FAILURE"

export const GET_ALL_USERS_SCORE_NET = "GET_ALL_USERS_SCORE_NET"
export const GET_ALL_USERS_SCORE_NET_SUCCESS = "GET_ALL_USERS_SCORE_NET_SUCCESS"
export const GET_ALL_USERS_SCORE_NET_FAILURE = "GET_ALL_USERS_SCORE_NET_FAILURE"

export const GET_TEST_SECTION_ELEMENTS = "GET_TEST_SECTION_ELEMENTS"
export const GET_TEST_SECTION_ELEMENTS_SUCCESS = "GET_TEST_SECTION_ELEMENTS_SUCCESS"
export const GET_TEST_SECTION_ELEMENTS_FAILURE = "GET_TEST_SECTION_ELEMENTS_FAILURE"

export const GET_SUB_COURSE_SUCCESS_LEVEL = "GET_SUB_COURSE_SUCCESS_LEVEL"
export const GET_SUB_COURSE_SUCCESS_LEVEL_SUCCESS = "GET_SUB_COURSE_SUCCESS_LEVEL_SUCCESS"
export const GET_SUB_COURSE_SUCCESS_LEVEL_FAILURE = "GET_SUB_COURSE_SUCCESS_LEVEL_FAILURE"

export const GET_LAST_UNFINISHED_TEST = "GET_LAST_UNFINISHED_TEST";
export const GET_LAST_UNFINISHED_TEST_SUCCESS = "GET_LAST_UNFINISHED_TEST_SUCCESS";
export const GET_LAST_UNFINISHED_TEST_FAILURE = "GET_LAST_UNFINISHED_TEST_FAILURE";

export const SET_SHOW_UNFINISHED_TEST_POPUP = "SET_SHOW_UNFINISHED_TEST_POPUP";
export const SET_SHOW_TAKE_AN_EXAM_POPUP = "SET_SHOW_TAKE_AN_EXAM_POPUP";

export const CREATE_ACHIEVEMENT_ELEMENT = "CREATE_ACHIEVEMENT_ELEMENT";
export const CREATE_ACHIEVEMENT_ELEMENT_SUCCESS = "CREATE_ACHIEVEMENT_ELEMENT_SUCCESS";
export const CREATE_ACHIEVEMENT_ELEMENT_FAILURE = "CREATE_ACHIEVEMENT_ELEMENT_FAILURE";
export const GET_QUESTION = "GET_QUESTION";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAILURE = "GET_QUESTION_FAILURE";
export const GET_LAST_THREE_ACHIEVEMENT_QUESTION = "GET_LAST_THREE_ACHIEVEMENT_QUESTION";
export const GET_LAST_THREE_ACHIEVEMENT_QUESTION_SUCCESS = "GET_LAST_THREE_ACHIEVEMENT_QUESTION_SUCCESS";
export const GET_LAST_THREE_ACHIEVEMENT_QUESTION_FAILURE = "GET_LAST_THREE_ACHIEVEMENT_QUESTION_FAILURE";
export const GET_TEST_SECTION_ELEMENT_BY_ID = "GET_TEST_SECTION_ELEMENT_BY_ID";
export const GET_TEST_SECTION_ELEMENT_BY_ID_SUCCESS = "GET_TEST_SECTION_ELEMENT_BY_ID_SUCCESS";
export const GET_TEST_SECTION_ELEMENT_BY_ID_FAILURE = "GET_TEST_SECTION_ELEMENT_BY_ID_FAILURE";
export const SET_CURRENT_ACHIEVEMENT_TEST_SECTION_ELEMENT_ID = "SET_CURRENT_ACHIEVEMENT_TEST_SECTION_ELEMENT_ID";
// END testSection

// START book
export const GET_NESTED_TITLES_REQUEST = 'GET_NESTED_TITLES_REQUEST';
export const GET_NESTED_TITLES_SUCCESS = 'GET_NESTED_TITLES_SUCCESS';
export const GET_NESTED_TITLES_FAILURE = 'GET_NESTED_TITLES_FAILURE';
export const GET_COURSES = 'GET_COURSES'
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS'
export const GET_COURSES_FAILURE = 'GET_COURSES_FAILURE'
export const GET_SUB_COURSE_TOPICS = 'GET_SUB_COURSE_TOPICS'
export const GET_SUB_COURSE_TOPICS_SUCCESS = 'GET_SUB_COURSE_TOPICS_SUCCESS'
export const GET_SUB_COURSE_TOPICS_FAILURE = 'GET_SUB_COURSE_TOPICS_FAILURE'
export const CHECK_LECTURE_CONTENT = 'CHECK_LECTURE_CONTENT'
export const CHECK_LECTURE_CONTENT_SUCCESS = 'CHECK_LECTURE_CONTENT_SUCCESS'
export const CHECK_LECTURE_CONTENT_FAILURE = 'CHECK_LECTURE_CONTENT_FAILURE'
export const GET_ACHIEVEMENTS = 'GET_ACHIEVEMENTS'
export const GET_ACHIEVEMENTS_SUCCESS = 'GET_ACHIEVEMENTS_SUCCESS'
export const GET_ACHIEVEMENTS_FAILURE = 'GET_ACHIEVEMENTS_FAILURE'
export const GET_ACHIEVEMENT_USER_PROGRESS = 'GET_ACHIEVEMENT_USER_PROGRESS'
export const GET_ACHIEVEMENT_USER_PROGRESS_SUCCESS = 'GET_ACHIEVEMENT_USER_PROGRESS_SUCCESS'
export const GET_ACHIEVEMENT_USER_PROGRESS_FAILURE = 'GET_ACHIEVEMENT_USER_PROGRESS_FAILURE'
export const UPDATE_ACHIEVEMENT_VIDEO_USER = 'UPDATE_ACHIEVEMENT_VIDEO_USER'
export const UPDATE_ACHIEVEMENT_VIDEO_USER_SUCCESS = 'UPDATE_ACHIEVEMENT_VIDEO_USER_SUCCESS'
export const UPDATE_ACHIEVEMENT_VIDEO_USER_FAILURE = 'UPDATE_ACHIEVEMENT_VIDEO_USER_FAILURE'
export const ADD_ACHIEVEMENT_VIDEO_DURATION = 'ADD_ACHIEVEMENT_VIDEO_DURATION'
export const ADD_ACHIEVEMENT_VIDEO_DURATION_SUCCESS = 'ADD_ACHIEVEMENT_VIDEO_DURATION_SUCCESS'
export const ADD_ACHIEVEMENT_VIDEO_DURATION_FAILURE = 'ADD_ACHIEVEMENT_VIDEO_DURATION_FAILURE'
export const RESET_ALL_DATA = 'RESET_ALL_DATA'
export const GET_ACHIEVEMENT_VIDEO_REWARD = 'GET_ACHIEVEMENT_VIDEO_REWARD'
export const GET_ACHIEVEMENT_VIDEO_REWARD_SUCCESS = 'GET_ACHIEVEMENT_VIDEO_REWARD_SUCCESS'
export const GET_ACHIEVEMENT_VIDEO_REWARD_FAILURE = 'GET_ACHIEVEMENT_VIDEO_REWARD_FAILURE'
export const GET_SUB_COURSES = 'GET_SUB_COURSES';
export const GET_SUB_COURSES_SUCCESS = 'GET_SUB_COURSES_SUCCESS';
export const GET_SUB_COURSES_FAILURE = 'GET_SUB_COURSES_FAILURE';
export const CLEAR_HOMEWORK_DATA = 'CLEAR_HOMEWORK_DATA';
export const GET_ACHIEVEMENT_REWARDED_GIFT = 'GET_ACHIEVEMENT_REWARDED_GIFT';
export const GET_ACHIEVEMENT_REWARDED_GIFT_SUCCESS = 'GET_ACHIEVEMENT_REWARDED_GIFT_SUCCESS';
export const GET_ACHIEVEMENT_REWARDED_GIFT_FAILURE = 'GET_ACHIEVEMENT_REWARDED_GIFT_FAILURE';
export const GET_ACHIEVEMENT_TEST_INSTRUCTION = 'GET_ACHIEVEMENT_TEST_INSTRUCTION';
export const GET_ACHIEVEMENT_TEST_INSTRUCTION_SUCCESS = 'GET_ACHIEVEMENT_TEST_INSTRUCTION_SUCCESS';
export const GET_ACHIEVEMENT_TEST_INSTRUCTION_FAILURE = 'GET_ACHIEVEMENT_TEST_INSTRUCTION_FAILURE';

export const SET_SHOW_ACHIEVEMENT_TEST_DETAIL_MODAL = 'SET_SHOW_ACHIEVEMENT_TEST_DETAIL_MODAL';
export const GET_ACHIEVEMENT_TEST_DETAIL = 'GET_ACHIEVEMENT_TEST_DETAIL';
export const GET_ACHIEVEMENT_REWARD = 'GET_ACHIEVEMENT_REWARD';
export const GET_ACHIEVEMENT_REWARD_SUCCESS = 'GET_ACHIEVEMENT_REWARD_SUCCESS';
export const GET_ACHIEVEMENT_REWARD_FAILURE = 'GET_ACHIEVEMENT_REWARD_FAILURE';
export const SHOW_ACHIEVEMENT_REWARD_ANIMATION = 'SHOW_ACHIEVEMENT_REWARD_ANIMATION';
export const SET_SHOW_TEST_INSTRUCTION_MODAL = 'SET_SHOW_TEST_INSTRUCTION_MODAL';
// END book

// START publishers
export const GET_ALL_PUBLISHERS_REQUEST = 'GET_ALL_PUBLISHERS_REQUEST';
export const GET_ALL_PUBLISHERS_SUCCESS = 'GET_ALL_PUBLISHERS_SUCCESS';
export const GET_ALL_PUBLISHERS_FAILURE = 'GET_ALL_PUBLISHERS_FAILURE';
// END publishers

// START accountProfile
export const GET_PUBLISHER_RATES_REQUEST = 'GET_PUBLISHER_RATES_REQUEST';
export const GET_PUBLISHER_RATES_SUCCESS = 'GET_PUBLISHER_RATES_SUCCESS';
export const GET_PUBLISHER_RATES_FAILURE = 'GET_PUBLISHER_RATES_FAILURE';

export const UPDATE_PUBLISHER_RATES_REQUEST = 'UPDATE_PUBLISHER_RATES_REQUEST';
export const UPDATE_PUBLISHER_RATES_SUCCESS = 'UPDATE_PUBLISHER_RATES_SUCCESS';
export const UPDATE_PUBLISHER_RATES_FAILURE = 'UPDATE_PUBLISHER_RATES_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const GET_PROFILE_INFO_REQUEST = 'GET_PROFILE_INFO_REQUEST';
export const GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS';
export const GET_PROFILE_INFO_FAILURE = 'GET_PROFILE_INFO_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const UPDATE_USER_EXAM_CATEGORY = 'UPDATE_USER_EXAM_CATEGORY';
export const UPDATE_USER_EXAM_CATEGORY_SUCCESS = 'UPDATE_USER_EXAM_CATEGORY_SUCCESS';
export const UPDATE_USER_EXAM_CATEGORY_FAILURE = 'UPDATE_USER_EXAM_CATEGORY_FAILURE';
// END accountProfile

// START lecture
export const GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_REQUEST = 'GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_REQUEST';
export const GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_SUCCESS = 'GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_SUCCESS';
export const GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_FAILURE = 'GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_FAILURE';

export const GET_LECTURE_INFO_BY_LECTURE_ID_REQUEST = 'GET_LECTURE_INFO_BY_LECTURE_ID_REQUEST';
export const GET_LECTURE_INFO_BY_LECTURE_ID_SUCCESS = 'GET_LECTURE_INFO_BY_LECTURE_ID_SUCCESS';
export const GET_LECTURE_INFO_BY_LECTURE_ID_FAILURE = 'GET_LECTURE_INFO_BY_LECTURE_ID_FAILURE';
// END lecture

// START lecture section
export const CREATE_LECTURE_SECTION_REQUEST = 'CREATE_LECTURE_SECTION_REQUEST';
export const CREATE_LECTURE_SECTION_SUCCESS = 'CREATE_LECTURE_SECTION_SUCCESS';
export const CREATE_LECTURE_SECTION_FAILURE = 'CREATE_LECTURE_SECTION_FAILURE';

export const GET_LECTURE_SECTION_REQUEST = 'GET_LECTURE_SECTION_REQUEST';
export const GET_LECTURE_SECTION_SUCCESS = 'GET_LECTURE_SECTION_SUCCESS';
export const GET_LECTURE_SECTION_FAILURE = 'GET_LECTURE_SECTION_FAILURE';

export const UPDATE_LECTURE_SECTION_REQUEST = 'UPDATE_LECTURE_SECTION_REQUEST';
export const UPDATE_LECTURE_SECTION_SUCCESS = 'UPDATE_LECTURE_SECTION_SUCCESS';
export const UPDATE_LECTURE_SECTION_FAILURE = 'UPDATE_LECTURE_SECTION_FAILURE';

// export const GET_LAST_SEEN_LECTURE_ORDER = 'GET_LAST_SEEN_LECTURE_ORDER';
// export const SET_LAST_SEEN_LECTURE_ORDER = 'sET_LAST_SEEN_LECTURE_ORDER';

export const GET_LECTURE_REQUEST = 'GET_LECTURE_REQUEST';
export const GET_LECTURE_SUCCESS = 'GET_LECTURE_SUCCESS';
export const GET_LECTURE_FAILURE = 'GET_LECTURE_FAILURE';
// END lecture section

export const PAYMENT_ACTIONS = {
	SetActiveCredits: 'SET_ACTIVE_CREDITS',
};
//start ktt Actions
export const KttActions = {
	SET_KTT_TOPIC_LIST: 'SET_KTT_TOPIC_LIST',
	SET_KTT_SELECTED_COURSE: 'SET_KTT_SELECTED_COURSE',
};
//end ktt Actions

//start course Actions
export const CourseActions = {
	SET_SELECTED_COURSE: 'SET_SELECTED_COURSE',
	SET_SELECTED_SUBTOPIC: 'SET_SELECTED_SUBTOPIC',
	SET_COURSES: 'SET_COURSES',
	SET_SUBTOPICS: 'SET_SUBTOPICS',
	SET_SUB_EXAM_TYPES: 'SET_SUB_EXAM_TYPES',
};
//end course Actions
//KTT Selected Topics start
export const KttSelectedActions = {
	SET_COURSE_ID: 'SET_COURSE_ID',
	SET_SUBCOURSE_TOPIC_ID: 'SET_SUBCOURSE_TOPIC_ID',
	SET_SUBCOURSE_TOPIC_NAME: 'SET_SUBCOURSE_TOPIC_NAME',
	SET_QUESTION_COUNT: 'SET_QUESTION_COUNT',
	SET_AVERAGE_DIFFICULTY: 'SET_AVERAGE_DIFFICULTY',
};
//KTT Selected Topics end

//School Panel Filters start
export const GET_SCHOOL_PANEL_FILTERS = 'GET_SCHOOL_PANEL_FILTERS';
export const GET_SCHOOL_PANEL_FILTERS_SUCCESS = 'GET_SCHOOL_PANEL_FILTERS_SUCCESS';
export const GET_SCHOOL_PANEL_FILTERS_FAILURE = 'GET_SCHOOL_PANEL_FILTERS_FAILURE';
export const ADD_SCHOOL_PANEL_FILTER = 'ADD_SCHOOL_PANEL_FILTER';
export const ADD_SCHOOL_PANEL_FILTER_SUCCESS = 'ADD_SCHOOL_PANEL_FILTER_SUCCESS';
export const ADD_SCHOOL_PANEL_FILTER_FAILURE = 'ADD_SCHOOL_PANEL_FILTER_FAILURE';
export const DELETE_SCHOOL_PANEL_FILTER = 'DELETE_SCHOOL_PANEL_FILTER';
export const DELETE_SCHOOL_PANEL_FILTER_SUCCESS = 'DELETE_SCHOOL_PANEL_FILTER_SUCCESS';
export const DELETE_SCHOOL_PANEL_FILTER_FAILURE = 'DELETE_SCHOOL_PANEL_FILTER_FAILURE';
export const ADD_SCHOOL_PANEL_FILTER_TAG = 'ADD_SCHOOL_PANEL_FILTER_TAG';
export const ADD_SCHOOL_PANEL_FILTER_TAG_SUCCESS = 'ADD_SCHOOL_PANEL_FILTER_TAG_SUCCESS';
export const ADD_SCHOOL_PANEL_FILTER_TAG_FAILURE = 'ADD_SCHOOL_PANEL_FILTER_TAG_FAILURE';
export const DELETE_SCHOOL_PANEL_FILTER_TAG = 'DELETE_SCHOOL_PANEL_FILTER_TAG';
export const DELETE_SCHOOL_PANEL_FILTER_TAG_SUCCESS = 'DELETE_SCHOOL_PANEL_FILTER_TAG_SUCCESS';
export const DELETE_SCHOOL_PANEL_FILTER_TAG_FAILURE = 'DELETE_SCHOOL_PANEL_FILTER_TAG_FAILURE';
export const EDIT_SCHOOL_PANEL_FILTER = 'EDIT_SCHOOL_PANEL_FILTER';
export const EDIT_SCHOOL_PANEL_FILTER_SUCCESS = 'EDIT_SCHOOL_PANEL_FILTER_SUCCESS';
export const EDIT_SCHOOL_PANEL_FILTER_FAILURE = 'EDIT_SCHOOL_PANEL_FILTER_FAILURE';
export const EDIT_SCHOOL_PANEL_FILTER_TAG = 'EDIT_SCHOOL_PANEL_FILTER_TAG';
export const EDIT_SCHOOL_PANEL_FILTER_TAG_SUCCESS = 'EDIT_SCHOOL_PANEL_FILTER_TAG_SUCCESS';
export const EDIT_SCHOOL_PANEL_FILTER_TAG_FAILURE = 'EDIT_SCHOOL_PANEL_FILTER_TAG_FAILURE';
export const HANDLE_SCHOOL_PANEL_FILTER_IDS = 'HANDLE_SCHOOL_PANEL_FILTER_IDS';
export const HANDLE_SCHOOL_PANEL_FILTER_IDS_SUCCESS = 'HANDLE_SCHOOL_PANEL_FILTER_IDS_SUCCESS';
export const HANDLE_SCHOOL_PANEL_FILTER_IDS_FAILURE = 'HANDLE_SCHOOL_PANEL_FILTER_IDS_FAILURE';
export const HANDLE_SCHOOL_PANEL_TAG_IDS = 'HANDLE_SCHOOL_PANEL_TAG_IDS';
export const HANDLE_SCHOOL_PANEL_TAG_IDS_SUCCESS = 'HANDLE_SCHOOL_PANEL_TAG_IDS_SUCCESS';
export const HANDLE_SCHOOL_PANEL_TAG_IDS_FAILURE = 'HANDLE_SCHOOL_PANEL_TAG_IDS_FAILURE';
export const HANDLE_SCHOOL_PANEL_USER_IDS = 'HANDLE_SCHOOL_PANEL_USER_IDS';
export const HANDLE_SCHOOL_PANEL_USER_IDS_SUCCESS = 'HANDLE_SCHOOL_PANEL_USER_IDS_SUCCESS';
export const HANDLE_SCHOOL_PANEL_USER_IDS_FAILURE = 'HANDLE_SCHOOL_PANEL_USER_IDS_FAILURE';
//School Panel Filters end

//School Panel Student start
export const GET_SCHOOL_PANEL_STUDENTS = 'GET_SCHOOL_PANEL_STUDENTS';
export const GET_SCHOOL_PANEL_STUDENTS_SUCCESS = 'GET_SCHOOL_PANEL_STUDENTS_SUCCESS';
export const GET_SCHOOL_PANEL_STUDENTS_FAILURE = 'GET_SCHOOL_PANEL_STUDENTS_FAILURE';
export const ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG = 'ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG';
export const ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG_SUCCESS = 'ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG_SUCCESS';
export const ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG_FAILURE = 'ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG_FAILURE';
export const DELETE_SCHOOL_PANEL_STUDENT_TAG = 'DELETE_SCHOOL_PANEL_STUDENT_TAG';
export const DELETE_SCHOOL_PANEL_STUDENT_TAG_SUCCESS = 'DELETE_SCHOOL_PANEL_STUDENT_TAG_SUCCESS';
export const DELETE_SCHOOL_PANEL_STUDENT_TAG_FAILURE = 'DELETE_SCHOOL_PANEL_STUDENT_TAG_FAILURE';
export const GET_CORPORATE_USER_SOLVED = 'GET_CORPORATE_USER_SOLVED';
export const GET_CORPORATE_USER_SOLVED_SUCCESS = 'GET_CORPORATE_USER_SOLVED_SUCCESS';
export const GET_CORPORATE_USER_SOLVED_FAILURE = 'GET_CORPORATE_USER_SOLVED_FAILURE ';
export const GET_CORPORATE_STUDY_STATISTICS = 'GET_CORPORATE_STUDY_STATISTICS';
export const GET_CORPORATE_STUDY_STATISTIC_SUCCESS = 'GET_CORPORATE_STUDY_STATISTICS_SUCCESS';
export const GET_CORPORATE_STUDY_STATISTIC_FAILURE = 'GET_CORPORATE_STUDY_STATISTICS_FAILURE';
export const HANDLE_STUDENTS_COUNT = 'HANDLE_STUDENTS_COUNT';
//School Panel Student end


//School Panel Homework start
export const GET_HOMEWORK_TYPE = 'GET_HOMEWORK_TYPE';
export const GET_HOMEWORK_TYPE_SUCCESS = 'GET_HOMEWORK_TYPE_SUCCESS';
export const GET_HOMEWORK_TYPE_FAILURE = 'GET_HOMEWORK_TYPE_FAILURE';
export const SET_SHOW_CREATE_HOMEWORK_POPUP = 'SET_SHOW_CREATE_HOMEWORK_POPUP';
export const SET_CREATE_HOMEWORK_POPUP_ACTIVE_TAB = 'SET_CREATE_HOMEWORK_POPUP_ACTIVE_TAB';
export const SET_CONFIRMED_SELECTED_HOMEWORK_TYPE = 'SET_CONFIRMED_SELECTED_HOMEWORK_TYPE';
export const CREATE_HOMEWORK = 'CREATE_HOMEWORK';
export const CREATE_HOMEWORK_SUCCESS = 'CREATE_HOMEWORK_SUCCESS';
export const CREATE_HOMEWORK_FAILURE = 'CREATE_HOMEWORK_FAILURE';
export const UPDATE_HOMEWORK = 'UPDATE_HOMEWORK';
export const UPDATE_HOMEWORK_SUCCESS = 'UPDATE_HOMEWORK_SUCCESS';
export const UPDATE_HOMEWORK_FAILURE = 'UPDATE_HOMEWORK_FAILURE';
export const GET_BY_ID = 'GET_BY_ID';
export const GET_BY_ID_SUCCESS = 'GET_BY_ID_SUCCESS';
export const GET_BY_ID_FAILURE = 'GET_BY_ID_FAILURE';
export const SET_PDF_TEST_CODE = 'SET_PDF_TEST_CODE';
export const SET_FREE_TEST_SELECTED_COURSE = 'SET_FREE_TEST_SELECTED_COURSE';
export const SET_FREE_TEST_SELECTED_COURSE_ID = 'SET_FREE_TEST_SELECTED_COURSE_TOPIC_ID';
export const SET_FREE_TEST_SELECTED_TOPIC = 'SET_FREE_TEST_SELECTED_TOPIC';
export const SET_FREE_TEST_SELECTED_TOPIC_ID = 'SET_FREE_TEST_SELECTED_TOPIC_ID';
export const SET_FREE_TEST_SELECTED_SUB_COURSE_TOPIC_ID = 'SET_FREE_TEST_SELECTED_SUB_COURSE_TOPIC_ID';
export const SET_FREE_TEST_SELECTED_COUNT = 'SET_FREE_TEST_SELECTED_COUNT';
export const SET_FREE_TEST_SELECTED_EXAM_TYPE = 'SET_FREE_TEST_SELECTED_EXAM_TYPE';
export const SET_SHARE_FILE_FILES = 'SET_SHARE_FILE_FILES';
export const SET_SHARE_FILE_FILES_URL = 'SET_SHARE_FILE_FILES_URL';
export const SET_SHARE_FILE_TITLE = 'SET_SHARE_FILE_TITLE';
export const SET_SHARE_FILE_DESCRIPTION = 'SET_SHARE_FILE_DESCRIPTION';
export const SET_SHARE_DESCRIPTION_TITLE = 'SET_SHARE_DESCRIPTION_TITLE';
export const SET_SHARE_DESCRIPTION_DESCRIPTION = 'SET_SHARE_DESCRIPTION_DESCRIPTION';
export const CLEAR_ALL_CREATE_HOMEWORK_POPUP_DATA = 'CLEAR_ALL_CREATE_HOMEWORK_POPUP_DATA';
export const GET_HOMEWORKS = 'GET_HOMEWORKS';
export const GET_HOMEWORKS_SUCCESS = 'GET_HOMEWORKS_SUCCESS';
export const GET_HOMEWORKS_FAILURE = 'GET_HOMEWORKS_FAILURE';
export const DELETE_HOMEWORK = 'DELETE_HOMEWORK';
export const DELETE_HOMEWORK_SUCCESS = 'DELETE_HOMEWORK_SUCCESS';
export const DELETE_HOMEWORK_FAILURE = 'DELETE_HOMEWORK_FAILURE';
export const CLONE_HOMEWORK = 'CLONE_HOMEWORK';
export const CLONE_HOMEWORK_SUCCESS = 'CLONE_HOMEWORK_SUCCESS';
export const CLONE_HOMEWORK_FAILURE = 'CLONE_HOMEWORK_FAILURE';
export const TRACING_BY_ID_HOMEWORK = 'TRACING_BY_ID_HOMEWORK';
export const TRACING_BY_ID_HOMEWORK_SUCCESS = 'TRACING_BY_ID_HOMEWORK_SUCCESS';
export const TRACING_BY_ID_HOMEWORK_FAILURE = 'TRACING_BY_ID_HOMEWORK_FAILURE';
export const INFO_BY_ID_HOMEWORK = 'INFO_BY_ID_HOMEWORK';
export const INFO_BY_ID_HOMEWORK_SUCCESS = 'INFO_BY_ID_HOMEWORK_SUCCESS';
export const INFO_BY_ID_HOMEWORK_FAILURE = 'INFO_BY_ID_HOMEWORK_FAILURE';
export const SEND_HOMEWORK_NOTIFICATION = 'SEND_HOMEWORK_NOTIFICATION';
export const SEND_HOMEWORK_NOTIFICATION_SUCCESS = 'SEND_HOMEWORK_NOTIFICATION_SUCCESS';
export const SEND_HOMEWORK_NOTIFICATION_FAILURE = 'SEND_HOMEWORK_NOTIFICATION_FAILURE';
export const ORDER_HOMEWORK = 'ORDER_HOMEWORK';
export const SET_ACTIVE_HOMEWORK_STATUS_TAB = 'SET_ACTIVE_HOMEWORK_STATUS_TAB';
export const SET_SHOW_HOMEWORK_INFO_POPUP = 'SET_SHOW_HOMEWORK_INFO_POPUP';
export const SET_IS_EDITABLE_HOMEWORK = 'SET_IS_EDITABLE_HOMEWORK';
export const GET_NOTIF_TYPES = 'GET_NOTIF_TYPES';
export const GET_NOTIF_TYPES_SUCCESS = 'GET_NOTIF_TYPES_SUCCESS';
export const GET_NOTIF_TYPES_FAILURE = 'GET_NOTIF_TYPES_FAILURE';
export const SET_ACTIVE_CONTENT = 'SET_ACTIVE_CONTENT';
export const HOMEWORK_SEND_SUCCESS_POPUP = 'HOMEWORK_SEND_SUCCESS_POPUP';
export const SET_FROM_HOMEWORK_CARD = 'SET_FROM_HOMEWORK_CARD';
export const SET_SHOULD_UPDATE_HOMEWORK = 'SET_SHOULD_UPDATE_HOMEWORK';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_SELECTED_STUDENT_USER_IDS = 'SET_SELECTED_STUDENT_USER_IDS';
export const UPDATE_HOMEWORK_STATUS = 'UPDATE_HOMEWORK_STATUS';
export const UPDATE_HOMEWORK_STATUS_SUCCESS = 'UPDATE_HOMEWORK_STATUS_SUCCESS';
export const UPDATE_HOMEWORK_STATUS_FAILURE = 'UPDATE_HOMEWORK_STATUS_FAILURE';
export const IS_UNAUTHORIZED_TEACHER = 'IS_UNAUTHORIZED_TEACHER';
export const SET_SHOW_GO_STUDENT_PROFILE_POP_UP = 'SET_SHOW_GO_STUDENT_PROFILE_POP_UP';
export const SET_SELECTED_STUDENT_USER_ID = 'SET_SELECTED_STUDENT_USER_ID';
//School Panel Homework end

//Date Picker start
export const HANDLE_DATE_PICKER = 'HANDLE_DATE_PICKER';
//Date Picker end

//Exam Type start
export const HANDLE_EXAM_TYPE = 'HANDLE_EXAM_TYPE';
//Exam Type end

//Navbar collapse onQuestionScreen start
export const HANDLE_COLLAPSE = 'HANDLE_COLLAPSE';

//Navbar collapse onQuestionScreen end

//Gpt response start
export const HANDLE_GPT_RESPONSE = 'HANDLE_GPT_RESPONSE';
//Gpt response start

//Admission start
export const GET_YKS_POINT = 'GET_YKS_POINT'
export const GET_YKS_POINT_SUCCESS = 'GET_YKS_POINT_SUCCESS'
export const GET_YKS_POINT_FAILURE = 'GET_YKS_POINT_FAILURE'
export const HANDLE_GRADE_POINT = 'HANDLE_GRADE_POINT'
export const HANDLE_GRADE_POINT_SUCCESS = 'HANDLE_GRADE_POINT_SUCCESS'
export const HANDLE_GRADE_POINT_FAILURE = 'HANDLE_GRADE_POINT_FAILURE'
export const SAVE_USER_RECORDS = 'SAVE_USER_RECORDS'
export const SAVE_USER_RECORDS_SUCCESS = 'SAVE_USER_RECORDS_SUCCESS'
export const SAVE_USER_RECORDS_FAILURE = 'SAVE_USER_RECORDS_FAILURE'
export const GET_LIST = 'GET_LIST'
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS'
export const GET_LIST_FAILURE = 'GET_LIST_FAILURE'
export const SAVE_INPUTS = 'SAVE_INPUTS'
export const POST_LIST = 'POST_LIST'
export const POST_LIST_SUCCESS = 'POST_LIST_SUCCESS'
export const POST_LIST_FAILURE = 'POST_LIST_FAILURE'
export const PREFERENCE_LIST= 'PREFERENCE_LIST'
export const REMOVE_FROM_PREFERENCE_LIST = 'REMOVE_FROM_PREFERENCE_LIST'
export const REMOVE_ALL_ITEMS_FROM_PREFERENCE_LIST = 'REMOVE_ALL_ITEMS_FROM_PREFERENCE_LIST'
export const ADD_OPTION = 'ADD_OPTION'
export const ADD_OPTION_SUCCESS = 'ADD_OPTION_SUCCESS'
export const ADD_OPTION_FAILURE = 'ADD_OPTION_FAILURE'
export const GET_OPTION = 'GET_OPTION'
export const GET_OPTION_SUCCESS = 'GET_OPTION_SUCCESS'
export const GET_OPTION_FAILURE = 'GET_OPTION_FAILURE'
export const REMOVE_FROM_GET_OPTION = 'REMOVE_FROM_GET_OPTION'
export const GET_RESULT = 'GET_RESULT'
export const GET_RESULT_SUCCESS = 'GET_RESULT_SUCCESS'
export const GET_RESULT_FAILURE = 'GET_RESULT_FAILURE'
export const GET_UNIVERSITY_RANKS = 'GET_UNIVERSITY_RANKS'
export const GET_UNIVERSITY_RANKS_SUCCESS = 'GET_UNIVERSITY_RANKS_SUCCESS'
export const GET_UNIVERSITY_RANKS_FAILURE = 'GET_UNIVERSITY_RANKS_FAILURE'
export const HANDLE_USER_RANKS = 'HANDLE_USER_RANKS'
export const HANDLE_SELECTED_CITIES = 'HANDLE_SELECTED_CITIES'
export const HANDLE_PROGRAM_TUITION_TYPE = 'HANDLE_PROGRAM_TUITION_TYPE'
export const HANDLE_PROGRAM_FEE = 'HANDLE_PROGRAM_FEE'
export const GET_ALL_RANK = 'GET_ALL_RANK';
export const GET_ALL_RANK_SUCCESS = 'GET_ALL_RANK_SUCCESS';
export const GET_ALL_RANK_FAILURE = 'GET_ALL_RANK_FAILURE';
export const GET_UNIVERSITY_DETAILS = 'GET_UNIVERSITY_DETAILS';
export const GET_UNIVERSITY_DETAILS_SUCCESS = 'GET_UNIVERSITY_DETAILS_SUCCESS';
export const GET_UNIVERSITY_DETAILS_FAILURE = 'GET_UNIVERSITY_DETAILS_FAILURE';
export const GET_UNIVERSITY_CONDITION_DETAILS = 'GET_UNIVERSITY_CONDITION_DETAILS';
export const GET_UNIVERSITY_CONDITION_DETAILS_SUCCESS = 'GET_UNIVERSITY_CONDITION_DETAILS_SUCCESS';
export const GET_UNIVERSITY_CONDITION_DETAILS_FAILURE = 'GET_UNIVERSITY_CONDITION_DETAILS_FAILURE';
//Admission start

//KTT Summary start
export const GET_TEST_SECTION_SUMMARY_ANALYTICS = 'GET_TEST_SECTION_SUMMARY_ANALYTICS'
export const GET_TEST_SECTION_SUMMARY_ANALYTICS_SUCCESS = 'GET_TEST_SECTION_SUMMARY_ANALYTICS_SUCCESS'
export const GET_TEST_SECTION_SUMMARY_ANALYTICS_FAILURE = 'GET_TEST_SECTION_SUMMARY_ANALYTICS_FAILURE'
//KTT Summary end

// TRIALS start
export const GET_TRACKING_OPTIONS = 'GET_TRACKING_OPTIONS';
export const GET_TRACKING_OPTIONS_SUCCESS = 'GET_TRACKING_OPTIONS_SUCCESS';
export const GET_TRACKING_OPTIONS_FAILURE = 'GET_TRACKING_OPTIONS_FAILURE';
export const SAVE_TRACKING_OPTIONS = 'SAVE_TRACKING_OPTIONS';
export const SAVE_TRACKING_OPTIONS_SUCCESS = 'SAVE_TRACKING_OPTIONS_SUCCESS';
export const SAVE_TRACKING_OPTIONS_FAILURE = 'SAVE_TRACKING_OPTIONS_FAILURE';
export const GET_TRIAL_EXAM_OPTIONS = 'GET_TRIAL_EXAM_OPTIONS';
export const GET_TRIAL_EXAM_OPTIONS_SUCCESS = 'GET_TRIAL_EXAM_OPTIONS_SUCCESS';
export const GET_TRIAL_EXAM_OPTIONS_FAILURE = 'GET_TRIAL_EXAM_OPTIONS_FAILURE';
export const CLEAR_TRIAL_EXAM_OPTIONS = 'CLEAR_TRIAL_EXAM_OPTIONS';
export const GET_TRIAL_EXAM_RANKING = 'GET_TRIAL_EXAM_RANKING';
export const GET_TRIAL_EXAM_RANKING_SUCCESS = 'GET_TRIAL_EXAM_RANKING_SUCCESS';
export const GET_TRIAL_EXAM_RANKING_FAILURE = 'GET_TRIAL_EXAM_RANKING_FAILURE';
export const GET_TRIAL_EXAM_SUMMARY_SESSIONS = 'GET_TRIAL_EXAM_SUMMARY_SESSIONS';
export const GET_TRIAL_EXAM_SUMMARY_SESSIONS_SUCCESS = 'GET_TRIAL_EXAM_SUMMARY_SESSIONS_SUCCESS';
export const GET_TRIAL_EXAM_SUMMARY_SESSIONS_FAILURE = 'GET_TRIAL_EXAM_SUMMARY_SESSIONS_FAILURE';
export const EMPTY_TRIAL_EXAM_SUMMARY_SESSIONS = 'EMPTY_TRIAL_EXAM_SUMMARY_SESSIONS';
export const GET_TRIAL_EXAMS_RANK = 'GET_TRIAL_EXAMS_RANK';
export const GET_TRIAL_EXAMS_RANK_SUCCESS = 'GET_TRIAL_EXAMS_RANK_SUCCESS';
export const GET_TRIAL_EXAMS_RANK_FAILURE = 'GET_TRIAL_EXAMS_RANK_FAILURE';
export const CREATE_TRIAL_EXAM_SECTION_ELEMENT = 'CREATE_TRIAL_EXAM_SECTION_ELEMENT';
export const CREATE_TRIAL_EXAM_SECTION_ELEMENT_SUCCESS = 'CREATE_TRIAL_EXAM_SECTION_ELEMENT_SUCCESS';
export const CREATE_TRIAL_EXAM_SECTION_ELEMENT_FAILURE = 'CREATE_TRIAL_EXAM_SECTION_ELEMENT_FAILURE';
export const GET_TRIAL_EXAM_SUMMARY = 'GET_TRIAL_EXAM_SUMMARY';
export const GET_TRIAL_EXAM_SUMMARY_SUCCESS = 'GET_TRIAL_EXAM_SUMMARY_SUCCESS';
export const GET_TRIAL_EXAM_SUMMARY_FAILURE = 'GET_TRIAL_EXAM_SUMMARY_FAILURE';
export const CLEAR_TRIAL_EXAM_SUMMARY = 'CLEAR_TRIAL_EXAM_SUMMARY';
export const GET_USER_POINTS = 'GET_USER_POINTS';
export const GET_USER_POINTS_SUCCESS = 'GET_USER_POINTS_SUCCESS';
export const GET_USER_POINTS_FAILURE = 'GET_USER_POINTS_FAILURE';
export const CLEAR_USER_POINTS = 'CLEAR_USER_POINTS';
export const GET_TRACKING_LIST = 'GET_TRACKING_LIST';
export const GET_TRACKING_LIST_SUCCESS = 'GET_TRACKING_LIST_SUCCESS';
export const GET_TRACKING_LIST_FAILURE = 'GET_TRACKING_LIST_FAILURE';


// TRIALS end

// COACHES start
export const GET_ALL_COACHES = 'GET_ALL_COACHES';
export const GET_ALL_COACHES_SUCCESS = 'GET_ALL_COACHES_SUCCESS';
export const GET_ALL_COACHES_FAILURE = 'GET_ALL_COACHES_FAILURE';
export const GET_USER_CAN_TAKE_COACH ='GET_USER_CAN_TAKE_COACH';
export const GET_USER_CAN_TAKE_COACH_SUCCESS = 'GET_USER_CAN_TAKE_COACH_SUCCESS';
export const GET_USER_CAN_TAKE_COACH_FAILURE = 'GET_USER_CAN_TAKE_COACH_FAILURE';
export const GET_USER_HAS_COACH = 'GET_USER_HAS_COACH';
export const GET_USER_HAS_COACH_SUCCESS = 'GET_USER_HAS_COACH_SUCCESS';
export const GET_USER_HAS_COACH_FAILURE = 'GET_USER_HAS_COACH_FAILURE';
export const TAKE_SEAT = 'TAKE_SEAT';
export const TAKE_SEAT_SUCCESS = 'TAKE_SEAT_SUCCESS';
export const TAKE_SEAT_FAILURE = 'TAKE_SEAT_FAILURE';
export const HANDLE_BOTTOM_CONTAINER = 'HANDLE_BOTTOM_CONTAINER';
export const GET_TUTORIAL = 'GET_TUTORIAL';
export const GET_TUTORIAL_SUCCESS = 'GET_TUTORIAL_SUCCESS';
export const GET_TUTORIAL_FAILURE = 'GET_TUTORIAL_FAILURE';
// COACHES end

// PHONE CONFIRMATION start
export const GET_USER_PHONE_NUMBER = 'GET_USER_PHONE_NUMBER';
export const GET_USER_PHONE_NUMBER_SUCCESS = 'GET_USER_PHONE_NUMBER_SUCCESS';
export const GET_USER_PHONE_NUMBER_FAILURE = 'GET_USER_PHONE_NUMBER_FAILURE';
export const GET_USER_PHONE_CONFIRMATION = 'GET_USER_PHONE_CONFIRMATION';
export const GET_USER_PHONE_CONFIRMATION_SUCCESS = 'GET_USER_PHONE_CONFIRMATION_SUCCESS';
export const GET_USER_PHONE_CONFIRMATION_FAILURE ='GET_USER_PHONE_CONFIRMATION_FAILURE';
export const GET_SMS_CODE = 'GET_SMS_CODE';
export const GET_SMS_CODE_SUCCESS = 'GET_SMS_CODE_SUCCESS';
export const GET_SMS_CODE_FAILURE = 'GET_SMS_CODE_FAILURE';
export const SET_OTP_SMS = 'SET_OTP_SMS';
export const SET_OTP_SMS_SUCCESS = 'SET_OTP_SMS_SUCCESS';
export const SET_OTP_SMS_FAILURE= 'SET_OTP_SMS_FAILURE';
export const CHECK_SMS_CODE = 'CHECK_SMS_CODE';
export const CHECK_SMS_CODE_SUCCESS = 'CHECK_SMS_CODE_SUCCESS';
export const CHECK_SMS_CODE_FAILURE = 'CHECK_SMS_CODE_FAILURE';
// PHONE CONFIRMATION end
//Admission start

//TrialExams start
export const SET_HORIZONTAL_DATA = 'SET_HORIZONTAL_DATA'
export const SET_TRIAL_EXAM_TYPE = 'SET_TRIAL_EXAM_TYPE'
export const SET_TRIAL_EXAM_TYPE_VAL = 'SET_TRIAL_EXAM_TYPE_VAL'
export const SET_PARTIAL_TRIAL_EXAM_SESSION_TYPE = 'SET_PARTIAL_TRIAL_EXAM_SESSION_TYPE'
export const SET_PARTIAL_TRIAL_EXAM_SESSION_TYPE_VAL = 'SET_PARTIAL_TRIAL_EXAM_SESSION_TYPE_VAL'
export const SET_GENERAL_TRIAL_EXAM_SESSION_TYPE = 'SET_GENERAL_TRIAL_EXAM_SESSION_TYPE'
export const SET_PARTIAL_TYT_DATA = 'SET_PARTIAL_TYT_DATA'
export const SET_PARTIAL_AYT_DATA = 'SET_PARTIAL_AYT_DATA'
export const SET_PARTIAL_YDT_DATA = 'SET_PARTIAL_YDT_DATA'
export const SET_GENERAL_YKS_DATA = 'SET_GENERAL_YKS_DATA'
export const SET_GENERAL_LGS_DATA = 'SET_GENERAL_LGS_DATA'
export const SET_GENERAL_EXAM_BANNER = 'SET_GENERAL_EXAM_BANNER'
export const SET_ONLINE_TRIAL_EXAM_STATUS = 'SET_ONLINE_TRIAL_EXAM_STATUS'
export const SET_ONLINE_TRIAL_EXAM_STATUS_SUCCESS = 'SET_ONLINE_TRIAL_EXAM_STATUS_SUCCESS'
export const SET_ONLINE_TRIAL_EXAM_STATUS_FAILURE = 'SET_ONLINE_TRIAL_EXAM_STATUS_FAILURE'
export const GET_ONLINE_TRIAL_EXAM_PDFS = 'GET_ONLINE_TRIAL_EXAM_PDFS'
export const GET_ONLINE_TRIAL_EXAM_PDFS_SUCCESS = 'GET_ONLINE_TRIAL_EXAM_PDFS_SUCCESS'
export const GET_ONLINE_TRIAL_EXAM_PDFS_FAILURE = 'GET_ONLINE_TRIAL_EXAM_PDFS_FAILURE'
export const SET_SHOW_BOOKLET_LIST_MODAL = 'SET_SHOW_BOOKLET_LIST_MODAL'

//TrialExams end


//Tracking start

export const UPDATE_TRACKING_ACTION = 'UPDATE_TRACKING_ACTION'
export const ADD_TRACKING_ACTION = 'ADD_TRACKING_ACTION'
export const DELETE_TRACKING = 'DELETE_TRACKING'
export const SET_ALL = 'SET_ALL'

//Tracking end

//Content start
export const GET_PROMOTION_BANNER = 'GET_PROMOTION_BANNER'
export const GET_PROMOTION_BANNER_SUCCESS = 'GET_PROMOTION_BANNER_SUCCESS'
export const GET_PROMOTION_BANNER_FAILURE = 'GET_PROMOTION_BANNER_FAILURE'
//Content end

//Printable Test start
export const GET_PRINTABLE_TEST_FILE = 'GET_PRINTABLE_TEST_FILE'
export const GET_PRINTABLE_TEST_FILE_SUCCESS = 'GET_PRINTABLE_TEST_FILE_SUCCESS'
export const GET_PRINTABLE_TEST_FILE_FAILURE = 'GET_PRINTABLE_TEST_FILE_FAILURE'
export const GET_PARAMETERS = 'GET_PARAMETERS';
export const GET_PARAMETERS_SUCCESS = 'GET_PARAMETERS_SUCCESS';
export const GET_PARAMETERS_FAILURE = 'GET_PARAMETERS_FAILURE';
export const SET_PARAMETERS = 'SET_PARAMETERS';

export const GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB = 'GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB';
export const GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB_SUCCESS = 'GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB_SUCCESS';
export const GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB_FAILURE = 'GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB_FAILURE';
export const CLEAR_PDF_DATA_FOR_HOMEWORKS = 'CLEAR_PDF_DATA_FOR_HOMEWORKS';

//Printable Test end

//Courses start
export const SET_TYT_COURSES = 'SET_TYT_COURSES'
export const SET_AYT_COURSES = 'SET_AYT_COURSES'
export const SET_LGS_COURSES = 'SET_LGS_COURSES'
export const SET_KPSS_COURSES = 'SET_KPSS_COURSES'
export const GET_DETAILS = 'GET_DETAILS'
export const GET_DETAILS_SUCCESS = 'GET_DETAILS_SUCCESS'
export const GET_DETAILS_FAILURE = 'GET_DETAILS_FAILURE'
export const GET_USER_SOLVED_PROGRESS = 'GET_USER_SOLVED_PROGRESS'
export const GET_USER_SOLVED_PROGRESS_SUCCESS = 'GET_USER_SOLVED_PROGRESS_SUCCESS'
export const GET_USER_SOLVED_PROGRESS_FAILURE = 'GET_USER_SOLVED_PROGRESS_FAILURE'
//Courses end

//MediaFile start
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const CLEAR_UPLOAD_FILE = 'CLEAR_UPLOAD_FILE';

//MediaFile end