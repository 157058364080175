import * as types from '../constants/actionTypes';

export const getPublisherRates = data => {
	return {
		type: types.GET_PUBLISHER_RATES_REQUEST,
		data,
	};
};

export const getPublisherRatesSuccess = data => {
	return {
		type: types.GET_PUBLISHER_RATES_SUCCESS,
		payload: data,
	};
};

export const getPublisherRatesFailure = () => {
	return {
		type: types.GET_PUBLISHER_RATES_FAILURE,
	};
};

export const updatePublisherRates = data => {
	return {
		type: types.UPDATE_PUBLISHER_RATES_REQUEST,
		data,
	};
};

export const updatePublisherRatesSuccess = data => {
	return {
		type: types.UPDATE_PUBLISHER_RATES_SUCCESS,
		payload: data,
	};
};

export const updatePublisherRatesFailure = () => {
	return {
		type: types.UPDATE_PUBLISHER_RATES_FAILURE,
	};
};

export const changePassword = data => {
	return {
		type: types.CHANGE_PASSWORD_REQUEST,
		data,
	};
};

export const changePsswordSuccess = data => {
	return {
		type: types.CHANGE_PASSWORD_SUCCESS,
		payload: data,
	};
};

export const changePasswordFailure = () => {
	return {
		type: types.CHANGE_PASSWORD_FAILURE,
	};
};

export const getProfileInfo = () => {
	return {
		type: types.GET_PROFILE_INFO_REQUEST,
	};
};

export const getProfileInfoSuccess = data => {
	return {
		type: types.GET_PROFILE_INFO_SUCCESS,
		payload: data,
	};
};

export const getProfileInfoFailure = () => {
	return {
		type: types.GET_PROFILE_INFO_FAILURE,
	};
};

export const updateProfile = data => {
	return {
		type: types.UPDATE_PROFILE_REQUEST,
		data,
	};
};

export const updateProfileSuccess = data => {
	return {
		type: types.UPDATE_PROFILE_SUCCESS,
		payload: data,
	};
};

export const updateProfileFailure = () => {
	return {
		type: types.UPDATE_PROFILE_FAILURE,
	};
};

export const updateUserExamCategory = (examCategory) => {
	return {
		type: types.UPDATE_USER_EXAM_CATEGORY,
		data: examCategory
	};
};

export const updateUserExamCategorySuccess = data => {
	return {
		type: types.UPDATE_USER_EXAM_CATEGORY_SUCCESS,
		payload: data,
	};
};

export const updateUserExamCategoryFailure = () => {
	return {
		type: types.UPDATE_USER_EXAM_CATEGORY_FAILURE,
	};
};