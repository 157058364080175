import { takeLatest } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import { ExamTypeAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherExamType() {
	yield takeLatest(types.HANDLE_EXAM_TYPE, workerExamType);
}

function handleExamType() {}
function* workerExamType(action) {
	try {
		handleExamType(action.payLoad);
		ExamTypeAction.handleExamType(action.payLoad);
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
	}
}
