import * as React from "react";
const PassDirectOutlined = (props) => (
	<svg
		width={24}
		height={18}
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M11 11L1 1" strokeWidth={1.5} stroke="#EE5942" strokeLinecap="round" />
		<path d="M11 1L1 11" strokeWidth={1.5} stroke="#EE5942" strokeLinecap="round" />
	</svg>
);
export default PassDirectOutlined;
