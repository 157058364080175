const ExamSubType = {
	Sayısal: 1,
	Sözel: 2,
	EA: 3,
	'Eşit\nAğırlık': 3,
	TYT: 4,
	YDT: 5,
	Dil: 5,
	LGS: 6,
	KPSS: 7,
};

export default ExamSubType;

export const AYTFieldButtons = [
  { name: "Sayısal", name2: 'Say',  value: ExamSubType.Sayısal },
  { name: "Sözel", name2: 'Söz', value: ExamSubType.Sözel },
  { name: "Eşit Ağırlık", name2: 'Ea', value: ExamSubType.EA },
  { name: "Dil", name2: 'Dil', value: ExamSubType.Dil },
];
