import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container } from './style';
import { getToken } from '../../../helpers/tokenManager';
import { History } from '../../../helpers/history';
import ExamCategoryType from '../../../enums/ExamCategoryType';

export default () => {
	// todo: forum token have to add in cookie
	// todo: read the guide that Fatih send to us
	return (
		<Container>
			<div
				onClick={() => {
					const userExamCategory = parseInt(localStorage.getItem('userExamCategory'));
					const u = userExamCategory === ExamCategoryType.LGS ? 'lgs/' : userExamCategory === ExamCategoryType.YKS ? '' : ''; //todo: KPSS
					let forumUrl = `forum.pakodemy.com/${u}`;
					window.open(
						`//${forumUrl}`,
						'_blank', // <- This is what makes it open in a new window.
					);
					//window.location.href  = `http://forum.pakodemy.com?pakodemyToken=${getToken('pakodemyToken')}`;
				}}
				className="text-blue-800 font-semibold text-sm lg:text-base mb-2 text-sm pointer block flex justify-center pt-4 pb-4"
				title="forum.pakodemy"
			>
				<img src="/assets/pakodemy_forum.png" className="pointer w-10" />
			</div>
			{navlist.map((item, key) => {
				return (
					<NavLink
						key={key}
						exact
						to={item.href}
						target="_blank"
						className="text-blue-800 font-semibold text-sm lg:text-base mb-2 text-sm pointer block flex justify-center pb-4"
						title={item.title}
					>
						<i className={item.class} />
					</NavLink>
				);
			})}
		</Container>
	);
};

const navlist = [
	{ id: 1, href: '//pakodemy.com/', class: 'fas fa-info info', title: 'pakodemy Nedir?' },
	{ id: 2, href: '//www.instagram.com/pakodemy/', class: 'fab fa-instagram instagram', title: 'instagram' },
	{
		id: 3,
		href: '//www.youtube.com/channel/UC0PHPpWOD1UE2SkXx6E_Iuw',
		class: 'fab fa-youtube youtube',
		title: 'youtube',
	},
	{ id: 4, href: '//twitter.com/pakodemy', class: 'fab fa-twitter twitter', title: 'twitter' },
	{
		id: 5,
		href: '//apps.apple.com/us/app/pakodemy/id1481710296?l=tr&ls=1',
		class: 'fab fa-app-store app-store',
		title: 'app store',
	},
	{
		id: 6,
		href: '//play.google.com/store/apps/details?id=com.pakodemy',
		class: 'fab fa-google-play google-play',
		title: 'google play',
	},
];
