import * as types from '../constants/actionTypes';
const initialState = {
	horizontalData: [],
	trialExamType: 0,
	trialExamTypeValue: 1,
	partialTrialExamSessionType: 1,
	partialTrialExamSessionTypeValue: 1,
	generalTrialExamSessionType: 0,
	partialExamTYTData: null,
	partialExamAYTData: null,
	partialExamYDTData: null,
	generalExamYKSData: null,
	generalExamLGSData: null,
	generalExamBannerTheme: null,
	generalExamBanner: null,
};

const TrialExams = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_HORIZONTAL_DATA:
			return {
				...state,
				horizontalData: action.data,
			};
		case types.SET_TRIAL_EXAM_TYPE:
			return {
				...state,
				trialExamType: action.data,
			};
		case types.SET_TRIAL_EXAM_TYPE_VAL:
			return {
				...state,
				trialExamTypeValue: action.data,
			};
		case types.SET_PARTIAL_TRIAL_EXAM_SESSION_TYPE:
			return {
				...state,
				partialTrialExamSessionType: action.data,
			};
		case types.SET_PARTIAL_TRIAL_EXAM_SESSION_TYPE_VAL:
			return {
				...state,
				partialTrialExamSessionTypeValue: action.data,
			};
		case types.SET_GENERAL_TRIAL_EXAM_SESSION_TYPE:
			return {
				...state,
				generalTrialExamSessionType: action.data,
			};
		case types.SET_PARTIAL_TYT_DATA:
			return {
				...state,
				partialExamTYTData: action.data,
			};
		case types.SET_PARTIAL_AYT_DATA:
			return {
				...state,
				partialExamAYTData: action.data,
			};
		case types.SET_PARTIAL_YDT_DATA:
			return {
				...state,
				partialExamYDTData: action.data,
			};
		case types.SET_GENERAL_YKS_DATA:
			return {
				...state,
				generalExamYKSData: action.data,
			};
		case types.SET_GENERAL_LGS_DATA:
			return {
				...state,
				generalExamLGSData: action.data,
			};
		case types.SET_GENERAL_EXAM_BANNER:
			return {
				...state,
				generalExamBanner: action.data,
			};
		default:
			return state;
	}
};

export default TrialExams;
