import { setAnswerToStore } from '../actions/question';
import { setCookie, getCookie } from './cookie';

export const setToken = token => {
	setCookie('pakodemyToken', token, 86400000, '.pakodemy.com', '/');
	setCookie('pakodemyToken', token, 86400000, '', '/');
};
export const getToken = tokenName => {
	return getCookie(tokenName);
};
const key1 = '3Snpmd99YZ7fhAJWygrEd8bb5vEmqpR3';
var CryptoJS = require('crypto-js');
var key = CryptoJS.enc.Utf8.parse(key1); // Convert into WordArray (using Utf8)
var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);
export const getCorrectAnswer = question => {
	if (!question) return '';

	const ans = question.CorrectAnswer;

	var decrypted = CryptoJS.AES.decrypt(ans, key, { iv: iv }); // By default: CBC, PKCS7
	const res = decrypted.toString(CryptoJS.enc.Utf8);

	if (res.indexOf('A') != -1) return 'A';
	if (res.indexOf('B') != -1) return 'B';
	if (res.indexOf('C') != -1) return 'C';
	if (res.indexOf('D') != -1) return 'D';
	if (res.indexOf('E') != -1) return 'E';
};
