import { apiCaller } from '../helpers/apiCaller';

export const getAllCoaches = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/coaching/get`);
};

export const canTakeCoach = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/coaching/cantakecoach`);
};

export const getIsUserHasCoach = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/coaching/getisuserhascoach`);
};

export const takeSeat = req => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/coaching/takeseat`, req);
};

export const getTutorial = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/coaching/tutorial`);
};