import { apiCaller } from '../helpers/apiCaller';
import ConvertJsonToQueryString from '../helpers/convertJsonToQueryString';

export const getStudyStatistics = filter => {
	return apiCaller({ needToken: true }).get(
		`${process.env.REACT_APP_API_URL}/api/UserStatistic/getstudystatisticsv3?${ConvertJsonToQueryString(filter)}`,
	);
};

export const getDailyStatistics = filter => {
	return apiCaller({ needToken: true }).get(
		`${process.env.REACT_APP_API_URL}/api/UserStatistic/getstudystatisticsv3?${ConvertJsonToQueryString(filter)}`,
	);
};

export const getUsersDailyRank = async postData => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/userstatistic/getusersdailyrank`,
		postData,
	);
};
export const gettestsectionsummaryanalytics = async postData => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/testsection/gettestsectionsummaryanalytics`,
		postData,
	);
};
export const getUserSolved = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/UserStatistic/getusersolvedv2`);
};

export const getUserSolvedSummary = postData => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/UserStatistic/getusersolvedsummaryv2`,
		postData,
	);
};

export const getElementCount = async postData => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/UserStatistic/getelementcount`,
		postData,
	);
}

export const getElementSummaryV2 = async postData => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/UserStatistic/getelementsummaryv2`,
		postData,
	);
};