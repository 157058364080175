import * as types from '../constants/actionTypes';
import { orderBy } from 'lodash';
const initialState = {
	questions: [],
	question: [],
};

const Question = (state = initialState, { type, question, questionNumber, answer }) => {
	switch (type) {
		case types.SET_QUESTION_TO_STORE:
			const oldQuestions = state.questions.filter(item => item.QuestionNumber !== question.QuestionNumber);
			return { ...state, questions: orderBy([...oldQuestions, question], ['QuestionNumber'], ['asc']) };

		case types.SET_ANSWER_TO_STORE:
			const storedQuestions = state.questions.map(item => {
				if (item.QuestionNumber === answer.QuestionNumber) {
					return { ...item, ...answer };
				} else {
					return item;
				}
			});
			return { ...state, questions: storedQuestions };

		case types.GET_QUESTION_FROM_STORE:
			return {
				...state,
				question: state.questions.filter(item => Number(item.QuestionNumber) === Number(questionNumber))[0],
			};
		case types.GET_ALL_QUESTION_FROM_STORE:
			return { ...state, questions: orderBy(state.questions, ['QuestionNumber'], ['asc']) };
		case types.CLEAR_QUESTION_FROM_STORE:
			return { ...state, questions: [], question: [] };

		default:
			return state;
	}
};

export default Question;
