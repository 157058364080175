import * as types from '../constants/actionTypes';

export const getTestSectionsV4 = data => {
	return {
		type: types.GET_TEST_SECTION_V4_REQUEST,
		data,
	};
};
export const getTestSectionsV4Success = data => {
	return {
		type: types.GET_TEST_SECTION_V4_SUCCESS,
		payLoad: data,
	};
};
export const getTestSectionsV4Failure = () => {
	return {
		type: types.GET_TEST_SECTION_V4_FAILURE,
	};
};
export const updateTestSectionElement = payLoad => {
	return {
		type: types.UPDATE_TEST_SECTION_ELEMENT_REQUEST,
		payLoad,
	};
};
export const updateTestSectionElementSuccess = data => {
	return {
		type: types.UPDATE_TEST_SECTION_ELEMENT_SUCCESS,
		payLoad: data,
	};
};
export const updateTestSectionElementFailure = () => {
	return {
		type: types.UPDATE_TEST_SECTION_ELEMENT_FAILURE,
	};
};
export const updateTestSectionElementAndGetNextQuestion = payLoad => {
	return {
		type: types.UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_REQUEST,
		payLoad,
	};
};
export const updateTestSectionElementAndGetNextQuestionSuccess = data => {
	return {
		type: types.UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_SUCCESS,
		payLoad: data,
	};
};
export const updateTestSectionElementAndGetNextQuestionFailure = () => {
	return {
		type: types.UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_FAILURE,
	};
};
export const finishSectionV2 = data => {
	return {
		type: types.FINISH_SECTION_V2_REQUEST,
		data,
	};
};
export const finishSectionV2Success = data => {
	return {
		type: types.FINISH_SECTION_V2_SUCCESS,
		payLoad: data,
	};
};
export const finishSectionV2Failure = () => {
	return {
		type: types.FINISH_SECTION_V2_FAILURE,
	};
};

export const createTestSectionV6 = (data, questionNumber = '') => {
	const {redirectStatus = 1, ...res} = data
	return {
		type: types.CREATE_TEST_SECTION_V6_REQUEST,
		data: res,
		questionNumber,
		redirectStatus
	};
};
export const createTestSectionV6Success = data => {
	return {
		type: types.CREATE_TEST_SECTION_V6_SUCCESS,
		payLoad: data,
	};
};
export const createTestSectionV6Failure = () => {
	return {
		type: types.CREATE_TEST_SECTION_V6_FAILURE,
	};
};
export const addSolvedTest = data => {
	return {
		type: types.ADD_SOLVED_TEST_FROM_OUTSIDE_REQUEST,
		data,
	};
};
export const addSolvedTestSuccess = data => {
	return {
		type: types.ADD_SOLVED_TEST_FROM_OUTSIDE_SUCCESS,
		payload: data,
	};
};
export const addSolvedTestFailure = () => {
	return {
		type: types.ADD_SOLVED_TEST_FROM_OUTSIDE_FAILURE,
	};
};
export const createTestSectionElementV2 = data => {
	return {
		type: types.CREATE_TEST_SECTION_ELEMENT_V2_REQUEST,
		data,
	};
};
export const createTestSectionElementV2Success = data => {
	return {
		type: types.CREATE_TEST_SECTION_ELEMENT_V2_SUCCESS,
		payLoad: data,
	};
};
export const createTestSectionElementV2Failure = () => {
	return {
		type: types.CREATE_TEST_SECTION_ELEMENT_V2_FAILURE,
	};
};
export const getTestSectionElement = (data, answerOptionStatus = '') => {
	return {
		type: types.GET_TEST_SECTION_ELEMENT_REQUEST,
		data,
		answerOptionStatus,
	};
};
export const getTestSectionElementSuccess = data => {
	return {
		type: types.GET_TEST_SECTION_ELEMENT_SUCCESS,
		payLoad: data,
	};
};
export const getTestSectionElementFailure = () => {
	return {
		type: types.GET_TEST_SECTION_ELEMENT_FAILURE,
	};
};

export const getFinishedTestSectionSummary = data => {
	return {
		type: types.GET_FINISHED_TEST_SECTION_SUMMARY_REQUEST,
		data,
	};
};
export const getFinishedTestSectionSummarySuccess = data => {
	return {
		type: types.GET_FINISHED_TEST_SECTION_SUMMARY_SUCCESS,
		payLoad: data,
	};
};
export const getFinishedTestSectionSummaryFailure = () => {
	return {
		type: types.GET_FINISHED_TEST_SECTION_SUMMARY_FAILURE,
	};
};

export const getTestSectionCourseSummary = data => {
	return {
		type: types.GET_TEST_SECTION_COURSE_SUMMARY,
		data,
	};
};
export const getTestSectionCourseSummarySuccess = data => {
	return {
		type: types.GET_TEST_SECTION_COURSE_SUMMARY_SUCCESS,
		payLoad: data,
	};
};
export const getTestSectionCourseSummaryFailure = () => {
	return {
		type: types.GET_TEST_SECTION_COURSE_SUMMARY_FAILURE,
	};
};

export const getTestSectionSubCourseSummary = data => {
	return {
		type: types.GET_TEST_SECTION_SUB_COURSE_SUMMARY,
		data,
	};
};
export const getTestSectionSubCourseSummarySuccess = data => {
	return {
		type: types.GET_TEST_SECTION_SUB_COURSE_SUMMARY_SUCCESS,
		payLoad: data,
	};
};
export const getTestSectionSubCourseSummaryFailure = () => {
	return {
		type: types.GET_TEST_SECTION_SUB_COURSE_SUMMARY_FAILURE,
	};
};

export const getTestSectionSummary = data => {
	return {
		type: types.GET_TEST_SECTION_SUMMARY_REQUEST,
		data,
	};
};
export const getTestSectionSummarySuccess = data => {
	return {
		type: types.GET_TEST_SECTION_SUMMARY_SUCCESS,
		payLoad: data,
	};
};
export const getTestSectionSummaryFailure = () => {
	return {
		type: types.GET_TEST_SECTION_SUMMARY_FAILURE,
	};
};
export const getTestSectionSummaryV3 = data => {
	return {
		type: types.GET_TEST_SECTION_SUMMARY_REQUEST_V3,
		data,
	};
};
export const getTestSectionSummarySuccessV3 = data => {
	return {
		type: types.GET_TEST_SECTION_SUMMARY_SUCCESS_V3,
		payLoad: data,
	};
};
export const getTestSectionSummaryFailureV3 = () => {
	return {
		type: types.GET_TEST_SECTION_SUMMARY_FAILURE_V3,
	};
};

export const updateTestSectionV2 = data => {
	return {
		type: types.UPDATE_TEST_SECTION_V2_REQUEST,
		data,
	};
};
export const updateTestSectionV2Success = data => {
	return {
		type: types.UPDATE_TEST_SECTION_V2_SUCCESS,
		payLoad: data,
	};
};
export const updateTestSectionV2Failure = () => {
	return {
		type: types.UPDATE_TEST_SECTION_V2_FAILURE,
	};
};

export const deleteTestSection = data => {
	return {
		type: types.DELETE_TEST_SECTION_REQUEST,
		data,
	};
};
export const deleteTestSectionSuccess = data => {
	return {
		type: types.DELETE_TEST_SECTION_SUCCESS,
		payLoad: data,
	};
};
export const deleteTestSectionFailure = () => {
	return {
		type: types.DELETE_TEST_SECTION_FAILURE,
	};
};

export const getUserScoreNet = (data) => {
	return {
		type: types.GET_USER_SCORE_NET,
		data,
	};
};

export const getUserScoreNetSuccess = (data) => {
	return {
		type: types.GET_USER_SCORE_NET_SUCCESS,
		payLoad: data,
	};
};

export const getUserScoreNetFailure = () => {
	return {
		type: types.GET_USER_SCORE_NET_FAILURE,
	};
};

export const getAllUsersScoreNet = (data) => {
	return {
		type: types.GET_ALL_USERS_SCORE_NET,
		data,
	};
};

export const getAllUsersScoreNetSuccess = (data) => {
	return {
		type: types.GET_ALL_USERS_SCORE_NET_SUCCESS,
		payLoad: data,
	};
};

export const getAllUsersScoreNetFailure = () => {
	return {
		type: types.GET_ALL_USERS_SCORE_NET_FAILURE,
	};
};

export const getTestSectionElements = (data) => {
	return {
		type: types.GET_TEST_SECTION_ELEMENTS,
		data,
	};
};

export const getTestSectionElementsSuccess = (data) => {
	return {
		type: types.GET_TEST_SECTION_ELEMENTS_SUCCESS,
		payLoad: data,
	};
};

export const getTestSectionElementsFailure = () => {
	return {
		type: types.GET_TEST_SECTION_ELEMENTS_FAILURE,
	};
};

export const getSubCourseSuccessLevel = (data) => {
	return {
		type: types.GET_SUB_COURSE_SUCCESS_LEVEL,
		data,
	};
};

export const getSubCourseSuccessLevelSuccess = (data) => {
	return {
		type: types.GET_SUB_COURSE_SUCCESS_LEVEL_SUCCESS,
		payLoad: data,
	};
};

export const getSubCourseSuccessLevelFailure = () => {
	return {
		type: types.GET_SUB_COURSE_SUCCESS_LEVEL_FAILURE,
	};
};

export const getLastUnFinishedTestAction = (data) => {
	
	return {
		type: types.GET_LAST_UNFINISHED_TEST,
		data
	};
};

export const getLastUnFinishedTestActionSuccess = (data) => {
	return {
		type: types.GET_LAST_UNFINISHED_TEST_SUCCESS,
		payLoad: data,
	};
};

export const getLastUnFinishedTestActionFailure = () => {
	return {
		type: types.GET_LAST_UNFINISHED_TEST_FAILURE,
	};
};

export const setShowUnfinishedTestPopup = payLoad => {
	return {
		type: types.SET_SHOW_UNFINISHED_TEST_POPUP,
		payLoad,
	};
};

export const setShowTakeAnExamPopup = payLoad => {
	return {
		type: types.SET_SHOW_TAKE_AN_EXAM_POPUP,
		payLoad,
	};
};

export const createAchievementElement = data => {
	return {
		type: types.CREATE_ACHIEVEMENT_ELEMENT,
		data,
	};
}

export const createAchievementElementSuccess = data => {
	return {
		type: types.CREATE_ACHIEVEMENT_ELEMENT_SUCCESS,
		payLoad: data,
	};
}

export const createAchievementElementFailure = () => {
	return {
		type: types.CREATE_ACHIEVEMENT_ELEMENT_FAILURE,
	};
}

export const getQuestion = data => {
	return {
		type: types.GET_QUESTION,
		data,
	};
}

export const getQuestionSuccess = data => {
	return {
		type: types.GET_QUESTION_SUCCESS,
		payLoad: data,
	};
}

export const getQuestionFailure = () => {
	return {
		type: types.GET_QUESTION_FAILURE,
	};
}

export const getLastThreeAchievementQuestion = data => {
	return {
		type: types.GET_LAST_THREE_ACHIEVEMENT_QUESTION,
		data,
	};
}

export const getLastThreeAchievementQuestionSuccess = data => {
	return {
		type: types.GET_LAST_THREE_ACHIEVEMENT_QUESTION_SUCCESS,
		payLoad: data,
	};
}

export const getLastThreeAchievementQuestionFailure = () => {
	return {
		type: types.GET_LAST_THREE_ACHIEVEMENT_QUESTION_FAILURE,
	};
}

export const getTestSectionElementById = data => {
	return {
		type: types.GET_TEST_SECTION_ELEMENT_BY_ID,
		data,
	};
}

export const getTestSectionElementByIdSuccess = data => {
	return {
		type: types.GET_TEST_SECTION_ELEMENT_BY_ID_SUCCESS,
		payLoad: data,
	};
}

export const getTestSectionElementByIdFailure = () => {
	return {
		type: types.GET_TEST_SECTION_ELEMENT_BY_ID_FAILURE,
	};
}

export const setCurrentAchievementTestSectionElementId = payLoad => {
	return {
		type: types.SET_CURRENT_ACHIEVEMENT_TEST_SECTION_ELEMENT_ID,
		payLoad,
	};
};