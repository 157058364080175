import { apiCaller } from '../helpers/apiCaller';
import ConvertJsonToQueryString from '../helpers/convertJsonToQueryString';

export const getDailyTask = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/DailyTask/get`);
};
export const addDailyTask = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/DailyTask/add`, data);
};
export const deleteDailyTask = id => {
	return apiCaller({ needToken: true }).get(
		`${process.env.REACT_APP_API_URL}/api/DailyTask/delete?${ConvertJsonToQueryString({ id })}`
	);
};

export const getIsAddedPlan = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/DailyTask/get`, data);
};
