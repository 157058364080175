import * as React from "react";
const Favorite = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		{...props}
	>
		<path
			opacity={0.1}
			d="M16.0001 29.3338C23.3334 29.3338 29.3334 23.3338 29.3334 16.0004C29.3334 8.66711 23.3334 2.66711 16.0001 2.66711C8.66675 2.66711 2.66675 8.66711 2.66675 16.0004C2.66675 23.3338 8.66675 29.3338 16.0001 29.3338Z"
			fill="#054EDD"
		/>
		<path
			d="M16.372 21.9499C16.168 22.0173 15.832 22.0173 15.628 21.9499C13.888 21.3937 10 19.0735 10 15.1409C10 13.405 11.494 12.0005 13.336 12.0005C14.428 12.0005 15.394 12.4949 16 13.2589C16.606 12.4949 17.578 12.0005 18.664 12.0005C20.506 12.0005 22 13.405 22 15.1409C22 19.0735 18.112 21.3937 16.372 21.9499Z"
			fill="#054EDD"
		/>
	</svg>
);
export default Favorite;
