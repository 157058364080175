import * as types from '../constants/actionTypes';

export const getUserPhoneConfirm = () => ({
	type: types.GET_USER_PHONE_CONFIRMATION,
});

export const getUserPhoneConfirmSuccess = data => ({
	type: types.GET_USER_PHONE_CONFIRMATION_SUCCESS,
	payLoad: data,
});

export const getUserPhoneConfirmFailure = () => ({
	type: types.GET_USER_PHONE_CONFIRMATION_FAILURE,
});

export const getUserPhoneNumber = () => {
	return {
		type: types.GET_USER_PHONE_NUMBER,
	};
};

export const getUserPhoneNumberSuccess = data => {
	return {
		type: types.GET_USER_PHONE_NUMBER_SUCCESS,
		payLoad: data,
	};
};

export const getUserPhoneNumberFailure = () => {
	return {
		type: types.GET_USER_PHONE_NUMBER_FAILURE,
	};
};

export const getSmsCode = data => {
	return {
		type: types.GET_SMS_CODE,
		data,
	};
};

export const getSmsCodeSuccess = data => {
	return {
		type: types.GET_SMS_CODE_SUCCESS,
		payLoad: data,
	};
};

export const getSmsCodeFailure = () => {
	return {
		type: types.GET_SMS_CODE_FAILURE,
	};
};

export const checkSmsCode = data => {
	return {
		type: types.CHECK_SMS_CODE,
		data,
	};
};

export const checkSmsCodeSuccess = data => {
	return {
		type: types.CHECK_SMS_CODE_SUCCESS,
		payLoad: data,
	};
};

export const checkSmsCodeFailure = () => {
	return {
		type: types.CHECK_SMS_CODE_FAILURE,
	};
};