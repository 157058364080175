const ExamTypeId = {
  NONE: 0,
  TYT: 1,
  AYT: 2,
  LGS: 3,
  KPSS: 4,
};

export default ExamTypeId;

export const ExamTypes = [
  { id: ExamTypeId.TYT, name: 'TYT' },
  { id: ExamTypeId.AYT, name: 'AYT' },
  { id: ExamTypeId.LGS, name: 'LGS' },
];
