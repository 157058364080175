import { takeLatest } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import { DatePickerAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherDatePicker() {
	yield takeLatest(types.HANDLE_DATE_PICKER, workerDatePicker);
}

function handleDatePicker() {}
function* workerDatePicker(action) {
	try {
		handleDatePicker(action.payLoad);
		DatePickerAction.handleDatePicker(action.payLoad);
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
	}
}
