import * as types from '../constants/actionTypes';
import { GET_ACHIEVEMENT_USER_PROGRESS } from '../constants/actionTypes';

export const getNestedTitles = sourceTypeId => {
	return {
		type: types.GET_NESTED_TITLES_REQUEST,
		sourceTypeId,
	};
};
export const getNestedTitlesSuccess = data => {
	return {
		type: types.GET_NESTED_TITLES_SUCCESS,
		payLoad: data,
	};
};
export const getNestedTitlesFailure = () => {
	return {
		type: types.GET_NESTED_TITLES_FAILURE,
	};
};

export const getCourses = examCategoryId => {
	return {
		type: types.GET_COURSES,
		examCategoryId,
	};
};

export const getCoursesSuccess = data => {
	return {
		type: types.GET_COURSES_SUCCESS,
		payLoad: data,
	};
};

export const getCoursesFailure = () => {
	return {
		type: types.GET_COURSES_FAILURE,
	};
};

export const getSubCourseTopics = postData => {
	return {
		type: types.GET_SUB_COURSE_TOPICS,
		postData,
	};
};

export const getSubCourseTopicsSuccess = data => {
	return {
		type: types.GET_SUB_COURSE_TOPICS_SUCCESS,
		payLoad: data,
	};
};

export const getSubCourseTopicsFailure = () => {
	return {
		type: types.GET_SUB_COURSE_TOPICS_FAILURE,
	};
};

export const checkLectureContent = id => {
	return {
		type: types.CHECK_LECTURE_CONTENT,
		id,
	};
};

export const checkLectureContentSuccess = data => {
	return {
		type: types.CHECK_LECTURE_CONTENT_SUCCESS,
		payLoad: data,
	};
};

export const checkLectureContentFailure = () => {
	return {
		type: types.CHECK_LECTURE_CONTENT_FAILURE,
	};
};

export const getAchievements = id => {
	return {
		type: types.GET_ACHIEVEMENTS,
		id,
	};
};

export const getAchievementsSuccess = data => {
	return {
		type: types.GET_ACHIEVEMENTS_SUCCESS,
		payLoad: data,
	};
};

export const getAchievementsFailure = () => {
	return {
		type: types.GET_ACHIEVEMENTS_FAILURE,
	};
};

export const getAchievementUserProgress = postData => {
	return {
		type: types.GET_ACHIEVEMENT_USER_PROGRESS,
		postData,
	};
};

export const getAchievementUserProgressSuccess = data => {
	return {
		type: types.GET_ACHIEVEMENT_USER_PROGRESS_SUCCESS,
		payLoad: data,
	};
};

export const getAchievementUserProgressFailure = () => {
	return {
		type: types.GET_ACHIEVEMENT_USER_PROGRESS_FAILURE,
	};
};

export const updateAchievementVideoUser = postData => {
	return {
		type: types.UPDATE_ACHIEVEMENT_VIDEO_USER,
		postData,
	};
};

export const updateAchievementVideoUserSuccess = data => {
	return {
		type: types.UPDATE_ACHIEVEMENT_VIDEO_USER_SUCCESS,
		payLoad: data,
	};
};

export const updateAchievementVideoUserFailure = () => {
	return {
		type: types.UPDATE_ACHIEVEMENT_VIDEO_USER_FAILURE,
	};
};

export const addAchievementVideoDuration = postData => {
	return {
		type: types.ADD_ACHIEVEMENT_VIDEO_DURATION,
		postData,
	};
};

export const addAchievementVideoDurationSuccess = data => {
	return {
		type: types.ADD_ACHIEVEMENT_VIDEO_DURATION_SUCCESS,
		payLoad: data,
	};
};

export const addAchievementVideoDurationFailure = () => {
	return {
		type: types.ADD_ACHIEVEMENT_VIDEO_DURATION_FAILURE,
	};
};


export const resetAllData = () => {
	return {
		type: types.RESET_ALL_DATA,
	};
}

export const getAchievementVideoReward = postData => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEO_REWARD,
		postData,
	};
}

export const getAchievementVideoRewardSuccess = data => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEO_REWARD_SUCCESS,
		payLoad: data,
	};
}

export const getAchievementVideoRewardFailure = () => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEO_REWARD_FAILURE,
	};
}

export const getSubCourses = payLoad => {
	return {
		type: types.GET_SUB_COURSES,
		payLoad,
	};
}

export const getSubCoursesSuccess = data => {
	return {
		type: types.GET_SUB_COURSES_SUCCESS,
		payLoad: data,
	};
}

export const getSubCoursesFailure = () => {
	return {
		type: types.GET_SUB_COURSES_FAILURE,
	};
}

export const clearHomeworkData = () => {
	return {
		type: types.CLEAR_HOMEWORK_DATA,
	};
}

export const getAchievementRewardedGift = subCourseTopicUniqueId => {
	return {
		type: types.GET_ACHIEVEMENT_REWARDED_GIFT,
		subCourseTopicUniqueId,
	};
}

export const getAchievementRewardedGiftSuccess = data => {
	return {
		type: types.GET_ACHIEVEMENT_REWARDED_GIFT_SUCCESS,
		payLoad: data,
	};
};

export const getAchievementRewardedGiftFailure = () => {
	return {
		type: types.GET_ACHIEVEMENT_REWARDED_GIFT_FAILURE,
	};
};

export const getAchievementTestInstruction = postData => {
	return {
		type: types.GET_ACHIEVEMENT_TEST_INSTRUCTION,
		postData,
	};
}

export const getAchievementTestInstructionSuccess = data => {
	return {
		type: types.GET_ACHIEVEMENT_TEST_INSTRUCTION_SUCCESS,
		payLoad: data,
	};
}

export const getAchievementTestInstructionFailure = () => {
	return {
		type: types.GET_ACHIEVEMENT_TEST_INSTRUCTION_FAILURE,
	};
}

export const setShowAchievementDetailModal = data => {
	return {
		type: types.SET_SHOW_ACHIEVEMENT_TEST_DETAIL_MODAL,
		payLoad: data,
	};
}

export const getAchievementTestDetail = (actionGetElementCount, actionGetAchievementTestInstruction) => {
	const requests = {
		actionGetElementCount,
		actionGetAchievementTestInstruction,
	};
	return {
		type: types.GET_ACHIEVEMENT_TEST_DETAIL,
		requests,
	};
};

export const setShowTestInstructionModal = payLoad => {
	return {
		type: types.SET_SHOW_TEST_INSTRUCTION_MODAL,
		payLoad: payLoad,
	};
};

export const getAchievementReward = postData => {
	return {
		type: types.GET_ACHIEVEMENT_REWARD,
		postData,
	};
}

export const getAchievementRewardSuccess = data => {
	return {
		type: types.GET_ACHIEVEMENT_REWARD_SUCCESS,
		payLoad: data,
	};
}

export const getAchievementRewardFailure = () => {
	return {
		type: types.GET_ACHIEVEMENT_REWARD_FAILURE,
	};
}

export const setShowAchievementRewardAnimation = payLoad => {
	return {
		type: types.SHOW_ACHIEVEMENT_REWARD_ANIMATION,
		payLoad: payLoad,
	};
};
