import * as types from '../constants/actionTypes';
const initialState = {
	getYksPoint: {
		loading: false,
		data: [],
	},
	getList: {
		loading: false,
		data: [],
	},
	postList: {
		loading: false,
		data: [],
	},
	preferenceList: {
		data: [],
		preferenceIDs: [],
	},
	optionList: {
		loading: false,
		data: [],
	},
	options: {
		loading: false,
		data: [],
	},
	getResult: {
		loading: false,
		data: [],
	},
	getUniversityRanks: {
		loading: false,
		data: [],
	},
	userRecords: {
		loading: false,
	},
	userRanks: {
		data: [],
	},
	selectedCities: {
		data: [],
	},
	programTuitionType: {
		data: [],
	},
	programFee: {
		data: [],
	},
	diploma: null,
	tytThousand: null,
	tytHundred: null,
	turkceCorrect: null,
	turkceWrong: null,
	sosyalBilimlerCorrect: null,
	sosyalBilimlerWrong: null,
	temelMatematikCorrect: null,
	temelMatematikWrong: null,
	fenBilimleriCorrect: null,
	fenBilimleriWrong: null,
	turkDiliVeEdebiyatiCorrect: null,
	turkDiliVeEdebiyatiWrong: null,
	tarih1Correct: null,
	tarih1Wrong: null,
	cografya1Correct: null,
	cografya1Wrong: null,
	tarih2Correct: null,
	tarih2Wrong: null,
	cografya2Correct: null,
	cografya2Wrong: null,
	felsefeGCorrect: null,
	felsefeGWrong: null,
	dinKFelsefeCorrect: null,
	dinKFelsefeWrong: null,
	matematikCorrect: null,
	matematikWrong: null,
	fizikCorrect: null,
	fizikWrong: null,
	kimyaCorrect: null,
	kimyaWrong: null,
	biyolojiCorrect: null,
	biyolojiWrong: null,
	yabanciDilCorrect: null,
	yabanciDilWrong: null,
	getAllRank: {
		loading: false,
		data: [],
	},
	universityDetails: {
		loading: false,
		data: [],
	},
	universityConditionDetails: {
		loading: false,
		data: [],
	},
};

const Admission = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_YKS_POINT:
			return {
				...state,
				getYksPoint: { ...state.getYksPoint, loading: true },
			};

		case types.GET_YKS_POINT_SUCCESS:
			return {
				...state,
				getYksPoint: { data: payLoad, loading: false },
			};

		case types.GET_YKS_POINT_FAILURE:
			return {
				...state,
				getYksPoint: { data: [], loading: false },
			};

		case types.GET_LIST:
			return {
				...state,
				getList: { ...state.getList, loading: true },
			};

		case types.GET_LIST_SUCCESS:
			return {
				...state,
				getList: { data: payLoad, loading: false },
			};

		case types.GET_LIST_FAILURE:
			return {
				...state,
				getList: { data: [], loading: false },
			};

		case types.POST_LIST:
			return {
				...state,
				postList: { ...state.postList, loading: true },
			};

		case types.POST_LIST_SUCCESS:
			return {
				...state,
				postList: { data: payLoad, loading: false },
			};

		case types.POST_LIST_FAILURE:
			return {
				...state,
				postList: { data: [], loading: false },
			};

		case types.PREFERENCE_LIST:
			const isItemExist = state.preferenceList.preferenceIDs.includes(payLoad.program_id);

			if (isItemExist) {
				return {
					...state,
					preferenceList: {
						...state.preferenceList,
						data: state.preferenceList.data.map(item => {
							if (item.program_id === payLoad.program_id) {
								return payLoad; // Güncellenecek "item" verisini payLoad ile değiştiriyoruz
							}
							return item;
						}),
					},
				};
			} else {
				return {
					...state,
					preferenceList: {
						...state.preferenceList,
						data: [...state.preferenceList.data, payLoad],
						preferenceIDs: [...state.preferenceList.preferenceIDs, payLoad.program_id],
					},
				};
			}

		case types.REMOVE_FROM_PREFERENCE_LIST:
			const programIdToRemove = payLoad;
			const updatedPreferenceList = state.preferenceList.data.filter(item => item.program_id !== programIdToRemove);
			const updatedPreferenceIDs = state.preferenceList.preferenceIDs.filter(item => item !== programIdToRemove);

			return {
				...state,
				preferenceList: { ...state.preferenceList, data: updatedPreferenceList, preferenceIDs: updatedPreferenceIDs },
			};

		case types.REMOVE_ALL_ITEMS_FROM_PREFERENCE_LIST:
			return {
				...state,
				preferenceList: { data: [], preferenceIDs: [] },
				options: { data: [] },
			};

		case types.ADD_OPTION:
			return {
				...state,
				optionList: { ...state.optionList, loading: true },
			};

		case types.ADD_OPTION_SUCCESS:
			return {
				...state,
				optionList: { loading: false },
			};

		case types.ADD_OPTION_FAILURE:
			return {
				...state,
				optionList: { data: [], loading: false },
			};

		case types.GET_OPTION:
			return {
				...state,
				options: { ...state.options, loading: true },
			};

		case types.GET_OPTION_SUCCESS:
			return {
				...state,
				options: { data: payLoad, loading: false },
			};

		case types.GET_OPTION_FAILURE:
			return {
				...state,
				options: { data: [], loading: false },
			};

		case types.REMOVE_FROM_GET_OPTION:
			const idToRemove = payLoad;
			console.log('idToRemove', idToRemove);
			console.log('state.options', state.options);
			return {
				...state,
				options: {
					...state.options,
					data: state.options.data.filter(item => item.program_id !== idToRemove),
				},
			};

		case types.GET_RESULT:
			return {
				...state,
				getResult: { ...state.getResult, loading: true },
			};

		case types.GET_RESULT_SUCCESS:
			return {
				...state,
				getResult: { data: payLoad, loading: false },
			};

		case types.GET_RESULT_FAILURE:
			return {
				...state,
				getResult: { data: [], loading: false },
			};

		case types.GET_UNIVERSITY_RANKS:
			return {
				...state,
				getUniversityRanks: { ...state.getUniversityRanks, loading: true },
			};

		case types.GET_UNIVERSITY_RANKS_SUCCESS:
			return {
				...state,
				getUniversityRanks: { data: payLoad, loading: false },
			};

		case types.GET_UNIVERSITY_RANKS_FAILURE:
			return {
				...state,
				getUniversityRanks: { data: [], loading: false },
			};

		case types.SAVE_USER_RECORDS:
			return {
				...state,
				userRecords: { ...state.userRecords, loading: true },
			};

		case types.SAVE_USER_RECORDS_SUCCESS:
			return {
				...state,
				userRecords: { loading: false },
			};

		case types.SAVE_USER_RECORDS_FAILURE:
			return {
				...state,
				userRecords: { data: [], loading: false },
			};

		case types.HANDLE_USER_RANKS:
			return {
				...state,
				userRanks: { data: payLoad },
			};

		case types.HANDLE_SELECTED_CITIES:
			return {
				...state,
				selectedCities: { data: payLoad },
			};

		case types.HANDLE_PROGRAM_TUITION_TYPE:
			return {
				...state,
				programTuitionType: { data: payLoad },
			};

		case types.HANDLE_PROGRAM_FEE:
			return {
				...state,
				programFee: { data: payLoad },
			};

		case types.SAVE_INPUTS:
		case types.SAVE_INPUTS:
			const updatedState = { ...state };
			payLoad.forEach(item => {
				const scoreType = Object.keys(item)[0];
				const scoreValue = Object.values(item)[0];
				switch (scoreType) {
					case 'diploma':
						updatedState.diploma = scoreValue;
						break;
					case 'tytThousand':
						updatedState.tytThousand = scoreValue;
						break;
					case 'tytHundred':
						updatedState.tytHundred = scoreValue;
						break;
					case 'turkceCorrect':
						updatedState.turkceCorrect = scoreValue;
						break;
					case 'turkceWrong':
						updatedState.turkceWrong = scoreValue;
						break;
					case 'sosyalBilimlerCorrect':
						updatedState.sosyalBilimlerCorrect = scoreValue;
						break;
					case 'sosyalBilimlerWrong':
						updatedState.sosyalBilimlerWrong = scoreValue;
						break;
					case 'temelMatematikCorrect':
						updatedState.temelMatematikCorrect = scoreValue;
						break;
					case 'temelMatematikWrong':
						updatedState.temelMatematikWrong = scoreValue;
						break;
					case 'fenBilimleriCorrect':
						updatedState.fenBilimleriCorrect = scoreValue;
						break;
					case 'fenBilimleriWrong':
						updatedState.fenBilimleriWrong = scoreValue;
						break;
					case 'turkDiliVeEdebiyatiCorrect':
						updatedState.turkDiliVeEdebiyatiCorrect = scoreValue;
						break;
					case 'turkDiliVeEdebiyatiWrong':
						updatedState.turkDiliVeEdebiyatiWrong = scoreValue;
						break;
					case 'tarih1Correct':
						updatedState.tarih1Correct = scoreValue;
						break;
					case 'tarih1Wrong':
						updatedState.tarih1Wrong = scoreValue;
						break;
					case 'cografya1Correct':
						updatedState.cografya1Correct = scoreValue;
						break;
					case 'cografya1Wrong':
						updatedState.cografya1Wrong = scoreValue;
						break;
					case 'tarih2Correct':
						updatedState.tarih2Correct = scoreValue;
						break;
					case 'tarih2Wrong':
						updatedState.tarih2Wrong = scoreValue;
						break;
					case 'cografya2Correct':
						updatedState.cografya2Correct = scoreValue;
						break;
					case 'cografya2Wrong':
						updatedState.cografya2Wrong = scoreValue;
						break;
					case 'felsefeGCorrect':
						updatedState.felsefeGCorrect = scoreValue;
						break;
					case 'felsefeGWrong':
						updatedState.felsefeGWrong = scoreValue;
						break;
					case 'dinKFelsefeCorrect':
						updatedState.dinKFelsefeCorrect = scoreValue;
						break;
					case 'dinKFelsefeWrong':
						updatedState.dinKFelsefeWrong = scoreValue;
						break;
					case 'matematikCorrect':
						updatedState.matematikCorrect = scoreValue;
						break;
					case 'matematikWrong':
						updatedState.matematikWrong = scoreValue;
						break;
					case 'fizikCorrect':
						updatedState.fizikCorrect = scoreValue;
						break;
					case 'fizikWrong':
						updatedState.fizikWrong = scoreValue;
						break;
					case 'kimyaCorrect':
						updatedState.kimyaCorrect = scoreValue;
						break;
					case 'kimyaWrong':
						updatedState.kimyaWrong = scoreValue;
						break;
					case 'biyolojiCorrect':
						updatedState.biyolojiCorrect = scoreValue;
						break;
					case 'biyolojiWrong':
						updatedState.biyolojiWrong = scoreValue;
						break;
					case 'yabanciDilCorrect':
						updatedState.yabanciDilCorrect = scoreValue;
						break;
					case 'yabanciDilWrong':
						updatedState.yabanciDilWrong = scoreValue;
						break;
					default:
						break;
				}
			});
			return updatedState;

		case types.GET_ALL_RANK:
			return {
				...state,
				getAllRank: { ...state.getAllRank, loading: true },
			};

		case types.GET_ALL_RANK_SUCCESS:
			return {
				...state,
				getAllRank: { data: payLoad, loading: false },
			};

		case types.GET_ALL_RANK_FAILURE:
			return {
				...state,
				getAllRank: { data: [], loading: false },
			};

		case types.GET_UNIVERSITY_DETAILS:
			return {
				...state,
				universityDetails: { ...state.universityDetails, loading: true },
			};

		case types.GET_UNIVERSITY_DETAILS_SUCCESS:
			return {
				...state,
				universityDetails: { data: payLoad, loading: false },
			};

		case types.GET_UNIVERSITY_DETAILS_FAILURE:
			return {
				...state,
				universityDetails: { data: [], loading: false },
			};

		case types.GET_UNIVERSITY_CONDITION_DETAILS:
			return {
				...state,
				universityConditionDetails: { ...state.universityConditionDetails, loading: true },
			};

		case types.GET_UNIVERSITY_CONDITION_DETAILS_SUCCESS:
			return {
				...state,
				universityConditionDetails: { data: payLoad, loading: false },
			};

		case types.GET_UNIVERSITY_CONDITION_DETAILS_FAILURE:
			return {
				...state,
				universityConditionDetails: { data: [], loading: false },
			};

		default:
			return state;
	}
};

export default Admission;
