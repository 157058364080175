import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as Coaching from '../apis/coaching';
import * as PhoneConfirmation from '../apis/phoneConfirmation';
import { CoachingAction, PhoneConfirmationAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherCoaching() {
	yield takeLatest(types.GET_ALL_COACHES, workerCoaching);
	yield takeLatest(types.GET_USER_CAN_TAKE_COACH, workerUserCanTakeCoach);
	yield takeLatest(types.TAKE_SEAT, workerTakeSeat);
	yield takeLatest(types.GET_TUTORIAL, workerGetTutorials);
}

function getAllCoaches() {
	return Coaching.getAllCoaches();
}
function* workerCoaching() {
	try {
		const response = yield call(getAllCoaches);
		yield put(CoachingAction.getAllCoachesActionSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(CoachingAction.getAllCoachesActionFailure());
	}
}

function canUserTakeCoach() {
	return Coaching.canTakeCoach();
}

function getIsUserHasCoach() {
	return Coaching.getIsUserHasCoach();
}

function userPhoneNumberConfirmation() {
	return PhoneConfirmation.checkPhoneConfirm();
}

function getUserPhoneNumber() {
	return PhoneConfirmation.getUserPhoneNumber();
}
function* workerUserCanTakeCoach() {
	try {
		const response = yield call(canUserTakeCoach);
		if (response.status === 200) {
			yield put(CoachingAction.getUserCanTakeCoachActionSuccess(response.data.ResponseData));
			if (response.data.ResponseData) {
				const phoneConfirmResponse = yield call(userPhoneNumberConfirmation);
				yield put(PhoneConfirmationAction.getUserPhoneConfirm());
				if (phoneConfirmResponse.status === 200) {
					if (phoneConfirmResponse.data.ResponseData.PhoneNumberConfirmed) {
						yield put(
							PhoneConfirmationAction.getUserPhoneConfirmSuccess(
								phoneConfirmResponse.data.ResponseData.PhoneNumberConfirmed,
							),
						);
					} else {
						yield put(
							PhoneConfirmationAction.getUserPhoneConfirmSuccess(
								phoneConfirmResponse.data.ResponseData.PhoneNumberConfirmed,
							),
						);
						const userPhoneNumberResponse = yield call(getUserPhoneNumber);
						yield put(PhoneConfirmationAction.getUserPhoneNumber());
						if (userPhoneNumberResponse.status === 200) {
							if (userPhoneNumberResponse.data.ResponseData) {
								yield put(
									PhoneConfirmationAction.getUserPhoneNumberSuccess(
										userPhoneNumberResponse.data.ResponseData.PhoneNumber,
									),
								);
							}
						} else {
							yield put(CoachingAction.getUserPhoneNumberFailure());
							toast.error('Bir Hata Oluştu!');
						}
					}
				} else {
					yield put(CoachingAction.getUserPhoneConfirmFailure());
					toast.error('Bir Hata Oluştu!');
				}
			} else {
				try {
					const userHasCoach = yield call(getIsUserHasCoach);
					if (userHasCoach.status === 200) {
						yield put(CoachingAction.getUserHasCoachAction());
						const { ResponseData, ResponseStatus } = userHasCoach.data;
						if (ResponseStatus === 1) {
							yield put(CoachingAction.getUserHasCoachActionSuccess(ResponseData));
						} else {
							yield put(CoachingAction.getUserHasCoachActionFailure());
							yield put(CoachingAction.getAllCoachesActionFailure());
							toast.error('Bir Hata Oluştu!');
						}
					} else {
						yield put(CoachingAction.getUserHasCoachActionFailure());
						yield put(CoachingAction.getAllCoachesActionFailure());
						toast.error('Bir Hata Oluştu!');
					}
				} catch (error) {
					if (error && error.data && error.data.Error) {
						toast.error(error.data.Error.toString());
					}
					yield put(CoachingAction.getAllCoachesActionFailure());
				}
			}
		} else {
			toast.error('Bir Hata Oluştu!');
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(CoachingAction.getUserCanTakeCoachActionFailure());
	}
}

function takeSeat(CoachId) {
	return Coaching.takeSeat(CoachId);
}
function* workerTakeSeat(action) {
	try {
		const response = yield call(takeSeat, action.data);
		if (response.data.ResponseStatus) {
			yield put(CoachingAction.takeSeatActionSuccess(true));
			yield put(CoachingAction.getAllCoachesAction());
			yield put(PhoneConfirmationAction.getUserPhoneConfirmFailure());
			try {
				const coaches = yield call(getAllCoaches);
				const userHasCoach = yield call(getIsUserHasCoach);
				yield put(CoachingAction.getAllCoachesActionSuccess(coaches.data.ResponseData));
				yield put(CoachingAction.getUserHasCoachActionSuccess(userHasCoach.data.ResponseData));
			} catch (error) {
				if (error && error.data && error.data.Error) {
					toast.error(error.data.Error.toString());
				}
				yield put(CoachingAction.getAllCoachesActionFailure());
			}
		} else {
			yield put(CoachingAction.takeSeatActionSuccess(false));
			toast.error('Yeterli Kredi Bulunamadı');
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(CoachingAction.takeSeatActionFailure());
	}
}

function getTutorials() {
	return Coaching.getTutorial();
}
function* workerGetTutorials() {
	try {
		const response = yield call(getTutorials);
		yield put(CoachingAction.getTutorialActionSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(CoachingAction.getTutorialActionFailure());
	}
}