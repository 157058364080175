import firebase from "firebase/app";


const visitPage=(pageName)=>{
    const analytics = firebase.analytics();
    analytics.logEvent('PAGE_VISIT_'+pageName);
    //console.log("VisitedPage",pageName)
}
//Send user actions
const logEvent=(eventName,data)=>{
    const analytics = firebase.analytics();
    analytics.logEvent(eventName,data);
}
export default visitPage;
export {visitPage,logEvent}