export const FlagType = {
	Statistics: 'Statistics',
	Dashboard: 'Dashboard',
	TestSection: 'TestSection',
	Solved: 'Solved',
	TriamExamDetail: 'TrialExamDetail',
};

export const FlagHelper = {
	set(name, value) {
		return localStorage.setItem(`shouldUpdate${name}`, value);
	},
	get(name) {
		let res = false;
		try {
			res = JSON.parse(localStorage.getItem(`shouldUpdate${name}`) || false);
		} catch (error) {
			res = false;
		}
		return res;
	},

	remove(name) {
		return localStorage.removeItem(`shouldUpdate${name}`);
	},

	async clearAll() {
		const keys = [...FlagType];
		try {
			keys.forEach(k => localStorage.removeItem(k));
		} catch (e) {
			// remove error
		}
	},

	async setAllFlags() {
		try {
			this.set(FlagType.Solved,true);
			this.set(FlagType.Statistics,true);
			this.set(FlagType.Dashboard,true);
		} catch (e) {
			//save error
		}
	},
};

// export const setUpdateDashboardFlag = () => {
// 	localStorage.setItem('shouldUpdateDashboard', 'true');
// };

// export const getUpdateDashboardFlag = () => {
// 	return localStorage.getItem('shouldUpdateDashboard');
// };

// export const removeUpdateDashboardFlag = () => {
// 	localStorage.removeItem('shouldUpdateDashboard');
// };

// export const setUpdateStatisticsFlag = () => {
// 	localStorage.setItem('shouldUpdateStatistics', 'true');
// };

// export const getUpdateStatisticsFlag = () => {
// 	return localStorage.getItem('shouldUpdateStatistics');
// };

// export const removeUpdateStatisticsFlag = () => {
// 	localStorage.removeItem('shouldUpdateStatistics');
// };

// export const setUpdateSolvedFlag = () => {
// 	localStorage.setItem('shouldUpdateSolved', 'true');
// };

// export const getUpdateSolvedFlag = () => {
// 	return localStorage.getItem('shouldUpdateSolved');
// };

// export const removeUpdateSolvedFlag = () => {
// 	localStorage.removeItem('shouldUpdateSolved');
// };

// export const setUpdateTestSectionFlag = () => {
// 	localStorage.setItem('shouldUpdateTestSection', 'true');
// };

// export const getUpdateTestSectionFlag = () => {
// 	return localStorage.getItem('shouldUpdateTestSection');
// };

// export const removeUpdateTestSectionFlag = () => {
// 	localStorage.removeItem('shouldUpdateTestSection');
// };

// export const setUpdateTasksFlag = () => {
// 	localStorage.setItem('shouldUpdateTasks', 'true');
// };

// export const getUpdateTasksFlag = () => {
// 	return localStorage.getItem('shouldUpdateTasks');
// };

// export const removeUpdateTasksFlag = () => {
// 	localStorage.removeItem('shouldUpdateTasks');
// };
