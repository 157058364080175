import * as types from '../constants/actionTypes';

export const getDashboardInfo = () => {
	return {
		type: types.GET_DASHBOARD_INFO_REQUEST,
	};
};
export const getDashboardInfoSuccess = data => {
	return {
		type: types.GET_DASHBOARD_INFO_SUCCESS,
		payLoad: data,
	};
};
export const getDashboardInfoFailure = () => {
	return {
		type: types.GET_DASHBOARD_INFO_FAILURE,
	};
};
