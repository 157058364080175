import * as types from '../constants/actionTypes';

export const updateTrackingAction = (tracking) => ({
	type: types.UPDATE_TRACKING_ACTION,
	tracking,
});

export const addTrackingAction = (tracking) => ({
	type: types.ADD_TRACKING_ACTION,
	tracking,
});

export const deleteTracking = (id) => ({
	type: types.DELETE_TRACKING,
	id,
});

export const setAll = (data, options) => ({
	type: types.SET_ALL,
	data,
	options,
});
