import * as types from '../constants/actionTypes';

export const getYksPoint = payLoad => {
	return {
		type: types.GET_YKS_POINT,
		payLoad,
	};
};

export const getYksPointSuccess = data => {
	return {
		type: types.GET_YKS_POINT_SUCCESS,
		payLoad: data,
	};
};

export const getYksPointFailure = () => {
	return {
		type: types.GET_YKS_POINT_FAILURE,
	};
};

export const saveGradePoint = payLoad => {
	return {
		type: types.HANDLE_GRADE_POINT,
		payLoad,
	};
};

export const saveGradePointSuccess = data => {
	return {
		type: types.HANDLE_GRADE_POINT_SUCCESS,
		payLoad: data,
	};
};

export const saveGradePointFailure = () => {
	return {
		type: types.HANDLE_GRADE_POINT_FAILURE,
	};
};

export const saveUserRecords = payLoad => {
	return {
		type: types.SAVE_USER_RECORDS,
		payLoad,
	};
};

export const saveUserRecordsSuccess = data => {
	return {
		type: types.SAVE_USER_RECORDS_SUCCESS,
		payLoad: data,
	};
};

export const saveUserRecordsFailure = () => {
	return {
		type: types.SAVE_USER_RECORDS_FAILURE,
	};
};

export const getList = payLoad => {
	return {
		type: types.GET_LIST,
		payLoad,
	};
};

export const getListSuccess = data => {
	return {
		type: types.GET_LIST_SUCCESS,
		payLoad: data,
	};
};

export const getListFailure = () => {
	return {
		type: types.GET_LIST_FAILURE,
	};
};

export const saveInputs = payLoad => {
	return {
		type: types.SAVE_INPUTS,
		payLoad,
	};
};

export const postList = payLoad => {
	return {
		type: types.POST_LIST,
		payLoad,
	};
};

export const postListSuccess = data => {
	return {
		type: types.POST_LIST_SUCCESS,
		payLoad: data,
	};
};

export const postListFailure = () => {
	return {
		type: types.POST_LIST_FAILURE,
	};
};

export const preferenceList = payLoad => {
	return {
		type: types.PREFERENCE_LIST,
		payLoad,
	};
};

export const removeFromPreferenceList = payLoad => {
	return {
		type: types.REMOVE_FROM_PREFERENCE_LIST,
		payLoad,
	};
};

export const removeAllItemsFromPreferenceList = () => {
	return {
		type: types.REMOVE_ALL_ITEMS_FROM_PREFERENCE_LIST,
	};
};

export const addOption = payLoad => {
	return {
		type: types.ADD_OPTION,
		payLoad,
	};
};

export const addOptionSuccess = data => {
	return {
		type: types.ADD_OPTION_SUCCESS,
		payLoad: data,
	};
};

export const addOptionFailure = () => {
	return {
		type: types.ADD_OPTION_FAILURE,
	};
};

export const getOption = payLoad => {
	return {
		type: types.GET_OPTION,
		payLoad,
	};
};

export const getOptionSuccess = data => {
	return {
		type: types.GET_OPTION_SUCCESS,
		payLoad: data,
	};
};

export const getOptionFailure = () => {
	return {
		type: types.GET_OPTION_FAILURE,
	};
};

export const removeFromGetOption = payLoad => {
	return {
		type: types.REMOVE_FROM_GET_OPTION,
		payLoad,
	};
};

export const getResult = payLoad => {
	return {
		type: types.GET_RESULT,
		payLoad,
	};
};

export const getResultSuccess = data => {
	return {
		type: types.GET_RESULT_SUCCESS,
		payLoad: data,
	};
};

export const getResultFailure = () => {
	return {
		type: types.GET_RESULT_FAILURE,
	};
};

export const getUniversityRanks = payLoad => {
	return {
		type: types.GET_UNIVERSITY_RANKS,
		payLoad,
	};
};

export const getUniversityRanksSuccess = data => {
	return {
		type: types.GET_UNIVERSITY_RANKS_SUCCESS,
		payLoad: data,
	};
};

export const getUniversityRanksFailure = () => {
	return {
		type: types.GET_UNIVERSITY_RANKS_FAILURE,
	};
};

export const handleUserRanks = payLoad => {
	return {
		type: types.HANDLE_USER_RANKS,
		payLoad,
	};
};

export const handleSelectedCities = payLoad => {
	return {
		type: types.HANDLE_SELECTED_CITIES,
		payLoad,
	};
};

export const handleProgramTuitionType = payLoad => {
	return {
		type: types.HANDLE_PROGRAM_TUITION_TYPE,
		payLoad,
	};
};

export const handleProgramFee = payLoad => {
	return {
		type: types.HANDLE_PROGRAM_FEE,
		payLoad,
	};
};

export const getAllRank = payLoad => {
	return {
		type: types.GET_ALL_RANK,
		payLoad,
	};
};

export const getAllRankSuccess = payLoad => {
	return {
		type: types.GET_ALL_RANK_SUCCESS,
		payLoad,
	};
};

export const getAllRankFailure = () => {
	return {
		type: types.GET_ALL_RANK_FAILURE,
	};
};

export const getUniversityDetails = (code) => {
	return {
		type: types.GET_UNIVERSITY_DETAILS,
		payLoad: code
	};
};

export const getUniversityDetailsSuccess = payLoad => {
	return {
		type: types.GET_UNIVERSITY_DETAILS_SUCCESS,
		payLoad,
	};
};

export const getUniversityDetailsFailure = () => {
	return {
		type: types.GET_UNIVERSITY_DETAILS_FAILURE,
	};
};

export const getUniversityConditionDetails = (conditions) => {
	return {
		type: types.GET_UNIVERSITY_CONDITION_DETAILS,
		payLoad: conditions
	};
};

export const getUniversityConditionDetailsSuccess = payLoad => {
	return {
		type: types.GET_UNIVERSITY_CONDITION_DETAILS_SUCCESS,
		payLoad,
	};
};

export const getUniversityConditionDetailsFailure = () => {
	return {
		type: types.GET_UNIVERSITY_CONDITION_DETAILS_FAILURE,
	};
};
