import { apiCaller } from '../helpers/apiCaller';
import ConvertJsonToQueryString from '../helpers/convertJsonToQueryString';

export const login = postData => {
	return apiCaller({ needToken: false }).post(
		`
	${process.env.REACT_APP_API_URL}/api/Account/loginv2`,
		postData,
	);
};

export const register = postData => {
	return apiCaller({ needToken: false }).post(`${process.env.REACT_APP_API_URL}/api/Account/registerweb`, postData);
};

export const loginWithFacebook = postData => {
	return apiCaller({ needToken: false }).post(`${process.env.REACT_APP_API_URL}/api/Account/facebook`, postData);
};

export const loginWithGoogle = postData => {
	return apiCaller({ needToken: false }).post(`${process.env.REACT_APP_API_URL}/api/Account/google`, postData);
}

export const getVerifyCode = () => {
	return apiCaller({ needToken: true }).get(`
	${process.env.REACT_APP_API_URL}/api//Auth/send-code`);
};

export const updateEmail = email => {
	return apiCaller({ needToken: true }).get(`
	${process.env.REACT_APP_API_URL}/api/Account/emailupdate?${ConvertJsonToQueryString({ email })}`);
};

export const forgetPassword = postData => {
	return apiCaller({ needToken: false }).post(
		`
	${process.env.REACT_APP_API_URL}/api/Account/forgetpasswordv2`,
		postData,
	);
};

export const getScreenCode = postData => {
	return apiCaller({ needToken: false }).post(
		`
	${process.env.REACT_APP_API_URL}/api/content/getbyscreencode`,
		postData,
	);
};

export const logout = postData => {
	return apiCaller({ needToken: true }).post(
		`
	${process.env.REACT_APP_API_URL}/api/account/logout`,
		postData,
	);
};

export const checklogin = postData => {
	return apiCaller({ needToken: true }, { isTeacher: true}).post(
		`
	${process.env.REACT_APP_API_URL}/api/account/checkloginV3`,
		postData,
	);
};
export const getGeneratedCode = () => {
	return apiCaller({ needToken: false }).get(`
	${process.env.REACT_APP_API_URL}/api/account/getlogincode`);
};
export const checkLoginCode = postData => {
	return apiCaller({ needToken: false }).post(
		`
	${process.env.REACT_APP_API_URL}/api/account/checklogincode`,
		postData,
	);
};
export const clearLoginCode = postData => {
	return apiCaller({ needToken: false }).post(
		`
	${process.env.REACT_APP_API_URL}/api/account/clearcode`,
		postData,
	);
};