export const shouldShowAnswer = (
	showTestInstructionModal,
	showAchievementRewardAnimation,
	isStayClicked,
	achievementQuestionData,
	currentAchievementTestSectionElementIdSession,
	currentAchievementTestSectionElementId,
	setIsAnswerVisible,
) => {
	if (showTestInstructionModal || showAchievementRewardAnimation) {
		setIsAnswerVisible(true);
		return;
	}
	setIsAnswerVisible(false);
	let sty = isStayClicked;
	if (!isStayClicked) {
		const stay = sessionStorage.getItem('isStay');
		if (stay) {
			const stayObject = JSON.parse(stay);
			sty = stayObject.TestSectionElementId === achievementQuestionData.TestSectionElementId;
		}
	}
	if (sty) {
		setIsAnswerVisible(true);
		return;
	}
	setIsAnswerVisible(achievementQuestionData?.TestSectionElementId !== currentAchievementTestSectionElementIdSession &&
		achievementQuestionData?.TestSectionElementId !== currentAchievementTestSectionElementId);
};