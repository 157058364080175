import { apiCaller } from '../helpers/apiCaller';

export const getPublisherRates = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/accountprofile/getpublisherrates`,
		data,
	);
};

export const updatePublisherRates = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/AccountProfile/updatepublisherrates`,
		data,
	);
};

export const changePassword = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/AccountProfile/changepassword`,
		data,
	);
};

export const getProfileInfo = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/AccountProfile/getprofileinfo`);
};

export const updateProfile = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/AccountProfile/updateprofile`, data);
};

export const updateUserExamCategory = id => {
	return apiCaller({ needToken: true }).put(
		`${process.env.REACT_APP_API_URL}/api/accountprofile/examcategory/${id}`,
		null,
	);
};
