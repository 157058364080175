import * as types from '../constants/actionTypes';

export const createLectureSection = data => {
	return {
		type: types.CREATE_LECTURE_SECTION_REQUEST,
		data,
	};
};
export const createLectureSectionSuccess = data => {
	return {
		type: types.CREATE_LECTURE_SECTION_SUCCESS,
		payLoad: data,
	};
};
export const createLectureSectionFailure = () => {
	return {
		type: types.CREATE_LECTURE_SECTION_FAILURE,
	};
};

export const getLectureSection = data => {
	return {
		type: types.GET_LECTURE_SECTION_REQUEST,
		data,
	};
};
export const getLectureSectionSuccess = data => {
	return {
		type: types.GET_LECTURE_SECTION_SUCCESS,
		payLoad: data,
	};
};
export const getLectureSectionFailure = () => {
	return {
		type: types.GET_LECTURE_SECTION_FAILURE,
	};
};

export const getLecture = data => {
	return {
		type: types.GET_LECTURE_REQUEST,
		data,
	};
};
export const getLectureSuccess = data => {
	return {
		type: types.GET_LECTURE_SUCCESS,
		payLoad: data,
	};
};
export const getLectureFailure = () => {
	return {
		type: types.GET_LECTURE_FAILURE,
	};
};

export const updateLectureSection = data => {
	return {
		type: types.UPDATE_LECTURE_SECTION_REQUEST,
		data,
	};
};
export const updateLectureSectionSuccess = data => {
	return {
		type: types.UPDATE_LECTURE_SECTION_SUCCESS,
		payLoad: data,
	};
};
export const updateLectureSectionFailure = () => {
	return {
		type: types.UPDATE_LECTURE_SECTION_FAILURE,
	};
};

// export const getLastSeenLectureOrder = () => {
// 	return {
// 		type: types.GET_LAST_SEEN_LECTURE_ORDER,
// 	};
// };
// export const setLastSeenLectureOrder = lastSeenLectureOrder => {
// 	return {
// 		type: types.SET_LAST_SEEN_LECTURE_ORDER,
// 		payLoad: lastSeenLectureOrder,
// 	};
// };
