import { apiCaller } from '../helpers/apiCaller';

export const getPrintableAnswerForm = data => {
	return apiCaller({ needToken: true ,isErrorHandled:true}).post(`${process.env.REACT_APP_API_URL}/api/PrintableTest/get-answerform`, data);
};
export const createTest = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/PrintableTest/create-test`, data);
};

export const getPrintableTestFile = data => {
	return apiCaller({ needToken: true }).post( `${process.env.REACT_APP_API_URL}/api/PrintableTest/get-filev3`, data);
};

export const getFile =  `${process.env.REACT_APP_API_URL}/api/PrintableTest/get-file`;

export const createFileUrl = `${process.env.REACT_APP_API_URL}/api/PrintableTest/create-file`
export const createKttTest = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/PrintableTest/create-optional-test`, data);
};

export const getParameters	= data => {
	return apiCaller({ needToken: true }, { isTeacher: true }).post(`${process.env.REACT_APP_API_URL}/api/PrintableTest/get-parameters`, data);
}