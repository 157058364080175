import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as SchoolPanelApi from '../apis/schoolPanel';
import { BookAction, MediaFileAction, PrintableTestAction, SchoolPanelAction } from '../actions';
import { toast } from 'react-toastify';
import { createHomeworkPopupActiveTab } from '../selectors/selectors';
import { SendHomeworkTabs, TabsStep } from '../enums/SPHomeworks';
import ResponseStatus from '../mobileAssets/enums/ResponseStatus';
import { History } from '../helpers/history';

export function* watcherSchoolPanel() {
	yield takeLatest(types.GET_SCHOOL_PANEL_FILTERS, workerSchoolPanelFilters);
	yield takeLatest(types.ADD_SCHOOL_PANEL_FILTER, workerCreateSchoolPanelFilters);
	yield takeLatest(types.DELETE_SCHOOL_PANEL_FILTER, workerDeleteSchoolPanelFilter);
	yield takeLatest(types.ADD_SCHOOL_PANEL_FILTER_TAG, workerCreateSchoolPanelFilterTag);
	yield takeLatest(types.DELETE_SCHOOL_PANEL_FILTER_TAG, workerDeleteSchoolPanelFilterTag);
	yield takeLatest(types.EDIT_SCHOOL_PANEL_FILTER, workerEditSchoolPanelFilter);
	yield takeLatest(types.EDIT_SCHOOL_PANEL_FILTER_TAG, workerEditSchoolPanelFilterTag);
	yield takeLatest(types.GET_SCHOOL_PANEL_STUDENTS, workerSchoolPanelStudents);
	yield takeLatest(types.ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG, workerSchoolPanelUpdateStudentTag);
	yield takeLatest(types.DELETE_SCHOOL_PANEL_STUDENT_TAG, workerDeleteSchoolPanelStudentTag);
	yield takeLatest(types.GET_CORPORATE_USER_SOLVED, workerCorporateUserSolved);
	yield takeLatest(types.HANDLE_SCHOOL_PANEL_FILTER_IDS, workerHandleSchoolPanelFilterIds);
	yield takeLatest(types.HANDLE_SCHOOL_PANEL_TAG_IDS, workerHandleSchoolPanelTagIds);
	yield takeLatest(types.HANDLE_SCHOOL_PANEL_USER_IDS, workerHandleSchoolPanelUserIds);
	yield takeLatest(types.GET_CORPORATE_STUDY_STATISTICS, workerSchoolPanelGetCorporateStudyStatistics);
	yield takeLatest(types.HANDLE_STUDENTS_COUNT, workerStudentsCount);
	yield takeLatest(types.GET_HOMEWORK_TYPE, workerGetHomeworkType);
	yield takeLatest(types.CREATE_HOMEWORK, workerCreateHomework);
	yield takeLatest(types.UPDATE_HOMEWORK, workerUpdateHomework);
	yield takeLatest(types.GET_HOMEWORKS, workerGetHomeworks);
	yield takeLatest(types.DELETE_HOMEWORK, workerDeleteHomework);
	yield takeLatest(types.CLONE_HOMEWORK, workerCloneHomework);
	yield takeLatest(types.TRACING_BY_ID_HOMEWORK, workerTracingByIdHomework);
	yield takeLatest(types.INFO_BY_ID_HOMEWORK, workerInfoByIdHomework);
	yield takeLatest(types.SEND_HOMEWORK_NOTIFICATION, workerSendHomeworkNotification);
	yield takeLatest(types.GET_NOTIF_TYPES, workerGetNotifTypes);
	yield takeLatest(types.GET_BY_ID, workerGetById);
	yield takeLatest(types.UPDATE_HOMEWORK_STATUS, workerUpdateHomeworkStatus);
}

function getSchoolPanelFilters() {
	return SchoolPanelApi.schoolPanelFilters();
}

function* workerSchoolPanelFilters() {
	try {
		const response = yield call(getSchoolPanelFilters);
		yield put(SchoolPanelAction.getSchoolPanelFiltersSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.getSchoolPanelFiltersFailure());
	}
}

function addSchoolPanelFilter(data) {
	return SchoolPanelApi.addSchoolPanelFilter(data);
}

function* workerCreateSchoolPanelFilters(action) {
	try {
		const response = yield call(addSchoolPanelFilter, action.payLoad);
		yield put(SchoolPanelAction.addSchoolPanelFilterSuccess(response.data));
		const response2 = yield call(getSchoolPanelFilters, {});
		yield put(SchoolPanelAction.getSchoolPanelFiltersFailure());
		yield put(SchoolPanelAction.getSchoolPanelFiltersSuccess(response2.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.addSchoolPanelFilterFailure());
	}
}

function deleteSchoolPanelFilter(data) {
	return SchoolPanelApi.deleteSchoolPanelFilter(data);
}

function* workerDeleteSchoolPanelFilter(action) {
	try {
		yield call(deleteSchoolPanelFilter, action.data);
		const response2 = yield call(getSchoolPanelFilters, {});
		yield put(SchoolPanelAction.getSchoolPanelFiltersFailure());
		yield put(SchoolPanelAction.getSchoolPanelFiltersSuccess(response2.data));
		const response = yield call(getSchoolPanelStudents);
		yield put(SchoolPanelAction.getSchoolPanelStudentsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.deleteSchoolPanelFilterFailure());
		yield put(SchoolPanelAction.getSchoolPanelStudentsFailure());
	}
}

function addSchoolPanelFilterTag(filterId, tagName) {
	return SchoolPanelApi.addSchoolPanelFilterTag(filterId, tagName);
}

function* workerCreateSchoolPanelFilterTag(action) {
	try {
		const response = yield call(addSchoolPanelFilterTag, action.payLoad.filterId.toString(), action.payLoad.tagName);
		yield put(SchoolPanelAction.addSchoolPanelFilterTagSuccess(response.data));
		const response2 = yield call(getSchoolPanelFilters, {});
		yield put(SchoolPanelAction.getSchoolPanelFiltersFailure());
		yield put(SchoolPanelAction.getSchoolPanelFiltersSuccess(response2.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.addSchoolPanelFilterTagFailure());
	}
}

function deleteSchoolPanelFilterTag(data) {
	return SchoolPanelApi.deleteSchoolPanelFilterTag(data);
}

function* workerDeleteSchoolPanelFilterTag(action) {
	try {
		yield call(deleteSchoolPanelFilterTag, action.data);
		const response2 = yield call(getSchoolPanelFilters, {});
		yield put(SchoolPanelAction.getSchoolPanelFiltersFailure());
		yield put(SchoolPanelAction.getSchoolPanelFiltersSuccess(response2.data));
		const response = yield call(getSchoolPanelStudents);
		yield put(SchoolPanelAction.getSchoolPanelStudentsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.deleteSchoolPanelFilterTagFailure());
		yield put(SchoolPanelAction.getSchoolPanelStudentsFailure());
	}
}

function editSchoolPanelFilter(filterId, filterName) {
	return SchoolPanelApi.updateFilter(filterId, filterName);
}

function* workerEditSchoolPanelFilter(action) {
	try {
		const response = yield call(editSchoolPanelFilter, action.payLoad.filterId.toString(), action.payLoad.filterName);
		yield put(SchoolPanelAction.editSchoolPanelFilterSuccess(response.data));
		const response2 = yield call(getSchoolPanelFilters, {});
		yield put(SchoolPanelAction.getSchoolPanelFiltersFailure());
		yield put(SchoolPanelAction.getSchoolPanelFiltersSuccess(response2.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.editSchoolPanelFilterFailure());
	}
}

function editSchoolPanelFilterTag(tagId, tagName) {
	return SchoolPanelApi.updateFilterTag(tagId, tagName);
}

function* workerEditSchoolPanelFilterTag(action) {
	try {
		const response = yield call(editSchoolPanelFilterTag, action.payLoad.tagId.toString(), action.payLoad.tagName);
		yield put(SchoolPanelAction.editSchoolPanelFilterTagSuccess(response.data));
		const response2 = yield call(getSchoolPanelFilters, {});
		yield put(SchoolPanelAction.getSchoolPanelFiltersFailure());
		yield put(SchoolPanelAction.getSchoolPanelFiltersSuccess(response2.data));
		const response3 = yield call(getSchoolPanelStudents);
		yield put(SchoolPanelAction.getSchoolPanelStudentsSuccess(response3.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.editSchoolPanelFilterTagFailure());
		yield put(SchoolPanelAction.getSchoolPanelStudentsFailure());
	}
}

function getSchoolPanelStudents() {
	return SchoolPanelApi.schoolPanelStudents();
}

function* workerSchoolPanelStudents() {
	try {
		const response = yield call(getSchoolPanelStudents);
		yield put(SchoolPanelAction.getSchoolPanelStudentsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.getSchoolPanelStudentsFailure());
	}
}

function addOrUpdateStudentTag(studentId, tagId, filterId) {
	return SchoolPanelApi.addOrUpdateStudentTag(studentId, tagId, filterId);
}

function* workerSchoolPanelUpdateStudentTag(action) {
	try {
		const response = yield call(
			addOrUpdateStudentTag,
			action.payLoad.studentId,
			action.payLoad.tagId,
			action.payLoad.filterId,
		);
		yield put(SchoolPanelAction.addOrUpdateSchoolPanelStudentTagSuccess(response.data));
		const response2 = yield call(getSchoolPanelStudents, {});
		yield put(SchoolPanelAction.getSchoolPanelStudentsSuccess(response2.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.addOrUpdateSchoolPanelStudentTagFailure());
		yield put(SchoolPanelAction.getSchoolPanelStudentsFailure());

	}
}

function deleteSchoolPanelStudentTag(studentId, tagId, filterId) {
	return SchoolPanelApi.deleteUserTag(studentId, tagId, filterId);
}

function* workerDeleteSchoolPanelStudentTag(action) {
	try {
		yield call(deleteSchoolPanelStudentTag, action.payLoad.studentId, action.payLoad.tagId, action.payLoad.filterId);
		const response2 = yield call(getSchoolPanelStudents, {});
		yield put(SchoolPanelAction.getSchoolPanelStudentsSuccess(response2.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.deleteSchoolPanelStudentTagFailure());
		yield put(SchoolPanelAction.getSchoolPanelStudentsFailure());
	}
}

function getCorporateUserSolved(data) {
	return SchoolPanelApi.getCorporateUserSolved(data);
}

function* workerCorporateUserSolved(action) {
	try {
		const response = yield call(getCorporateUserSolved, action.payLoad);
		yield put(SchoolPanelAction.getCorporateUserSolvedSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.getCorporateUserSolvedFailure());
	}
}

function handleSchoolPanelFilterIds(filterIds) {
}

function* workerHandleSchoolPanelFilterIds(action) {
	try {
		handleSchoolPanelFilterIds(action.payLoad);
		SchoolPanelAction.handleSchoolPanelFilterIdsSuccess(action.payLoad);
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		SchoolPanelAction.handleSchoolPanelFilterIdsFailure();
	}
}

function handleSchoolPanelTagIds(tagIds) {
}

function* workerHandleSchoolPanelTagIds(action) {
	try {
		handleSchoolPanelTagIds(action.payLoad);
		SchoolPanelAction.handleSchoolPanelTagIdsSuccess(action.payLoad);
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		SchoolPanelAction.handleSchoolPanelTagIdsFailure();
	}
}

function handleSchoolPanelUserIds(userIds) {
}

function* workerHandleSchoolPanelUserIds(action) {
	try {
		handleSchoolPanelUserIds(action.payLoad);
		SchoolPanelAction.handleSchoolPanelUserIdsSuccess(action.payLoad);
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		SchoolPanelAction.handleSchoolPanelUserIdsFailure();
	}
}

function handleCorporateStudyStatistics(data) {
	return SchoolPanelApi.getCorporateStudyStatistics(data);
}

function* workerSchoolPanelGetCorporateStudyStatistics(action) {
	try {
		const response = yield call(handleCorporateStudyStatistics, action.payLoad);
		yield put(SchoolPanelAction.handleCorporateStudyStatisticsSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.handleCorporateStudyStatisticsFailure());
	}
}

function handleStudentsCount() {
}

function* workerStudentsCount(action) {
	try {
		handleStudentsCount(action.payLoad);
		SchoolPanelAction.handleStudentsCount(action.payLoad);
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
	}
}

function getHomeworkType() {
	return SchoolPanelApi.getHomeworkType();
}

function* workerGetHomeworkType() {
	try {
		const response = yield call(getHomeworkType);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(SchoolPanelAction.getHomeworkTypeSuccess(response.data.ResponseData));
			yield put(SchoolPanelAction.setShowCreateHomeworkPopup(true));
		} else {
			toast.error(response.data?.ResponseMessage);
			yield put(SchoolPanelAction.getHomeworkTypeFailure());
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.getHomeworkTypeFailure());
	}
}

function createHomework(data) {
	return SchoolPanelApi.createHomework(data);
}

function* workerCreateHomework(action) {
	try {
		const response = yield call(createHomework, action.payLoad);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(SchoolPanelAction.createHomeworkSuccess(response.data?.ResponseData));
			yield put(SchoolPanelAction.setConfirmedSelectedHomeworkType(action.payLoad?.HomeworkType));
			yield put(SchoolPanelAction.setShouldUpdateHomework(true));
		} else {
			toast.error(response.data?.ResponseMessage);
			yield put(SchoolPanelAction.createHomeworkFailure());
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.createHomeworkFailure());
	}
}

function getById(id) {
	return SchoolPanelApi.getById(id);
}

function* workerGetById(action) {
	try {
		const response = yield call(getById, action.payLoad);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(SchoolPanelAction.getByIdSuccess(response.data?.ResponseData));
			yield put(SchoolPanelAction.setShowCreateHomeworkPopup(true));
		} else {
			toast.error(response.data?.ResponseMessage);
			yield put(SchoolPanelAction.getByIdFailure());
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.getByIdFailure());
	}
}

function updateHomework(data) {
	return SchoolPanelApi.updateHomework(data);
}

function* workerUpdateHomework(action) {
	try {
		const response = yield call(updateHomework, action.payLoad);
		const activeTab = yield select(createHomeworkPopupActiveTab);
		yield put(SchoolPanelAction.updateHomeworkSuccess(response.data));
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(SchoolPanelAction.setShouldUpdateHomework(true));
			if (activeTab === TabsStep.Deadline) {
				try {
					const responseGetById = yield call(getById, action.payLoad?.Id);
					yield put(SchoolPanelAction.getByIdSuccess(responseGetById.data?.ResponseData));
					yield put(SchoolPanelAction.setCreateHomeworkPopupActiveTab(activeTab + 1));
				} catch (error) {
					if (error && error.data && error.data.Error) {
						toast.error(error.data.Error.toString());
					}
				}
			} else {
				yield put(SchoolPanelAction.setCreateHomeworkPopupActiveTab(activeTab + 1));
			}
		} else {
			toast.error(response.data.ResponseMessage);
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.updateHomeworkFailure());
	}
}

function getHomeworks() {
	return SchoolPanelApi.getHomeworks();
}

function* workerGetHomeworks() {
	try {
		const response = yield call(getHomeworks);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(SchoolPanelAction.getHomeworksSuccess(response.data?.ResponseData));
			yield put(SchoolPanelAction.setIsUnAuthorizedTeacher(false));
		} else {
			if (response.data.ResponseMessage.includes('Yetkisiz')) {
				yield put(SchoolPanelAction.setIsUnAuthorizedTeacher(true));
			}
			toast.error(response.data?.ResponseMessage);
			yield put(SchoolPanelAction.getHomeworksFailure());
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.getHomeworksFailure());
	}
}

function deleteHomework(id) {
	return SchoolPanelApi.deleteHomework(id);
}

function* workerDeleteHomework(action) {
	try {
		const response = yield call(deleteHomework, action.payLoad);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			try {
				const response2 = yield call(getHomeworks);
				if (response2.data.ResponseStatus === ResponseStatus.Success) {
					yield put(SchoolPanelAction.deleteHomeworkSuccess(response.data));
					yield put(SchoolPanelAction.getHomeworksSuccess(response2.data?.ResponseData));
				} else {
					yield put(SchoolPanelAction.getHomeworksFailure());
					yield put(SchoolPanelAction.deleteHomeworkFailure());
				}
			} catch (error) {
				if (error && error.data && error.data.Error) {
					toast.error(error.data.Error.toString());
				}
				yield put(SchoolPanelAction.deleteHomeworkFailure());
				yield put(SchoolPanelAction.getHomeworksFailure());
			}
		} else {
			toast.error(response.data.ResponseMessage);
			yield put(SchoolPanelAction.deleteHomeworkFailure());
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.deleteHomeworkFailure());
	}
}

function cloneHomework(id) {
	return SchoolPanelApi.cloneHomework(id);
}

function* workerCloneHomework(action) {
	try {
		const response = yield call(cloneHomework, action.payLoad);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			const response2 = yield call(getHomeworks);
			if (response2.data.ResponseStatus === ResponseStatus.Success) {
				yield put(SchoolPanelAction.getHomeworksSuccess(response2.data?.ResponseData));
				yield put(SchoolPanelAction.cloneHomeworkSuccess(response.data.ResponseData));
				yield put(SchoolPanelAction.createHomeworkSuccess(response.data.ResponseData));
			} else {
				toast(response2.data.ResponseMessage);
				yield put(SchoolPanelAction.cloneHomeworkFailure());
			}
		} else {
			toast(response.data.ResponseMessage);
			yield put(SchoolPanelAction.cloneHomeworkFailure());
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.cloneHomeworkFailure());
	}
}

function tracingByIdHomework(id) {
	return SchoolPanelApi.tracingByIdHomework(id);
}

function* workerTracingByIdHomework(action) {
	try {
		const response = yield call(tracingByIdHomework, action.payLoad);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(SchoolPanelAction.tracingByIdHomeworkSuccess(response.data.ResponseData));
			yield put(SchoolPanelAction.setShowHomeworkInfoPopup(true));
		} else {
			toast.error(response.data.ResponseMessage);
			yield put(SchoolPanelAction.tracingByIdHomeworkFailure());
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.tracingByIdHomeworkFailure());
	}
}

function infoByIdHomework(id) {
	return SchoolPanelApi.infoByIdHomework(id);
}

function* workerInfoByIdHomework(action) {
	try {
		const response = yield call(infoByIdHomework, action.payLoad.Id);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(SchoolPanelAction.infoByIdHomeworkSuccess(response.data?.ResponseData));
			yield put(SchoolPanelAction.setCreateHomeworkPopupActiveTab(TabsStep.HomeworkInfo));
			History.push(`/schoolPanel/homeworkInfo/${action.payLoad.Id}/${action.payLoad.HomeworkType}`);
		} else {
			toast.error(response.data.ResponseMessage);
			yield put(SchoolPanelAction.infoByIdHomeworkFailure());
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.infoByIdHomeworkFailure());
	}
}

function sendHomeworkNotification(id) {
	return SchoolPanelApi.sendHomeworkNotification(id);
}

function* workerSendHomeworkNotification(action) {
	try {
		const response = yield call(sendHomeworkNotification, action.payLoad);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(SchoolPanelAction.sendHomeworkNotificationSuccess(response.data));
			yield put(SchoolPanelAction.setShouldUpdateHomework(true));
			yield put(SchoolPanelAction.setShowCreateHomeworkPopup(false));
			yield put(SchoolPanelAction.clearAllCreateHomeworkPopupData());
			yield put(BookAction.clearHomeworkData());
			yield put(PrintableTestAction.clearPdfDataForHomeworks());
			yield put(MediaFileAction.clearUploadFile());
			yield put(SchoolPanelAction.homeworkSendSuccessPopup(true));
			const response2 = yield call(getHomeworks);
			yield put(SchoolPanelAction.getHomeworksSuccess(response2.data.ResponseData));
		} else {
			toast.error(response.data.ResponseMessage);
			yield put(SchoolPanelAction.sendHomeworkNotificationFailure());
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.sendHomeworkNotificationFailure());
	}
}

function getNotifTypes() {
	return SchoolPanelApi.getNotifTypes();
}

function* workerGetNotifTypes() {
	try {
		const response = yield call(getNotifTypes);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(SchoolPanelAction.getNotifTypesSuccess(response.data.ResponseData));
			yield put(SchoolPanelAction.setActiveContent(SendHomeworkTabs.NotificationOption));
		} else {
			toast.error(response.data.ResponseMessage);
			yield put(SchoolPanelAction.getNotifTypesFailure());
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.setActiveContent(SendHomeworkTabs.SendOption));
		yield put(SchoolPanelAction.getNotifTypesFailure());
	}
}

function updateHomeworkStatus(data) {
	return SchoolPanelApi.updateHomeworkStatus(data);
}

function* workerUpdateHomeworkStatus(action) {
	try {
		const response = yield call(updateHomeworkStatus, action.payLoad);
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(SchoolPanelAction.updateHomeworkStatusSuccess(response.data?.ResponseData));
			const response2 = yield call(getHomeworks);
			if (response2.data.ResponseStatus === ResponseStatus.Success) {
				yield put(SchoolPanelAction.getHomeworksSuccess(response2.data?.ResponseData));
			} else {
				toast.error(response2.data.ResponseMessage);
				yield put(SchoolPanelAction.getHomeworksFailure());
			}
		} else {
			toast.error(response.data.ResponseMessage);
			yield put(SchoolPanelAction.updateHomeworkStatusFailure());
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(SchoolPanelAction.updateHomeworkStatusFailure());
	}
}