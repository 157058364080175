import React from 'react';
import NavBar from './navBar';
import Logo from '../component/logo';

export default ({role, collapse, toggleLeftSidebar }) => {
	return (
		<>
			<Logo toggleLeftSidebar={toggleLeftSidebar} collapse={collapse} />
			<div className=' overflow-auto h-body'>
				<div className={`${collapse ? 'px-2 lg:px-4' : 'px-4 lg:px-6 pb-4'}`}>
					<NavBar role={role} collapse={collapse} toggleLeftSidebar={toggleLeftSidebar} />
				</div>
			</div>
		</>
	);
};
