import ZoomType from '../../../enums/ZoomType';

export const adjustZoomLevel = (type, zoom, setZoom) => {
	let newZoom = zoom;

	if (type === ZoomType.ZoomIn) {
		newZoom = zoom === 0.5 ? zoom + 0.5 : Math.min(zoom + 1, 5);
	} else if (type === ZoomType.ZoomOut) {
		newZoom = zoom > 1 ? zoom - 1 : zoom === 0.5 ? zoom : zoom - 0.5;
	}

	if (newZoom !== zoom) {
		setZoom(newZoom);
		sessionStorage.setItem('zoom', newZoom);
	}
};