import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as accountApi from '../apis/auth';
import { AccountAction } from '../actions';
import { setLocalStorageWithExpiry, setLocalStorage, getLocalStorage } from '../helpers/storage';
import { History } from '../helpers/history';
import { toast } from 'react-toastify';
import * as QueryString from 'query-string';
import { setToken } from '../helpers/tokenManager';

import { getToken } from '../helpers/tokenManager';
import { FlagHelper } from '../helpers/FlagHelper';

export function* watcherAccount() {
	yield takeLatest(types.LOGIN_REQUEST, workerLogin);
	yield takeLatest(types.REGISTER_REQUEST, workerRegister);
	yield takeLatest(types.GET_VERIFY_CODE_REQUEST, workerGetVerifyCode);
	yield takeLatest(types.UPDATE_EMAIL_REQUEST, workerUpdateEmail);
}

function getParams() {
	const params = QueryString.parse(window.location.search);
	return params;
}
function login(data) {
	FlagHelper.set('Dashboard', true);
	FlagHelper.set('Solved', true);
	FlagHelper.set('Statistics', true);
	FlagHelper.set('TestSection', true);
	return accountApi.login(data);
}
function* workerLogin(action) {
	console.log("login Worker login")
	try {
		const response = yield call(login, action.data);
		// set token time one day
		const now = new Date();
		let tomorrow = new Date(now);
		tomorrow.setDate(tomorrow.getDate() + 1);
		setToken(response.data.JwtToken);
		yield put(AccountAction.setUserLottery(response.data.UserLottery));
		yield put(AccountAction.setUserCoupon(response.data.UserCoupon));
		setLocalStorageWithExpiry('userId', response.data.UserId, tomorrow);
		setLocalStorage('userInfo', response.data);
		const param = getParams();

		document.cookie = `pakodemyToken=${getToken('pakodemyToken')};domain=.pakodemy.com;`;
		if (param.redirect && param.redirect !== '') {
			window.open(
				param.redirect,
				'_blank', // <- This is what makes it open in a new window.
			);
		}

		yield put(AccountAction.loginSuccess(response.data));
		localStorage.setItem('userExamCategory', response.data.ExamCategoryId);
		History.replace('/');
		window.location.reload();
	} catch (error) {
		if (error && error.data && error.data.Error) {
			let errorMessage = '';
			if (error.data.Error === 'User account is not activate.') {
				errorMessage = 'Bu hesap kapatılmıştır.';
			} else {
				errorMessage = 'Lütfen bilgilerinizi kontrol edin. ';
			}
			toast.error(errorMessage);
		}
		yield put(AccountAction.loginFailure());
	}
}
function register(data) {
	FlagHelper.set('Dashboard', true);
	FlagHelper.set('Solved', true);
	FlagHelper.set('Statistics', true);
	FlagHelper.set('TestSection', true);
	return accountApi.register(data);
}
function* workerRegister(action) {
	try {
		const response = yield call(register, action.data);
		// set token time one day
		const now = new Date();
		let tomorrow = new Date(now);
		tomorrow.setDate(tomorrow.getDate() + 1);
		setToken(response.data.JwtToken);

		document.cookie = `pakodemyToken=${response.data.JwtToken};domain=.pakodemy.com;path=http://web.pakodemy.com/`;
		// setLocalStorageWithExpiry('token', response.data.JwtToken, tomorrow);
		setLocalStorageWithExpiry('userId', response.data.UserId, tomorrow);
		setLocalStorage('userInfo', response.data);

		yield put(AccountAction.registerSuccess(response.data));
		localStorage.setItem('userExamCategory', response.data.ExamCategoryId);
		const param = yield select(getParams);
		if (param.redirect_uri && param.redirect_uri !== '') {
			window.open(
				param.redirect_uri,
				'_blank', // <- This is what makes it open in a new window.
			);
		}
		History.replace('/introduction');
	} catch (errorr) {
		if (errorr && errorr.data && errorr.data.Error) {
			const error = errorr.data.Error;
			let errorMessage = 'Beklenmeyen bir hata oluştu';
			if (error === 'User already have been registered') {
				errorMessage = 'Bu e-posta adresi ile bir kullanıcı zaten kayıtlı.';
			} else if (error.includes('is already taken')) {
				errorMessage = 'Bu hesap kapatılmıştır.';
			} else if (error.includes('can only contain letters or digits.')) {
				errorMessage = 'E-posta adresiniz geçerli değil.';
			} else {
				//console.log(errorMessage);
				errorMessage = error;
			}
			toast.error(errorMessage.toString());
		}
		yield put(AccountAction.registerFailure());
	}
}

function getVerifyCode() {
	return accountApi.getVerifyCode();
}
function* workerGetVerifyCode(action) {
	try {
		const response = yield call(getVerifyCode, action.data);
		yield put(AccountAction.getVerifyCodeSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AccountAction.getVerifyCodeFailure());
	}
}

function updateEmail(email) {
	return accountApi.updateEmail(email);
}
function* workerUpdateEmail(action) {
	try {
		const response = yield call(updateEmail, action.email);

		if (response.status === 200) {
			const info = getLocalStorage('userInfo');
			info.Email = response.data.ResponseData.Email;
			setLocalStorage('userInfo', info);
		}
		yield put(AccountAction.updateEmailSuccess(response.data));
		toast.warning(response.data.ResponseMessage.toString());
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AccountAction.updateEmailFailure());
	}
}
