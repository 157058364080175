import * as React from "react";
const NoIdeaOutlined = (props) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 6 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M0.511745 3.16868C-0.0199569 2.86772 -0.169801 2.1166 0.216892 1.62528C0.673673 1.04136 1.47606 0.447156 2.8319 0.503747C4.56235 0.578345 6.0632 1.8465 5.99795 3.57768C5.95203 4.78153 5.08439 5.48121 3.95814 5.93908C3.55937 6.10628 3.5352 6.45098 3.51828 6.85483C3.50378 7.23554 3.20409 7.53135 2.8464 7.51592L2.23494 7.4902C1.87725 7.47476 1.59932 7.15579 1.61382 6.77509L1.63799 6.12429C1.65732 5.5841 1.69841 5.14423 2.23011 4.80983C2.95275 4.35967 3.61012 4.35195 3.64396 3.47993C3.66571 2.89087 3.26693 2.45615 2.74731 2.433C2.27603 2.41242 1.93526 2.63621 1.69841 2.92688C1.40356 3.29215 0.912939 3.39762 0.511745 3.17125V3.16868ZM3.78171 9.6561C3.75271 10.4304 3.14126 11.0297 2.40171 10.9989C1.67424 10.968 1.11112 10.3172 1.14012 9.54292C1.16912 8.75836 1.78058 8.15643 2.50805 8.1873C3.24518 8.21817 3.81072 8.86897 3.78171 9.6561Z"
			fill="#FDA420"
		/>
	</svg>
);
export default NoIdeaOutlined;
