import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as CoursesApi from '../apis/courses';
import { CoursesAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherCourses() {
	yield takeLatest(types.GET_DETAILS, workerGetDetails);
	yield takeLatest(types.GET_USER_SOLVED_PROGRESS, workerGetUserSolvedProgress);
}

function getDetails(data) {
	return CoursesApi.getDetails(data);
}

function* workerGetDetails({ data }) {
	try {
		const result = yield call(CoursesApi.getDetails, data);
		yield put(CoursesAction.getDetailsSuccess(result.data));
	} catch (error) {
		yield put(CoursesAction.getDetailsFail());
		toast.error('Beklenmedik bir hata oluştu');
	}
}

function getUserSolvedProgress(data) {
	return CoursesApi.getUserSolvedProgress(data);
}

function* workerGetUserSolvedProgress({ data }) {
	try {
		const result = yield call(CoursesApi.getUserSolvedProgress, data);
		yield put(CoursesAction.getUserSolvedProgressSuccess(result.data));
	} catch (error) {
		yield put(CoursesAction.getUserSolvedProgressFail());
		toast.error('Beklenmedik bir hata oluştu');
	}
}
