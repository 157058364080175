import * as types from '../constants/actionTypes';

export const getStudyStatistics = filter => {
	return {
		type: types.GET_STUDY_STATISTICS_REQUEST,
		filter,
	};
};
export const getStudyStatisticsSuccess = data => {
	return {
		type: types.GET_STUDY_STATISTICS_SUCCESS,
		payLoad: data,
	};
};
export const getStudyStatisticsFailure = () => {
	return {
		type: types.GET_STUDY_STATISTICS_FAILURE,
	};
};

export const getUserSolved = () => {
	return {
		type: types.GET_USER_SOLVED_REQUEST,
	};
};
export const getUserSolvedSuccess = data => {
	return {
		type: types.GET_USER_SOLVED_SUCCESS,
		payLoad: data,
	};
};
export const getUserSolvedFailure = () => {
	return {
		type: types.GET_USER_SOLVED_FAILURE,
	};
};

export const getUserSolvedSummary = data => {
	return {
		type: types.GET_USER_SOLVED_SUMMARY_REQUEST,
		data,
	};
};
export const getUserSolvedSummarySuccess = data => {
	return {
		type: types.GET_USER_SOLVED_SUMMARY_SUCCESS,
		payLoad: data,
	};
};
export const getUserSolvedSummaryFailure = () => {
	return {
		type: types.GET_USER_SOLVED_SUMMARY_FAILURE,
	};
};

export const getUserDaily = filter => {
	return {
		type: types.GET_USER_DAILY,
		filter,
	};
};

export const getUserDailySuccess = data => {
	return {
		type: types.GET_USER_DAILY_SUCCESS,
		payLoad: data,
	};
};

export const getTestSectionSummaryAnalytics = data => {
	return {
		type: types.GET_TEST_SECTION_SUMMARY_ANALYTICS,
		data,
	};
};

export const getTestSectionSummaryAnalyticsSuccess = data => {
	return {
		type: types.GET_TEST_SECTION_SUMMARY_ANALYTICS_SUCCESS,
		payLoad: data,
	};
};

export const getTestSectionSummaryAnalyticsFailure = () => {
	return {
		type: types.GET_TEST_SECTION_SUMMARY_ANALYTICS_FAILURE,
	};
};

export const getElementCount = data => {
	return {
		type: types.GET_ELEMENT_COUNT,
		data,
	};
}

export const getElementCountSuccess = data => {
	return {
		type: types.GET_ELEMENT_COUNT_SUCCESS,
		payLoad: data,
	};
}

export const getElementCountFailure = () => {
	return {
		type: types.GET_ELEMENT_COUNT_FAILURE,
	};
}

export const getElementSummary = data => {
	return {
		type: types.GET_ELEMENT_SUMMARY,
		data,
	};
};

export const getElementSummarySuccess = data => {
	return {
		type: types.GET_ELEMENT_SUMMARY_SUCCESS,
		payLoad: data,
	};
};

export const getElementSummaryFailure = () => {
	return {
		type: types.GET_ELEMENT_SUMMARY_FAILURE,
	};
};

export const setShowSummaryQuestions = data => {
	return {
		type: types.SET_SHOW_SUMMARY_QUESTIONS,
		payLoad: data,
	};
};