import * as types from '../constants/actionTypes';

const initialState = {
	userPhoneNumberConfirmation: {
		loading: false,
		isUserPhoneNumberConfirmed: false,
	},
	userPhoneNumber: {
		loading: false,
		phoneNumber: null,
	},
	expireTime: {
		loading: false,
		keyExpireTime: 0,
	},
	checkSmsCode: {
		loading: false,
		isOK: true,
	},
};

const PhoneConfirmation = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_USER_PHONE_CONFIRMATION:
			return {
				...state,
				userPhoneNumberConfirmation: { ...state.userPhoneNumberConfirmation, loading: true },
			};

		case types.GET_USER_PHONE_CONFIRMATION_SUCCESS:
			return {
				...state,
				userPhoneNumberConfirmation: { isUserPhoneNumberConfirmed: payLoad, loading: false },
			};

		case types.GET_USER_PHONE_CONFIRMATION_FAILURE:
			return {
				...state,
				userPhoneNumberConfirmation: { isUserPhoneNumberConfirmed: false, loading: false },
			};

		case types.GET_USER_PHONE_NUMBER:
			return {
				...state,
				userPhoneNumber: { ...state.userPhoneNumber, loading: true },
			};

		case types.GET_USER_PHONE_NUMBER_SUCCESS:
			return {
				...state,
				userPhoneNumber: { phoneNumber: payLoad, loading: false },
			};

		case types.GET_USER_PHONE_NUMBER_FAILURE:
			return {
				...state,
				userPhoneNumber: { phoneNumber: null, loading: false },
			};

		case types.GET_SMS_CODE:
			return {
				...state,
				expireTime: { ...state.expireTime, loading: true },
			};

		case types.GET_SMS_CODE_SUCCESS:
			return {
				...state,
				expireTime: { keyExpireTime: payLoad, loading: false },
			};

		case types.GET_SMS_CODE_FAILURE:
			return {
				...state,
				expireTime: { keyExpireTime: 0, loading: false },
			};

		case types.CHECK_SMS_CODE:
			return {
				...state,
				checkSmsCode: { ...state.checkSmsCode, loading: true },
			};

		case types.CHECK_SMS_CODE_SUCCESS:
			return {
				...state,
				checkSmsCode: { isOK: payLoad, loading: false },
			};

		case types.CHECK_SMS_CODE_FAILURE:
			return {
				...state,
				checkSmsCode: { isOK: true, loading: false },
			};
		default:
			return state;
	}
};

export default PhoneConfirmation;