import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as dashboardApi from '../apis/dashboard';
import { DashboardAction } from '../actions';
import { toast } from 'react-toastify';
import { getLocalStorageWithExpiry, clearLocalStorage } from '../helpers/storage';
import { History } from '../helpers/history';

export function* watcherDashboard() {
	yield takeLatest(types.GET_DASHBOARD_INFO_REQUEST, workerGetDashboardInfo);
}

function getDashboardInfo(data) {
	return dashboardApi.getDashboardInfo(data);
}
function* workerGetDashboardInfo() {
	try {
		const UserId = getLocalStorageWithExpiry('userId');
		const postData = {
			UserId,
			deviceChannelCode: 'Web',
		};
		const response = yield call(getDashboardInfo, postData);
		yield put(DashboardAction.getDashboardInfoSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(DashboardAction.getDashboardInfoFailure());
	}
}
