import { apiCaller } from '../helpers/apiCaller';

export const checkPhoneConfirm = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/account/checkphoneconfirm`);
};

export const getUserPhoneNumber = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/account/getusernumber`);
};

export const getSmsCode = request => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/account/getsmscode`, request);
};

export const checkSmsCode = request => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/account/checksmscode`, request);
};