import { takeLatest } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import { NavBarAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherNavBar() {
	yield takeLatest(types.HANDLE_COLLAPSE, workerNavBarCollapse);
}

function handleNavBarCollapse() {}
function* workerNavBarCollapse(action) {
	try {
		handleNavBarCollapse(action.payLoad);
		NavBarAction.handleNavBarCollapse(action.payLoad);
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
	}
}
