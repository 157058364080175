import styled from 'styled-components';
import Variables from '../../constants/styleVariables';

export const ButtonWrapper = styled.div`
	cursor: pointer;
	border-radius: 32px;
	display: flex;
	justify-content: center;
	align-items: center;

	${props => {
		switch (props.type) {
			case 'primary':
				return `
				background-color: ${Variables.colors.blue[800]};
				color: ${Variables.colors.white};
			`;
			case 'secondary':
				return `
				background-color: ${Variables.colors.gray[300]};
				color: ${Variables.colors.white};
			`;
			case 'warning':
				return `
				background-color: ${Variables.colors.orange[400]};
				color: ${Variables.colors.white};
			`;
			case 'success':
				return `
				background-color: ${Variables.colors.green[400]};
				color: ${Variables.colors.white};
			`;
			case 'primary-outline':
				return `
				background-color: ${Variables.colors.white};
				color: ${Variables.colors.blue[800]};
				border: 1px solid ${Variables.colors.blue[800]};
			`;
			case 'secondary-outline':
				return `
				background-color: ${Variables.colors.white};
				color: ${Variables.colors.gray[300]};
				border: 1px solid ${Variables.colors.gray[300]};
			`;
			case 'warning-outline':
				return `
				background-color: ${Variables.colors.white};
				color: ${Variables.colors.orange[300]};
				border: 1px solid ${Variables.colors.orange[400]};
			`;
			case 'success-outline':
				return `
				background-color: ${Variables.colors.white};
				color: ${Variables.colors.green[300]};
				border: 1px solid ${Variables.colors.green[400]};
			`;
			case 'danger':
				return `
				background-color: ${Variables.colors.red[700]};
				color: ${Variables.colors.white};
			`;
		}
	}};
	opacity: ${props => (props.disabled ? '.5' : '1')};
	cursor: ${props => (props.disabled ? 'not-allowed' : '')};
	${props => {
		switch (props.size) {
			case 'sm':
				return `
					padding: 8px 23px;
					font-size: ${Variables.fontSize.xs};
					font-weight: ${Variables.fontWeight.medium};
					border-radius: 31px;
					min-width: 100px;
					min-height: 30px;
				`;
			case 'md':
				return `
					padding: 13px 23px;
					font-size: ${Variables.fontSize.sm};
					font-weight: ${Variables.fontWeight.semibold};
					border-radius: 12px;
					min-width: 171px;
					min-height: 44px;
					@media (min-width: 1024px) {
						font-size:${Variables.fontSize.base};
					}
				`;
			case 'sm-wide':
				return `
					padding: 8px 23px;
					font-size: ${Variables.fontSize.xs};
					font-weight: ${Variables.fontWeight.medium};
					border-radius: 31px;
					min-width: 185px;
					min-height: 30px;
				`;
		}
	}};
`;
