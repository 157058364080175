import React, { useEffect, useState } from 'react';
import '../../styles/app.css';
import { Route, Switch } from 'react-router-dom';
import { DashboardLayout, PublicLayout } from '../../layouts';
import Routes from '../../routes';
import NotFound from '../../components/notFound';
import { ConnectedRouter } from 'connected-react-router';
import { History } from '../../helpers/history';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { getToken } from '../../helpers/tokenManager';
import { checklogin } from '../../apis/auth';
import { getUniqueDeviceId, setLocalStorageWithExpiry } from '../../helpers/storage';
import { useDispatch, useSelector } from 'react-redux';
import { setUserCoupon, setUserLottery } from '../../actions/account';
import { getActiveCredits } from '../../apis/userCredits';
import { FlagHelper } from '../../helpers/FlagHelper';
import visitPage from '../../helpers/firebaseHelper';
import { getPublisherImage } from '../../apis/publishers';
import {
	getSchoolPanelFilters,
	getSchoolPanelStudents,
	handleSchoolPanelFilterIds,
	handleSchoolPanelTagIds,
} from '../../actions/schoolPanel';

const isTeacher = JSON.parse(localStorage.getItem('userInfo'))?.Role;

function App() {
	const dispatch = useDispatch();
	// const filters = useSelector(state => state.SchoolPanel.getSchoolPanelFilters.data);
const [userRole,setUserRole] = useState(null)
	const isMobile = {
		Android: function () {
			const isTablet =
				/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
					navigator.userAgent,
				);
			return navigator.userAgent.match(/Android/i) && !isTablet;
		},
		iOS: function () {
			return navigator.userAgent.match(/iPhone|iPod/i);
		},
	};
	useEffect(() => {
		if (isMobile.iOS()) {
			window.location.href = '//apps.apple.com/us/app/pakodemy/id1481710296?l=tr&ls=1';
		}
		if (isMobile.Android()) {
			window.location.href = '//play.google.com/store/apps/details?id=com.pakodemy';
		}
		return History.listen(location => {
			visitPage(location.pathname);
		});
	}, []);


	// useEffect(() => {
	// 	// İlk render'da tüm filter checkbox'ları seçili hale getir
	// 	if (filters && filters.length > 0 && isTeacher == 'Teachers') {
	// 		const allTagIds = filters.flatMap(filter => filter.SchoolfilterTags.map(tag => tag.TagId));
	// 		const allFilterIds = filters.map(filter => filter.FilterId);
	// 		handleSchoolPanelFilterIds(allFilterIds);
	// 		handleSchoolPanelTagIds(allTagIds);
	// 	}
	// }, [filters.length]);
	// console.log('<<<<<<<<<< filters >>>>>>>>>>>>>', filters);

	const layoutManage = (item, key) => {
		switch (item.layout) {
			case 'dashboard':
				return (
					<Route
						key={key}
						exact={true}
						path={item.path}
						render={route => {
							const crumbs = Routes.filter(({ path }) => route.match.path.includes(path)).map(({ path, ...rest }) => ({
								path: Object.keys(route.match.params).length
									? Object.keys(route.match.params).reduce(
											(path, param) => path.replace(`:${param}`, route.match.params[param]),
											path,
									  )
									: path,
								...rest,
							}));
							return (
								<DashboardLayout
								role={userRole}
									breadCrumbs={crumbs}
									route={route}
									Component={item.component}
									options={item.options || {}}
								/>
							);
						}}
					/>
				);
			case 'public':
				return (
					<Route
						key={key}
						exact={true}
						path={item.path}
						render={route => <PublicLayout route={route} Component={item.component} options={item.options || {}} />}
					/>
				);
			default:
				return (
					<Route key={key} exact={true} path={item.path} component={item.component} options={item.options || {}} />
				);
		}
	};
	const switchRoutes = () => {
		return Routes.map((route, key) => {
			return layoutManage(route, key);
		});
	};
	const publishersFromApi = async () => {
		const res = await getPublisherImage();
		localStorage.removeItem('publishers');
		if (res.status === 200) {
			const data = res.data.ResponseData;

			localStorage.setItem('publishers', JSON.stringify(data));
		}
	};
	const updateUser = async () => {
		const userInfo = JSON.parse(localStorage.getItem('userInfo'));
		if (!userInfo) {
			History.replace('/login');
			return;
		}
		const reqlogin = {
			UserId: userInfo.UserId,
			DeviceId: getUniqueDeviceId(),
			OSType: 3,
			DeviceType: 3,
		};
		const res = await checklogin(reqlogin);

		const now = new Date();
		let tomorrow = new Date(now);
		tomorrow.setDate(tomorrow.getDate() + 1);
		if (res.status === 200) {
			if(res.data.Role === 'Teachers'){
				dispatch(getSchoolPanelFilters());
				dispatch(getSchoolPanelStudents());
			}
			localStorage.setItem('userInfo', JSON.stringify(res.data));
			if(res.data.ExamCategoryId>0) localStorage.setItem('userExamCategory',res.data.ExamCategoryId)
			setLocalStorageWithExpiry('userId', res.data.UserId, tomorrow)
			dispatch(setUserCoupon(res.data.UserCoupon));
			dispatch(setUserLottery(res.data.UserLottery));
			setUserRole(res.data.Role)
			if (userInfo.Version < res.data.Version) {
				localStorage.removeItem('courses');
			}
		} else {
			localStorage.removeItem('userInfo');
		}
	};

	const updateUserActiveCredits = async () => {
		const creditres = await getActiveCredits();
		sessionStorage.removeItem('Credits');
		if (creditres.status === 200) {
			sessionStorage.setItem('Credits', creditres.data.ResponseData);
		}
	};

	useEffect(() => {
		const token = getToken('pakodemyToken');
		if (token) {
			updateUser();
			updateUserActiveCredits();
			publishersFromApi();
			FlagHelper.set('Dashboard', true);
			FlagHelper.set('Solved', true);
			FlagHelper.set('Statistics', true);
			FlagHelper.set('TestSection', true);
			FlagHelper.set('Tasks', true);
		}
	}, []);

	const validPath = ['/register', '/login', '/forget-password', '/verify', '/','/codeLogin'];
	if (
		!getToken('pakodemyToken') &&
		(validPath.indexOf(window.location.pathname) === -1 || window.location.pathname === '/')
	) {
		window.location.replace('/login');
		return null;
	} else {
		return (
			<ConnectedRouter history={History}>
				<ToastContainer
					position="top-right"
					autoClose={parseInt(process.env.REACT_APP_TOASTER_CLOSE_TIMEOUT, 10)}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					style={{ zIndex: 999999 }}
				/>
				<Switch>
					{switchRoutes()}
					<Route component={NotFound} />
				</Switch>
			</ConnectedRouter>
		);
	}
}

export default App;
