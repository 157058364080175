import * as types from '../constants/actionTypes';

export const uploadFile = (formData) => ({
	type: types.UPLOAD_FILE,
	payload: { formData },
});

export const uploadFileSuccess = (data) => ({
	type: types.UPLOAD_FILE_SUCCESS,
	payload: data,
});

export const uploadFileFailure = (error) => ({
	type: types.UPLOAD_FILE_FAILURE,
	payload: error,
});

export const clearUploadFile = () => ({
	type: types.CLEAR_UPLOAD_FILE,
});