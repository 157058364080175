import * as types from '../constants/actionTypes';

const initialState = {
	getNestedTitles: {
		loading: false,
		data: [],
	},
	getCourses: {
		loading: false,
		data: [],
	},
	getSubCourseTopics: {
		loading: false,
		data: [],
	},
	checkLectureContent: {
		loading: false,
		data: null,
	},
	getAchievements: {
		loading: false,
		data: null,
	},
	getAchievementUserProgress: {
		loading: false,
		data: null,
	},
	updateAchievementVideoUser: {
		loading: false,
		data: null,
	},
	addAchievementVideoDuration: {
		loading: false,
		data: null,
	},
	getAchievementVideoReward: {
		loading: false,
		data: null,
	},
	getSubCourses: {
		loading: false,
		data: [],
	},
	getAchievementRewardedGift: {
		loading: false,
		data: [],
	},
	getAchievementTestInstruction: {
		loading: false,
		data: [],
	},
	showAchievementTestDetailModal: false,
	getElementCount: {
		loading: false,
		data: [],
	},
	showTestInstructionModal: false,
	getAchievementReward : {
		loading: false,
		data: [],
	},
	showAchievementRewardAnimation: false,
};

const Book = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_NESTED_TITLES_REQUEST:
			return {
				...state,
				getNestedTitles: { ...state.getNestedTitles, loading: true },
			};
		case types.GET_NESTED_TITLES_SUCCESS:
			return {
				...state,
				getNestedTitles: { data: payLoad, loading: false },
			};
		case types.GET_NESTED_TITLES_FAILURE:
			return {
				...state,
				getNestedTitles: { ...state.getNestedTitles, loading: false },
			};
		case types.GET_COURSES:
			return {
				...state,
				getCourses: { ...state.getCourses, loading: true },
			};
		case types.GET_COURSES_SUCCESS:
			return {
				...state,
				getCourses: { data: payLoad, loading: false },
			};
		case types.GET_COURSES_FAILURE:
			return {
				...state,
				getCourses: { ...state.getCourses, loading: false },
			};
		case types.GET_SUB_COURSE_TOPICS:
			return {
				...state,
				getSubCourseTopics: { ...state.getSubCourseTopics, loading: true },
			};
		case types.GET_SUB_COURSE_TOPICS_SUCCESS:
			return {
				...state,
				getSubCourseTopics: { data: payLoad, loading: false },
			};
		case types.GET_SUB_COURSE_TOPICS_FAILURE:
			return {
				...state,
				getSubCourseTopics: { ...state.getSubCourseTopics, loading: false },
			};
		case types.CHECK_LECTURE_CONTENT:
			return {
				...state,
				checkLectureContent: { ...state.checkLectureContent, loading: true },
			};
		case types.CHECK_LECTURE_CONTENT_SUCCESS:
			return {
				...state,
				checkLectureContent: { data: payLoad, loading: false },
			};
		case types.CHECK_LECTURE_CONTENT_FAILURE:
			return {
				...state,
				checkLectureContent: { ...state.checkLectureContent, loading: false },
			};
		case types.GET_ACHIEVEMENTS:
			return {
				...state,
				getAchievements: { ...state.getAchievements, loading: true },
			};
		case types.GET_ACHIEVEMENTS_SUCCESS:
			return {
				...state,
				getAchievements: { data: payLoad, loading: false },
			};
		case types.GET_ACHIEVEMENTS_FAILURE:
			return {
				...state,
				getAchievements: { ...state.getAchievements, loading: false },
			};
		case types.GET_ACHIEVEMENT_USER_PROGRESS:
			return {
				...state,
				getAchievementUserProgress: { ...state.getAchievementUserProgress, loading: true },
			};
		case types.GET_ACHIEVEMENT_USER_PROGRESS_SUCCESS:
			return {
				...state,
				getAchievementUserProgress: { data: payLoad, loading: false },
			};
		case types.GET_ACHIEVEMENT_USER_PROGRESS_FAILURE:
			return {
				...state,
				getAchievementUserProgress: { ...state.getAchievementUserProgress, loading: false },
			};
		case types.UPDATE_ACHIEVEMENT_VIDEO_USER:
			return {
				...state,
				updateAchievementVideoUser: { ...state.updateAchievementVideoUser, loading: true },
			};
		case types.UPDATE_ACHIEVEMENT_VIDEO_USER_SUCCESS:
			return {
				...state,
				updateAchievementVideoUser: { data: payLoad, loading: false },
			};
		case types.UPDATE_ACHIEVEMENT_VIDEO_USER_FAILURE:
			return {
				...state,
				updateAchievementVideoUser: { ...state.updateAchievementVideoUser, loading: false },
			};
		case types.ADD_ACHIEVEMENT_VIDEO_DURATION:
			return {
				...state,
				addAchievementVideoDuration: { ...state.addAchievementVideoDuration, loading: true },
			};
		case types.ADD_ACHIEVEMENT_VIDEO_DURATION_SUCCESS:
			return {
				...state,
				addAchievementVideoDuration: { data: payLoad, loading: false },
			};
		case types.ADD_ACHIEVEMENT_VIDEO_DURATION_FAILURE:
			return {
				...state,
				addAchievementVideoDuration: { ...state.addAchievementVideoDuration, loading: false },
			};
		case types.RESET_ALL_DATA:
			return {
				...state,
				getNestedTitles: {
					loading: false,
					data: [],
				},
				getCourses: {
					loading: false,
					data: [],
				},
				getSubCourseTopics: {
					loading: false,
					data: [],
				},
				checkLectureContent: {
					loading: false,
					data: null,
				},
				getAchievements: {
					loading: false,
					data: null,
				},
				getAchievementUserProgress: {
					loading: false,
					data: null,
				},
				updateAchievementVideoUser: {
					loading: false,
					data: null,
				},
				addAchievementVideoDuration: {
					loading: false,
					data: null,
				},
			};
		case types.GET_ACHIEVEMENT_VIDEO_REWARD:
			return {
				...state,
				getAchievementVideoReward: { ...state.getAchievementVideoReward, loading: true },
			};
		case types.GET_ACHIEVEMENT_VIDEO_REWARD_SUCCESS:
			return {
				...state,
				getAchievementVideoReward: { data: payLoad, loading: false },
			};
		case types.GET_ACHIEVEMENT_VIDEO_REWARD_FAILURE:
			return {
				...state,
				getAchievementVideoReward: { ...state.getAchievementVideoReward, loading: false },
			};
		case types.GET_SUB_COURSES:
			return {
				...state,
				getSubCourses: { ...state.getSubCourses, loading: true },
			};
		case types.GET_SUB_COURSES_SUCCESS:
			return {
				...state,
				getSubCourses: { data: payLoad, loading: false },
			};
		case types.GET_SUB_COURSES_FAILURE:
			return {
				...state,
				getSubCourses: { ...state.getSubCourses, loading: false },
			};
		case types.CLEAR_HOMEWORK_DATA:
			return {
				...state,
				getSubCourses: {
					loading: false,
					data: [],
				},
			};
		case types.GET_ACHIEVEMENT_REWARDED_GIFT:
			return {
				...state,
				getAchievementRewardedGift: { ...state.getAchievementRewardedGift, loading: true },
			};
		case types.GET_ACHIEVEMENT_REWARDED_GIFT_SUCCESS:
			return {
				...state,
				getAchievementRewardedGift: { data: payLoad, loading: false },
			};
		case types.GET_ACHIEVEMENT_REWARDED_GIFT_FAILURE:
			return {
				...state,
				getAchievementRewardedGift: { ...state.getAchievementRewardedGift, loading: false },
			};
		case types.GET_ACHIEVEMENT_TEST_INSTRUCTION:
			return {
				...state,
				getAchievementTestInstruction: { ...state.getAchievementTestInstruction, loading: true },
			};
		case types.GET_ACHIEVEMENT_TEST_INSTRUCTION_SUCCESS:
			return {
				...state,
				getAchievementTestInstruction: { data: payLoad, loading: false },
			};
		case types.GET_ACHIEVEMENT_TEST_INSTRUCTION_FAILURE:
			return {
				...state,
				getAchievementTestInstruction: { ...state.getAchievementTestInstruction, loading: false },
			};
		case types.SET_SHOW_ACHIEVEMENT_TEST_DETAIL_MODAL:
			return {
				...state,
				showAchievementTestDetailModal: payLoad,
			};
		case types.GET_ELEMENT_COUNT:
			return {
				...state,
				getElementCount: { ...state.getElementCount, loading: true },
			};
		case types.GET_ELEMENT_COUNT_SUCCESS:
			return {
				...state,
				getElementCount: { data: payLoad, loading: false },
			};
		case types.GET_ELEMENT_COUNT_FAILURE:
			return {
				...state,
				getElementCount: { data: [], loading: false },
			};
		case types.GET_ACHIEVEMENT_TEST_DETAIL:
			return {
				...state,
				getAchievementTestInstruction: { ...state.getAchievementTestInstruction, loading: true },
				getElementCount: { ...state.getElementCount, loading: true },
			};
		case types.SET_SHOW_TEST_INSTRUCTION_MODAL:
			return {
				...state,
				showTestInstructionModal: payLoad,
			};
		case types.GET_ACHIEVEMENT_REWARD:
			return {
				...state,
				getAchievementReward: { ...state.getAchievementReward, loading: true },
			};
		case types.GET_ACHIEVEMENT_REWARD_SUCCESS:
			return {
				...state,
				getAchievementReward: { data: payLoad, loading: false },
			};
		case types.GET_ACHIEVEMENT_REWARD_FAILURE:
			return {
				...state,
				getAchievementReward: { ...state.getAchievementReward, loading: false },
			};
		case types.SHOW_ACHIEVEMENT_REWARD_ANIMATION:
			return {
				...state,
				showAchievementRewardAnimation: payLoad,
			};
		default:
			return state;
	}
};

export default Book;
