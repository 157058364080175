import * as types from '../constants/actionTypes';

const initialState = {
	promotionBanner: {
		loading: false,
		data: [],
	},
};

const Coaches = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_PROMOTION_BANNER:
			return {
				...state,
				promotionBanner: { ...state.promotionBanner, loading: true },
			};

		case types.GET_PROMOTION_BANNER_SUCCESS:
			return {
				...state,
				promotionBanner: { data: payLoad, loading: false },
			};

		case types.GET_PROMOTION_BANNER_FAILURE:
			return {
				...state,
				promotionBanner: { data: [], loading: false },
			};

		default:
			return state;
	}
};

export default Coaches;