import * as React from "react";
const Like = (props) => (
	<svg
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g id="Duotone">
			<path
				id="Vector"
				opacity={0.1}
				d="M16.0001 29.3338C23.3334 29.3338 29.3334 23.3338 29.3334 16.0004C29.3334 8.66711 23.3334 2.66711 16.0001 2.66711C8.66675 2.66711 2.66675 8.66711 2.66675 16.0004C2.66675 23.3338 8.66675 29.3338 16.0001 29.3338Z"
				fill="#00DBA2"
			/>
			<g id="Group">
				<path
					id="Vector_2"
					d="M12.4984 15.1395L10.6349 15.0379C10.289 15.0191 9.99326 15.2842 9.9744 15.6301L9.69307 20.7914C9.67422 21.1373 9.93936 21.433 10.2853 21.4519L12.1488 21.5535C12.4947 21.5723 12.7905 21.3072 12.8093 20.9613L13.0906 15.8C13.1095 15.4541 12.8444 15.1584 12.4984 15.1395Z"
					fill="#00DBA2"
				/>
				<path
					id="Vector_3"
					d="M20.8699 16.2069L20.7592 16.2002C21.3045 16.2292 21.775 15.8092 21.8046 15.2618C21.8337 14.7166 21.4136 14.246 20.8663 14.2164L17.6862 14.0435C18.1124 13.4464 18.6989 12.4906 18.7426 11.6854C18.8114 10.4096 18.1529 9.66406 17.7014 9.55915C17.2499 9.45424 16.9031 9.67798 16.7516 10.055C16.6001 10.4321 16.7914 11.174 16.6887 11.4288C16.5284 11.8218 15.7979 12.3214 14.7782 13.4892C13.8697 14.5331 13.92 15.827 13.92 15.827L13.8248 17.5868L13.7296 19.3466L13.6846 20.1652C13.6671 20.5048 13.9283 20.7973 14.2678 20.8149L20.1751 21.1379C20.571 21.1591 20.9121 20.8535 20.9327 20.4598C20.9539 20.0639 20.6484 19.7227 20.2546 19.7021L20.3561 19.7086C20.8401 19.7351 21.2572 19.3603 21.2836 18.8763C21.31 18.3923 20.9353 17.9753 20.4513 17.9488L20.7747 17.9667C21.2587 17.9931 21.6757 17.6184 21.7022 17.1344C21.7286 16.6504 21.3539 16.2333 20.8699 16.2069Z"
					fill="#00DBA2"
				/>
			</g>
		</g>
	</svg>
);
export default Like;
