import { apiCaller } from '../helpers/apiCaller';
import ConvertJsonToQueryString from '../helpers/convertJsonToQueryString';

export const getLectureBySubCourseTopicId = subCourseTopicId => {
	return apiCaller({ needToken: true }).get(
		`${process.env.REACT_APP_API_URL}/api/Lecture/get?${ConvertJsonToQueryString({ subCourseTopicId })}`
	);
};

export const getLectureInfoByLectureId = lectureId => {
	return apiCaller({ needToken: true }).get(
		`${process.env.REACT_APP_API_URL}/api/Lecture/info?${ConvertJsonToQueryString({ lectureId })}`
	);
};

export const createForumPost = data => {
	return apiCaller({ needToken: true }).get(`devblog.tosanalytics.com/yks/api/discussions`, data);
};
