import * as types from '../constants/actionTypes';
import ExamTypeId from '../mobileAssets/enums/ExamTypeId';
import { HomeworkStatus, OrderHomework, SendHomeworkTabs } from '../enums/SPHomeworks';

const initialState = {
	getSchoolPanelFilters: {
		loading: false,
		data: [],
	},
	getSchoolPanelStudents: {
		loading: false,
		data: [],
	},
	getCorporateUserSolved: {
		loading: false,
		data: [],
	},
	handleSchoolPanelFilterIds: {
		loading: false,
		data: [],
	},
	handleSchoolPanelTagIds: {
		loading: false,
		data: [],
	},
	handleSchoolPanelUserIds: {
		loading: false,
		data: [],
	},
	handleCorporateStudyStatistics: {
		loading: false,
		data: [],
	},
	handleStudentTags: {
		loading: false,
		data: [],
	},
	studentsCount: 0,
	getHomeworkType: {
		loading: false,
		data: [],
	},
	createHomeworkPopupActiveTab: 1,
	confirmedSelectedHomeworkType: 0,
	createHomework: {
		loading: false,
		data: [],
	},
	updateHomework: {
		loading: false,
		data: [],
	},
	showCreateHomeworkPopup: false,
	getById: {
		loading: false,
		data: [],
	},
	pdfTestCode: '',
	freeTest: {
		selectedCourse: [{
			CourseTopics: [],
		}],
		selectedCourseId: null,
		selectedTopic: [{
			SubTopics: [],
		}],
		selectedTopicId: null,
		selectedSubCourseTopicId: null,
		selectedCount: 0,
		selectedExamType: ExamTypeId.TYT
	},
	shareFile: {
		files: [],
		filesUrl: [],
		title: '',
		description: '',
	},
	shareDescription: {
		title: '',
		description: '',
	},
	getHomeworks: {
		loading: false,
		data: [],
	},
	deleteHomework: {
		loading: false,
	},
	cloneHomework: {
		loading: false,
		data: 0,
	},
	tracingByIdHomework: {
		loading: false,
		data: [],
	},
	infoByIdHomework: {
		loading: false,
		data: [],
	},
	sendHomeworkNotification: {
		loading: false,
		data: [],
	},
	orderHomework: OrderHomework.None,
	activeHomeworkStatusTab: HomeworkStatus.None,
	showHomeworkInfoPopup: false,
	isEditableHomework: true,
	getNotifTypes: {
		loading: false,
		data: [],
	},
	activeContent: SendHomeworkTabs.SendOption,
	homeworkSendSuccessPopup: false,
	fromHomeworkCard: false,
	selectedStudentUserIds: [],
	selectedDate: null,
	shouldUpdateHomework: false,
	updateHomeworkStatus: false,
	isUnauthorizedTeacher: false,
	showGoStudentProfilePopup:false,
	selectedStudentUserId: 0
};

const SchoolPanel = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_SCHOOL_PANEL_FILTERS:
			return {
				...state,
				getSchoolPanelFilters: { ...state.getSchoolPanelFilters, loading: true },
			};

		case types.GET_SCHOOL_PANEL_FILTERS_SUCCESS:
			return {
				...state,
				getSchoolPanelFilters: { data: payLoad, loading: false },
			};

		case types.GET_SCHOOL_PANEL_FILTERS_FAILURE:
			return {
				...state,
				getSchoolPanelFilters: { data: [], loading: false },
			};

		case types.ADD_SCHOOL_PANEL_FILTER:
			return {
				...state,
				getSchoolPanelFilters: { ...state.getSchoolPanelFilters, loading: true },
			};

		case types.ADD_SCHOOL_PANEL_FILTER_SUCCESS:
			return {
				...state,
				getSchoolPanelFilters: { data: payLoad, loading: false },
			};

		case types.ADD_SCHOOL_PANEL_FILTER_FAILURE:
			return {
				...state,
				getSchoolPanelFilters: { data: [], loading: false },
			};

		case types.DELETE_SCHOOL_PANEL_FILTER:
			return {
				...state,
				deleteTestSection: {
					...state.deleteTestSection,
					loading: true,
				},
			};
		case types.DELETE_SCHOOL_PANEL_FILTER_SUCCESS:
			return {
				...state,
				deleteTestSection: { loading: false },
			};
		case types.DELETE_SCHOOL_PANEL_FILTER_FAILURE:
			return {
				...state,
				deleteTestSection: {
					data: [],
					loading: false,
				},
			};

		case types.ADD_SCHOOL_PANEL_FILTER_TAG:
			return {
				...state,
				getSchoolPanelFilters: { ...state.getSchoolPanelFilters, loading: true },
			};

		case types.ADD_SCHOOL_PANEL_FILTER_TAG_SUCCESS:
			return {
				...state,
				getSchoolPanelFilters: { data: payLoad, loading: false },
			};

		case types.ADD_SCHOOL_PANEL_FILTER_TAG_FAILURE:
			return {
				...state,
				getSchoolPanelFilters: { data: [], loading: false },
			};

		case types.EDIT_SCHOOL_PANEL_FILTER:
			return {
				...state,
				getSchoolPanelFilters: { ...state.getSchoolPanelFilters, loading: true },
			};

		case types.EDIT_SCHOOL_PANEL_FILTER_SUCCESS:
			return {
				...state,
				getSchoolPanelFilters: { data: payLoad, loading: false },
			};

		case types.EDIT_SCHOOL_PANEL_FILTER_FAILURE:
			return {
				...state,
				getSchoolPanelFilters: { data: [], loading: false },
			};

		case types.EDIT_SCHOOL_PANEL_FILTER_TAG:
			return {
				...state,
				getSchoolPanelFilters: { ...state.getSchoolPanelFilters, loading: true },
			};

		case types.EDIT_SCHOOL_PANEL_FILTER_TAG_SUCCESS:
			return {
				...state,
				getSchoolPanelFilters: { data: payLoad, loading: false },
			};

		case types.EDIT_SCHOOL_PANEL_FILTER_TAG_FAILURE:
			return {
				...state,
				getSchoolPanelFilters: { data: [], loading: false },
			};

		case types.GET_SCHOOL_PANEL_STUDENTS:
			return {
				...state,
				getSchoolPanelStudents: { ...state.getSchoolPanelStudents, loading: true },
			};

		case types.GET_SCHOOL_PANEL_STUDENTS_SUCCESS:
			return {
				...state,
				getSchoolPanelStudents: { data: payLoad, loading: false },
			};

		case types.GET_SCHOOL_PANEL_STUDENTS_FAILURE:
			return {
				...state,
				getSchoolPanelStudents: { data: [], loading: false },
			};

		case types.ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG:
			return {
				...state,
				handleStudentTags: { ...state.handleStudentTags, loading: true },
			};

		case types.ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG_SUCCESS:
			return {
				...state,
				handleStudentTags: { data: payLoad, loading: false },
			};

		case types.ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG_FAILURE:
			return {
				...state,
				handleStudentTags: { data: [], loading: false },
			};

		case types.GET_CORPORATE_USER_SOLVED:
			return {
				...state,
				getCorporateUserSolved: { ...state.getCorporateUserSolved, loading: true },
			};

		case types.GET_CORPORATE_USER_SOLVED_SUCCESS:
			return {
				...state,
				getCorporateUserSolved: { data: payLoad, loading: false },
			};

		case types.GET_CORPORATE_USER_SOLVED_FAILURE:
			return {
				...state,
				getCorporateUserSolved: { data: [], loading: false },
			};

		case types.HANDLE_SCHOOL_PANEL_FILTER_IDS:
			return {
				...state,
				handleSchoolPanelFilterIds: { data: payLoad, loading: false },
			};

		case types.HANDLE_SCHOOL_PANEL_FILTER_IDS_SUCCESS:
			return {
				...state,
				handleSchoolPanelFilterIds: { data: payLoad, loading: false },
			};

		case types.HANDLE_SCHOOL_PANEL_FILTER_IDS_FAILURE:
			return {
				...state,
				handleSchoolPanelFilterIds: { data: [], loading: false },
			};

		case types.HANDLE_SCHOOL_PANEL_TAG_IDS:
			return {
				...state,
				handleSchoolPanelTagIds: { data: payLoad, loading: false },
			};

		case types.HANDLE_SCHOOL_PANEL_TAG_IDS_SUCCESS:
			return {
				...state,
				handleSchoolPanelTagIds: { data: payLoad, loading: false },
			};

		case types.HANDLE_SCHOOL_PANEL_TAG_IDS_FAILURE:
			return {
				...state,
				handleSchoolPanelTagIds: { data: [], loading: false },
			};

		case types.HANDLE_SCHOOL_PANEL_USER_IDS:
			return {
				...state,
				handleSchoolPanelUserIds: { data: payLoad, loading: false },
			};

		case types.HANDLE_SCHOOL_PANEL_USER_IDS_SUCCESS:
			return {
				...state,
				handleSchoolPanelUserIds: { data: payLoad, loading: false },
			};

		case types.HANDLE_SCHOOL_PANEL_USER_IDS_FAILURE:
			return {
				...state,
				handleSchoolPanelUserIds: { data: [], loading: false },
			};

		case types.GET_CORPORATE_STUDY_STATISTICS:
			return {
				...state,
				handleCorporateStudyStatistics: { ...state.handleCorporateStudyStatistics, loading: true },
			};

		case types.GET_CORPORATE_STUDY_STATISTIC_SUCCESS:
			return {
				...state,
				handleCorporateStudyStatistics: { data: payLoad, loading: false },
			};

		case types.GET_CORPORATE_STUDY_STATISTIC_FAILURE:
			return {
				...state,
				handleCorporateStudyStatistics: { data: [], loading: false },
			};

		case types.HANDLE_STUDENTS_COUNT:
			return {
				...state,
				studentsCount: payLoad,
			};

		case types.GET_HOMEWORK_TYPE:
			return {
				...state,
				getHomeworkType: { ...state.getHomeworkType, loading: true },
			};
		case types.GET_HOMEWORK_TYPE_SUCCESS:
			return {
				...state,
				getHomeworkType: { data: payLoad, loading: false },
			};
		case types.GET_HOMEWORK_TYPE_FAILURE:
			return {
				...state,
				getHomeworkType: { data: [], loading: false },
			};
		case types.SET_SHOW_CREATE_HOMEWORK_POPUP:
			return {
				...state,
				showCreateHomeworkPopup: payLoad,
			};
		case types.SET_CREATE_HOMEWORK_POPUP_ACTIVE_TAB:
			return {
				...state,
				createHomeworkPopupActiveTab: payLoad,
			};
		case types.SET_CONFIRMED_SELECTED_HOMEWORK_TYPE:
			return {
				...state,
				confirmedSelectedHomeworkType: payLoad,
			};
		case types.CREATE_HOMEWORK:
			return {
				...state,
				createHomework: { ...state.createHomework, loading: true },
			};
		case types.CREATE_HOMEWORK_SUCCESS:
			return {
				...state,
				createHomework: { data: payLoad, loading: false },
			};
		case types.CREATE_HOMEWORK_FAILURE:
			return {
				...state,
				createHomework: { data: [], loading: false },
			};
		case types.UPDATE_HOMEWORK:
			return {
				...state,
				updateHomework: { ...state.updateHomework, loading: true },
			};
		case types.UPDATE_HOMEWORK_SUCCESS:
			return {
				...state,
				updateHomework: { data: payLoad, loading: false },
			};
		case types.UPDATE_HOMEWORK_FAILURE:
			return {
				...state,
				updateHomework: { data: [], loading: false },
			};
		case types.GET_BY_ID:
			return {
				...state,
				getById: { ...state.getById, loading: true },
			};
		case types.GET_BY_ID_SUCCESS:
			return {
				...state,
				getById: { data: payLoad, loading: false },
			};
		case types.GET_BY_ID_FAILURE:
			return {
				...state,
				getById: { data: [], loading: false },
			};
		case types.SET_PDF_TEST_CODE:
			return {
				...state,
				pdfTestCode: payLoad,
			};
		case types.SET_FREE_TEST_SELECTED_COURSE:
			return {
				...state,
				freeTest: { ...state.freeTest, selectedCourse: payLoad },
			};
		case types.SET_FREE_TEST_SELECTED_COURSE_ID:
			return {
				...state,
				freeTest: { ...state.freeTest, selectedCourseId: payLoad },
			};
		case types.SET_FREE_TEST_SELECTED_TOPIC:
			return {
				...state,
				freeTest: { ...state.freeTest, selectedTopic: payLoad },
			};
		case types.SET_FREE_TEST_SELECTED_TOPIC_ID:
			return {
				...state,
				freeTest: { ...state.freeTest, selectedTopicId: payLoad },
			};
		case types.SET_FREE_TEST_SELECTED_SUB_COURSE_TOPIC_ID:
			return {
				...state,
				freeTest: { ...state.freeTest, selectedSubCourseTopicId: payLoad },
			};
		case types.SET_FREE_TEST_SELECTED_COUNT:
			return {
				...state,
				freeTest: { ...state.freeTest, selectedCount: payLoad },
			};
		case types.SET_FREE_TEST_SELECTED_EXAM_TYPE:
			return {
				...state,
				freeTest: { ...state.freeTest, selectedExamType: payLoad },
			};
		case types.SET_SHARE_FILE_FILES:
			return {
				...state,
				shareFile: { ...state.shareFile, files: payLoad },
			};
		case types.SET_SHARE_FILE_FILES_URL:
			return {
				...state,
				shareFile: { ...state.shareFile, filesUrl: payLoad },
			};
		case types.SET_SHARE_FILE_TITLE:
			return {
				...state,
				shareFile: { ...state.shareFile, title: payLoad },
			};
		case types.SET_SHARE_FILE_DESCRIPTION:
			return {
				...state,
				shareFile: { ...state.shareFile, description: payLoad },
			};
		case types.SET_SHARE_DESCRIPTION_TITLE:
			return {
				...state,
				shareDescription: { ...state.shareDescription, title: payLoad },
			};
		case types.SET_SHARE_DESCRIPTION_DESCRIPTION:
			return {
				...state,
				shareDescription: { ...state.shareDescription, description: payLoad },
			};
		case types.CLEAR_ALL_CREATE_HOMEWORK_POPUP_DATA:
			return {
				...state,
				getHomeworkType: {
					loading: false,
					data: [],
				},
				createHomeworkPopupActiveTab: 1,
				confirmedSelectedHomeworkType: 0,
				createHomework: {
					loading: false,
					data: [],
				},
				updateHomework: {
					loading: false,
					data: [],
				},
				showCreateHomeworkPopup: false,
				getById: {
					loading: false,
					data: [],
				},
				pdfTestCode: '',
				freeTest: {
					selectedCourse: [{
						CourseTopics: [],
					}],
					selectedCourseId: null,
					selectedTopic: [{
						SubTopics: [],
					}],
					selectedTopicId: null,
					selectedSubCourseTopicId: null,
					selectedCount: 0,
					selectedExamType: ExamTypeId.TYT
				},
				shareFile: {
					files: [],
					filesUrl: [],
					title: '',
					description: '',
				},
				shareDescription: {
					title: '',
					description: '',
				},
				isEditableHomework: true,
				activeContent: SendHomeworkTabs.SendOption,
				fromHomeworkCard: false,
				selectedDate: null,
				shouldUpdateHomework: false,
				selectedStudentUserIds: [],
				cloneHomework: {
					data: 0,
				},

			};
		case types.GET_HOMEWORKS:
			return {
				...state,
				getHomeworks: { ...state.getHomeworks, loading: true },
			};
		case types.GET_HOMEWORKS_SUCCESS:
			return {
				...state,
				getHomeworks: { data: payLoad, loading: false },
			};
		case types.GET_HOMEWORKS_FAILURE:
			return {
				...state,
				getHomeworks: { data: [], loading: false },
			};
		case types.DELETE_HOMEWORK:
			return {
				...state,
				deleteHomework: { loading: true },
				getHomeworks: { ...state.getHomeworks, loading: true },
			};
		case types.DELETE_HOMEWORK_SUCCESS:
			return {
				...state,
				deleteHomework: { loading: false },
				getHomeworks: { ...state.getHomeworks, loading: false },
			};
		case types.DELETE_HOMEWORK_FAILURE:
			return {
				...state,
				deleteHomework: { loading: false },
				getHomeworks: { ...state.getHomeworks, loading: false },
			};
		case types.CLONE_HOMEWORK:
			return {
				...state,
				cloneHomework: { ...state.cloneHomework, loading: true },
				getHomeworks: { ...state.getHomeworks, loading: true },
			};
		case types.CLONE_HOMEWORK_SUCCESS:
			return {
				...state,
				cloneHomework: { data: payLoad, loading: false },
				getHomeworks: { ...state.getHomeworks, loading: false },
			};
		case types.CLONE_HOMEWORK_FAILURE:
			return {
				...state,
				cloneHomework: { data: 0, loading: false },
				getHomeworks: { ...state.getHomeworks, loading: false },
			};
		case types.TRACING_BY_ID_HOMEWORK:
			return {
				...state,
				tracingByIdHomework: { ...state.tracingByIdHomework, loading: true },
			};
		case types.TRACING_BY_ID_HOMEWORK_SUCCESS:
			return {
				...state,
				tracingByIdHomework: { data: payLoad, loading: false },
			};
		case types.TRACING_BY_ID_HOMEWORK_FAILURE:
			return {
				...state,
				tracingByIdHomework: { data: [], loading: false },
			};
		case types.INFO_BY_ID_HOMEWORK:
			return {
				...state,
				infoByIdHomework: { ...state.infoByIdHomework, loading: true },
			};
		case types.INFO_BY_ID_HOMEWORK_SUCCESS:
			return {
				...state,
				infoByIdHomework: { data: payLoad, loading: false },
			};
		case types.INFO_BY_ID_HOMEWORK_FAILURE:
			return {
				...state,
				infoByIdHomework: { data: [], loading: false },
			};
		case types.SEND_HOMEWORK_NOTIFICATION:
			return {
				...state,
				sendHomeworkNotification: { ...state.sendHomeworkNotification, loading: true },
			};
		case types.SEND_HOMEWORK_NOTIFICATION_SUCCESS:
			return {
				...state,
				sendHomeworkNotification: { data: payLoad, loading: false },
			};
		case types.SEND_HOMEWORK_NOTIFICATION_FAILURE:
			return {
				...state,
				sendHomeworkNotification: {
					data: [], loading: false,
				},
			};
		case types.ORDER_HOMEWORK:
			return {
				...state,
				orderHomework: payLoad,
			};
		case types.SET_ACTIVE_HOMEWORK_STATUS_TAB:
			return {
				...state,
				activeHomeworkStatusTab: payLoad,
			};
		case types.SET_SHOW_HOMEWORK_INFO_POPUP:
			return {
				...state,
				showHomeworkInfoPopup: payLoad,
			};
		case types.SET_IS_EDITABLE_HOMEWORK:
			return {
				...state,
				isEditableHomework: payLoad,
			};
		case types.GET_NOTIF_TYPES:
			return {
				...state,
				getNotifTypes: { ...state.getNotifTypes, loading: true },
			};
		case types.GET_NOTIF_TYPES_SUCCESS:
			return {
				...state,
				getNotifTypes: { data: payLoad, loading: false },
			};
		case types.GET_NOTIF_TYPES_FAILURE:
			return {
				...state,
				getNotifTypes: { data: [], loading: false },
			};
		case types.SET_ACTIVE_CONTENT:
			return {
				...state,
				activeContent: payLoad,
			};
		case types.HOMEWORK_SEND_SUCCESS_POPUP:
			return {
				...state,
				homeworkSendSuccessPopup: payLoad,
			};
		case types.SET_FROM_HOMEWORK_CARD:
			return {
				...state,
				fromHomeworkCard: payLoad,
			};
		case types.SET_SELECTED_STUDENT_USER_IDS:
			return {
				...state,
				selectedStudentUserIds: payLoad,
			};
		case types.SET_SELECTED_DATE:
			return {
				...state,
				selectedDate: payLoad,
			};
		case types.SET_SHOULD_UPDATE_HOMEWORK:
			return {
				...state,
				shouldUpdateHomework: payLoad,
			};
		case types.UPDATE_HOMEWORK_STATUS:
			return {
				...state,
				updateHomeworkStatus: payLoad,
			};
		case types.UPDATE_HOMEWORK_STATUS_SUCCESS:
			return {
				...state,
				updateHomeworkStatus: { data: payLoad, loading: false },
			};
		case types.UPDATE_HOMEWORK_STATUS_FAILURE:
			return {
				...state,
				updateHomeworkStatus: { data: [], loading: false },
			};
		case types.IS_UNAUTHORIZED_TEACHER:
			return {
				...state,
				isUnauthorizedTeacher: payLoad,
			};
		case types.SET_SHOW_GO_STUDENT_PROFILE_POP_UP:
			return {
				...state,
				showGoStudentProfilePopup: payLoad,
			};
		case types.SET_SELECTED_STUDENT_USER_ID:
			return {
				...state,
				selectedStudentUserId: payLoad,
			};
		default:
			return state;
	}
};

export default SchoolPanel;
