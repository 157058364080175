import * as types from '../constants/actionTypes';
import { SET_SHOW_GO_STUDENT_PROFILE_POP_UP } from '../constants/actionTypes';

export const getSchoolPanelFilters = payLoad => {
	return {
		type: types.GET_SCHOOL_PANEL_FILTERS,
		payLoad,
	};
};

export const getSchoolPanelFiltersSuccess = data => {
	return {
		type: types.GET_SCHOOL_PANEL_FILTERS_SUCCESS,
		payLoad: data,
	};
};

export const getSchoolPanelFiltersFailure = () => {
	return {
		type: types.GET_SCHOOL_PANEL_FILTERS_FAILURE,
	};
};

export const addSchoolPanelFilter = payLoad => {
	return {
		type: types.ADD_SCHOOL_PANEL_FILTER,
		payLoad,
	};
};

export const addSchoolPanelFilterSuccess = data => {
	return {
		type: types.ADD_SCHOOL_PANEL_FILTER_SUCCESS,
		payLoad: data,
	};
};

export const addSchoolPanelFilterFailure = () => {
	return {
		type: types.ADD_SCHOOL_PANEL_FILTER_FAILURE,
	};
};

export const deleteSchoolPanelFilter = data => {
	return {
		type: types.DELETE_SCHOOL_PANEL_FILTER,
		data,
	};
};
export const deleteSchoolPanelFilterSuccess = data => {
	return {
		type: types.DELETE_SCHOOL_PANEL_FILTER_SUCCESS,
		payLoad: data,
	};
};
export const deleteSchoolPanelFilterFailure = () => {
	return {
		type: types.DELETE_SCHOOL_PANEL_FILTER_FAILURE,
	};
};

export const addSchoolPanelFilterTag = payLoad => {
	return {
		type: types.ADD_SCHOOL_PANEL_FILTER_TAG,
		payLoad,
	};
};

export const addSchoolPanelFilterTagSuccess = data => {
	return {
		type: types.ADD_SCHOOL_PANEL_FILTER_TAG_SUCCESS,
		payLoad: data,
	};
};

export const addSchoolPanelFilterTagFailure = () => {
	return {
		type: types.ADD_SCHOOL_PANEL_FILTER_TAG_FAILURE,
	};
};

export const deleteSchoolPanelFilterTag = data => {
	return {
		type: types.DELETE_SCHOOL_PANEL_FILTER_TAG,
		data,
	};
};
export const deleteSchoolPanelFilterTagSuccess = data => {
	return {
		type: types.DELETE_SCHOOL_PANEL_FILTER_TAG_SUCCESS,
		payLoad: data,
	};
};
export const deleteSchoolPanelFilterTagFailure = () => {
	return {
		type: types.DELETE_SCHOOL_PANEL_FILTER_TAG_FAILURE,
	};
};

export const editSchoolPanelFilter = payLoad => {
	return {
		type: types.EDIT_SCHOOL_PANEL_FILTER,
		payLoad,
	};
};

export const editSchoolPanelFilterSuccess = data => {
	return {
		type: types.EDIT_SCHOOL_PANEL_FILTER_SUCCESS,
		payLoad: data,
	};
};

export const editSchoolPanelFilterFailure = () => {
	return {
		type: types.EDIT_SCHOOL_PANEL_FILTER_FAILURE,
	};
};

export const editSchoolPanelFilterTag = payLoad => {
	return {
		type: types.EDIT_SCHOOL_PANEL_FILTER_TAG,
		payLoad,
	};
};

export const editSchoolPanelFilterTagSuccess = data => {
	return {
		type: types.EDIT_SCHOOL_PANEL_FILTER_TAG_SUCCESS,
		payLoad: data,
	};
};

export const editSchoolPanelFilterTagFailure = () => {
	return {
		type: types.EDIT_SCHOOL_PANEL_FILTER_TAG_FAILURE,
	};
};

export const getSchoolPanelStudents = payLoad => {
	return {
		type: types.GET_SCHOOL_PANEL_STUDENTS,
		payLoad,
	};
};

export const getSchoolPanelStudentsSuccess = data => {
	return {
		type: types.GET_SCHOOL_PANEL_STUDENTS_SUCCESS,
		payLoad: data,
	};
};

export const getSchoolPanelStudentsFailure = () => {
	return {
		type: types.GET_SCHOOL_PANEL_STUDENTS_FAILURE,
	};
};

export const addOrUpdateSchoolPanelStudentTag = payLoad => {
	return {
		type: types.ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG,
		payLoad,
	};
};

export const addOrUpdateSchoolPanelStudentTagSuccess = data => {
	return {
		type: types.ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG_SUCCESS,
		payLoad: data,
	};
};

export const addOrUpdateSchoolPanelStudentTagFailure = () => {
	return {
		type: types.ADD_OR_UPDATE_SCHOOL_PANEL_STUDENT_TAG_FAILURE,
	};
};

export const deleteSchoolPanelStudentTag = payLoad => {
	return {
		type: types.DELETE_SCHOOL_PANEL_STUDENT_TAG,
		payLoad,
	};
};
export const deleteSchoolPanelStudentTagSuccess = data => {
	return {
		type: types.DELETE_SCHOOL_PANEL_STUDENT_TAG_SUCCESS,
		payLoad: data,
	};
};
export const deleteSchoolPanelStudentTagFailure = () => {
	return {
		type: types.DELETE_SCHOOL_PANEL_STUDENT_TAG_FAILURE,
	};
};

export const getCorporateUserSolved = payLoad => {
	return {
		type: types.GET_CORPORATE_USER_SOLVED,
		payLoad,
	};
};

export const getCorporateUserSolvedSuccess = data => {
	return {
		type: types.GET_CORPORATE_USER_SOLVED_SUCCESS,
		payLoad: data,
	};
};

export const getCorporateUserSolvedFailure = () => {
	return {
		type: types.GET_CORPORATE_USER_SOLVED_FAILURE,
	};
};

export const handleSchoolPanelFilterIds = payLoad => {
	return {
		type: types.HANDLE_SCHOOL_PANEL_FILTER_IDS,
		payLoad,
	};
};

export const handleSchoolPanelFilterIdsSuccess = data => {
	return {
		type: types.HANDLE_SCHOOL_PANEL_FILTER_IDS_SUCCESS,
		payLoad: data,
	};
};

export const handleSchoolPanelFilterIdsFailure = () => {
	return {
		type: types.HANDLE_SCHOOL_PANEL_FILTER_IDS_FAILURE,
	};
};

export const handleSchoolPanelTagIds = payLoad => {
	return {
		type: types.HANDLE_SCHOOL_PANEL_TAG_IDS,
		payLoad,
	};
};

export const handleSchoolPanelTagIdsSuccess = data => {
	return {
		type: types.HANDLE_SCHOOL_PANEL_TAG_IDS_SUCCESS,
		payLoad: data,
	};
};

export const handleSchoolPanelTagIdsFailure = () => {
	return {
		type: types.HANDLE_SCHOOL_PANEL_TAG_IDS_FAILURE,
	};
};

export const handleSchoolPanelUserIds = payLoad => {
	return {
		type: types.HANDLE_SCHOOL_PANEL_USER_IDS,
		payLoad,
	};
};

export const handleSchoolPanelUserIdsSuccess = data => {
	return {
		type: types.HANDLE_SCHOOL_PANEL_USER_IDS_SUCCESS,
		payLoad: data,
	};
};

export const handleSchoolPanelUserIdsFailure = () => {
	return {
		type: types.HANDLE_SCHOOL_PANEL_USER_IDS_FAILURE,
	};
};

export const handleCorporateStudyStatistics = payLoad => {
	return {
		type: types.GET_CORPORATE_STUDY_STATISTICS,
		payLoad,
	};
};

export const handleCorporateStudyStatisticsSuccess = data => {
	return {
		type: types.GET_CORPORATE_STUDY_STATISTIC_SUCCESS,
		payLoad: data,
	};
};

export const handleCorporateStudyStatisticsFailure = () => {
	return {
		type: types.GET_CORPORATE_STUDY_STATISTIC_FAILURE,
	};
};

export const handleStudentsCount = payLoad => {
	return {
		type: types.HANDLE_STUDENTS_COUNT,
		payLoad,
	};
};

export const getHomeworkType = () => ({
	type: types.GET_HOMEWORK_TYPE,
});

export const getHomeworkTypeSuccess = payLoad => ({
	type: types.GET_HOMEWORK_TYPE_SUCCESS,
	payLoad,
});

export const getHomeworkTypeFailure = () => ({
	type: types.GET_HOMEWORK_TYPE_FAILURE,
});

export const setShowCreateHomeworkPopup = payLoad => ({
	type: types.SET_SHOW_CREATE_HOMEWORK_POPUP,
	payLoad,
});

export const setCreateHomeworkPopupActiveTab = payLoad => ({
	type: types.SET_CREATE_HOMEWORK_POPUP_ACTIVE_TAB,
	payLoad,
});

export const setConfirmedSelectedHomeworkType = payLoad => ({
	type: types.SET_CONFIRMED_SELECTED_HOMEWORK_TYPE,
	payLoad,
});

export const createHomework = payLoad => ({
	type: types.CREATE_HOMEWORK,
	payLoad,
});

export const createHomeworkSuccess = payLoad => ({
	type: types.CREATE_HOMEWORK_SUCCESS,
	payLoad,
});

export const createHomeworkFailure = () => ({
	type: types.CREATE_HOMEWORK_FAILURE,
});

export const updateHomework = payLoad => ({
	type: types.UPDATE_HOMEWORK,
	payLoad,
});

export const updateHomeworkSuccess = payLoad => ({
	type: types.UPDATE_HOMEWORK_SUCCESS,
	payLoad,
});

export const updateHomeworkFailure = () => ({
	type: types.UPDATE_HOMEWORK_FAILURE,
});

export const getById = payLoad => ({
	type: types.GET_BY_ID,
	payLoad,
});

export const getByIdSuccess = payLoad => ({
	type: types.GET_BY_ID_SUCCESS,
	payLoad,
});

export const getByIdFailure = () => ({
	type: types.GET_BY_ID_FAILURE,
});

export const setPdfTestCode = payLoad => ({
	type: types.SET_PDF_TEST_CODE,
	payLoad,
});

export const setFreeTestSelectedCourse = payLoad => ({
	type: types.SET_FREE_TEST_SELECTED_COURSE,
	payLoad,
});

export const setFreeTestSelectedCourseId = payLoad => ({
	type: types.SET_FREE_TEST_SELECTED_COURSE_ID,
	payLoad,
});

export const setFreeTestSelectedTopic = payLoad => ({
	type: types.SET_FREE_TEST_SELECTED_TOPIC,
	payLoad,
});

export const setFreeTestSelectedTopicId = payLoad => ({
	type: types.SET_FREE_TEST_SELECTED_TOPIC_ID,
	payLoad,
});

export const setFreeTestSelectedSubCourseTopicId = payLoad => ({
	type: types.SET_FREE_TEST_SELECTED_SUB_COURSE_TOPIC_ID,
	payLoad,
});

export const setFreeTestSelectedCount = payLoad => ({
	type: types.SET_FREE_TEST_SELECTED_COUNT,
	payLoad,
});

export const setFreeTestSelectedExamType = payLoad => ({
	type: types.SET_FREE_TEST_SELECTED_EXAM_TYPE,
	payLoad,
});

export const setShareFileFiles = payLoad => ({
	type: types.SET_SHARE_FILE_FILES,
	payLoad,
});

export const setShareFileFilesUrl = payLoad => ({
	type: types.SET_SHARE_FILE_FILES_URL,
	payLoad,
});

export const setShareFileTitle = payLoad => ({
	type: types.SET_SHARE_FILE_TITLE,
	payLoad,
});

export const setShareFileDescription = payLoad => ({
	type: types.SET_SHARE_FILE_DESCRIPTION,
	payLoad,
});

export const setShareDescriptionTitle = payLoad => ({
	type: types.SET_SHARE_DESCRIPTION_TITLE,
	payLoad,
});

export const setShareDescriptionDescription = payLoad => ({
	type: types.SET_SHARE_DESCRIPTION_DESCRIPTION,
	payLoad,
});

export const clearAllCreateHomeworkPopupData = () => ({
	type: types.CLEAR_ALL_CREATE_HOMEWORK_POPUP_DATA,
});

export const getHomeworks = () => ({
	type: types.GET_HOMEWORKS,
});

export const getHomeworksSuccess = payLoad => ({
	type: types.GET_HOMEWORKS_SUCCESS,
	payLoad,
});

export const getHomeworksFailure = () => ({
	type: types.GET_HOMEWORKS_FAILURE,
});

export const deleteHomework = payLoad => ({
	type: types.DELETE_HOMEWORK,
	payLoad,
});

export const deleteHomeworkSuccess = payLoad => ({
	type: types.DELETE_HOMEWORK_SUCCESS,
	payLoad,
});

export const deleteHomeworkFailure = () => ({
	type: types.DELETE_HOMEWORK_FAILURE,
});

export const cloneHomework = payLoad => ({
	type: types.CLONE_HOMEWORK,
	payLoad,
});

export const cloneHomeworkSuccess = payLoad => ({
	type: types.CLONE_HOMEWORK_SUCCESS,
	payLoad,
});

export const cloneHomeworkFailure = () => ({
	type: types.CLONE_HOMEWORK_FAILURE,
});

export const tracingByIdHomework = payLoad => ({
	type: types.TRACING_BY_ID_HOMEWORK,
	payLoad,
});

export const tracingByIdHomeworkSuccess = payLoad => ({
	type: types.TRACING_BY_ID_HOMEWORK_SUCCESS,
	payLoad,
});

export const tracingByIdHomeworkFailure = () => ({
	type: types.TRACING_BY_ID_HOMEWORK_FAILURE,
});

export const infoByIdHomework = payLoad => ({
	type: types.INFO_BY_ID_HOMEWORK,
	payLoad,
});

export const infoByIdHomeworkSuccess = payLoad => ({
	type: types.INFO_BY_ID_HOMEWORK_SUCCESS,
	payLoad,
});

export const infoByIdHomeworkFailure = () => ({
	type: types.INFO_BY_ID_HOMEWORK_FAILURE,
});

export const sendHomeworkNotification = payLoad => ({
	type: types.SEND_HOMEWORK_NOTIFICATION,
	payLoad,
});

export const sendHomeworkNotificationSuccess = payLoad => ({
	type: types.SEND_HOMEWORK_NOTIFICATION_SUCCESS,
	payLoad,
});

export const sendHomeworkNotificationFailure = () => ({
	type: types.SEND_HOMEWORK_NOTIFICATION_FAILURE,
});

export const orderHomework = payLoad => ({
	type: types.ORDER_HOMEWORK,
	payLoad,
});

export const setActiveHomeworkStatusTab = payLoad => ({
	type: types.SET_ACTIVE_HOMEWORK_STATUS_TAB,
	payLoad,
});

export const setShowHomeworkInfoPopup = payLoad => {
	return {
		type: types.SET_SHOW_HOMEWORK_INFO_POPUP,
		payLoad,
	};
};

export const setIsEditableHomework = payLoad => ({
	type: types.SET_IS_EDITABLE_HOMEWORK,
	payLoad,
});

export const getNotifTypes = () => ({
	type: types.GET_NOTIF_TYPES,
});

export const getNotifTypesSuccess = payLoad => ({
	type: types.GET_NOTIF_TYPES_SUCCESS,
	payLoad,
});

export const getNotifTypesFailure = () => ({
	type: types.GET_NOTIF_TYPES_FAILURE,
});

export const setActiveContent = payLoad => ({
	type: types.SET_ACTIVE_CONTENT,
	payLoad,
});

export const homeworkSendSuccessPopup = payLoad => ({
	type: types.HOMEWORK_SEND_SUCCESS_POPUP,
	payLoad,
});

export const setFromHomeworkCard = payLoad => ({
	type: types.SET_FROM_HOMEWORK_CARD,
	payLoad,
});

export const setSelectedDate = payLoad => ({
	type: types.SET_SELECTED_DATE,
	payLoad,
});

export const setSelectedStudentUserIds = payLoad => ({
	type: types.SET_SELECTED_STUDENT_USER_IDS,
	payLoad,
});

export const setShouldUpdateHomework = payLoad => ({
	type: types.SET_SHOULD_UPDATE_HOMEWORK,
	payLoad,
});

export const updateHomeworkStatus = payLoad => ({
	type: types.UPDATE_HOMEWORK_STATUS,
	payLoad,
});

export const updateHomeworkStatusSuccess = payLoad => ({
	type: types.UPDATE_HOMEWORK_STATUS_SUCCESS,
	payLoad,
});

export const updateHomeworkStatusFailure = () => ({
	type: types.UPDATE_HOMEWORK_STATUS_FAILURE,
});

export const setIsUnAuthorizedTeacher = payLoad => ({
	type: types.IS_UNAUTHORIZED_TEACHER,
	payLoad,
});

export const setShowGoStudentProfilePopup = payLoad => ({
	type: types.SET_SHOW_GO_STUDENT_PROFILE_POP_UP,
	payLoad
})

export const setSelectedStudentUserId	=	payLoad	=>	({
	type: types.SET_SELECTED_STUDENT_USER_ID,
	payLoad
})