import * as types from '../constants/actionTypes';

export const login = data => {
	return {
		type: types.LOGIN_REQUEST,
		data: data,
	};
};
export const loginSuccess = () => {
	return {
		type: types.LOGIN_SUCCESS,
	};
};
export const loginFailure = () => {
	return {
		type: types.LOGIN_FAILURE,
	};
};

export const register = data => {
	return {
		type: types.REGISTER_REQUEST,
		data: data,
	};
};
export const registerSuccess = data => {
	return {
		type: types.REGISTER_SUCCESS,
		payLoad: data,
	};
};
export const registerFailure = () => {
	return {
		type: types.REGISTER_FAILURE,
	};
};

export const getVerifyCode = () => {
	return {
		type: types.GET_VERIFY_CODE_REQUEST,
	};
};

export const getVerifyCodeSuccess = data => {
	return {
		type: types.GET_VERIFY_CODE_SUCCESS,
		payload: data,
	};
};

export const getVerifyCodeFailure = () => {
	return {
		type: types.GET_VERIFY_CODE_FAILURE,
	};
};

export const updateEmail = email => {
	return {
		type: types.UPDATE_EMAIL_REQUEST,
		email,
	};
};

export const updateEmailSuccess = data => {
	return {
		type: types.UPDATE_EMAIL_SUCCESS,
		payload: data,
	};
};

export const updateEmailFailure = () => {
	return {
		type: types.UPDATE_EMAIL_FAILURE,
	};
};

export const setUserLottery = data => {
	return {
		type: types.SET_LOTTERY,
		payLoad: data,
	};
};

export const setUserCoupon = data => {
	return {
		type: types.SET_COUPON,
		payLoad: data,
	};
};

export const setUserActiveCredits = data => {
	return {
		type: types.SET_ACTIVE_CREDITS,
		payLoad: data,
	};
};
