import { apiCaller } from '../helpers/apiCaller';

const homeworkUrl = `${process.env.REACT_APP_API_URL}/api/homework/`;
const notificationUrl = `${process.env.REACT_APP_API_URL}/api/notification/`;

export const schoolPanelFilters = ()=>{
    return apiCaller({ needToken: true }, { isTeacher: true }).get(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/filters`);
}

export const addSchoolPanelFilter = (name) =>{
    return apiCaller({needToken: true} , { isTeacher: true }).put(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/filter/`+name,)
}

export const editSchoolPanelFilter = (filterId,filterName)=>{
    return apiCaller({needToken:true}, { isTeacher: true }).put(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/filter/${filterId}/${filterName}`)
}

export const addSchoolPanelFilterTag = (id,tagName)=>{
    return apiCaller({needToken:true}, { isTeacher: true }).put(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/tag/${id}/${tagName}`)
}

export const deleteSchoolPanelFilter = (id) =>{
    return apiCaller({needToken: true}, { isTeacher: true }).delete(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/filter/${id}`)
}

export const deleteSchoolPanelFilterTag = (id)=>{
    return apiCaller({needToken:true}, { isTeacher: true }).delete(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/tag/${id}`)
}

export const schoolPanelStudents = ()=>{
    return apiCaller({ needToken: true }, { isTeacher: true }).get(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/getusers`);
}

export const getUsersWithTags =  () =>{
    return apiCaller({needToken:true}, { isTeacher: true }).get(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/getuserswithtag`)
}

export const getCorporateUserSolved = (lessonAndUserInfo) =>{
    return apiCaller({needToken:true}, { isTeacher: true }).post(`${process.env.REACT_APP_API_URL}/api/UserStatistic/getCorporateUserSolved`,lessonAndUserInfo)
}

export const getCorporateStudyStatistics = (userStatistics) =>{
    return apiCaller({needToken:true}, { isTeacher: true }).post(`${process.env.REACT_APP_API_URL}/api/UserStatistic/getCorporateStudyStatistics`,userStatistics)
}

export const addOrUpdateStudentTag = (UserId, TagId, FilterId) =>{
    return apiCaller({needToken:true}, { isTeacher: true }).post(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/updatestudenttag`, {UserId, TagId, FilterId})
}

export const updateFilters = (data) =>{
    return apiCaller({needToken:true}, { isTeacher: true }).post(`${process.env.REACT_APP_API_URL}/api/Schoolpanel/updatefilter`,data)
}

export const deleteUserTag = (UserId, TagId, FilterId) =>{
    return apiCaller({needToken:true}, { isTeacher: true }).post(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/deletestudenttag`, {UserId, TagId, FilterId})
}

export const updateFilter = (FilterId, FilterName) =>{
    return apiCaller({needToken:true}, { isTeacher: true }).put(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/updatefilter/${FilterId}/${FilterName}`)
}

export const updateFilterTag = (TagId, TagName) =>{
    return apiCaller({needToken:true}, { isTeacher: true }).put(`${process.env.REACT_APP_API_URL}/api/SchoolPanel/updatefiltertag/${TagId}/${TagName}`)
}

export const getHomeworkType = () => {
	return apiCaller({ needToken: true }, { isTeacher: true }).get(`${homeworkUrl}gethomeworktype`);
};

export const createHomework = data => {
	return apiCaller({ needToken: true }, { isTeacher: true }).post(`${homeworkUrl}create`, data);
};

export const updateHomework = data => {
	return apiCaller({ needToken: true }, { isTeacher: true }).post(`${homeworkUrl}update`, data);
};

export const getById = id => {
	return apiCaller({ needToken: true }, { isTeacher: true }).post(`${homeworkUrl}getbyid`, { id });
};

export const getHomeworks = () => {
	return apiCaller({ needToken: true }, { isTeacher: true }).get(`${homeworkUrl}get`);
};

export const deleteHomework = id => {
	return apiCaller({ needToken: true }, { isTeacher: true }).post(`${homeworkUrl}delete`, { id });
};

export const cloneHomework = id => {
	return apiCaller({ needToken: true }, { isTeacher: true }).post(`${homeworkUrl}clone`, { id });
};

export const tracingByIdHomework = id => {
	return apiCaller({ needToken: true }, { isTeacher: true }).post(`${homeworkUrl}tracingbyid`, { id });
};

export const infoByIdHomework = id => {
	return apiCaller({ needToken: true }, { isTeacher: true }).post(`${homeworkUrl}infobyid`, { id });
};

export const sendHomeworkNotification = data => {
	return apiCaller({ needToken: true }, { isTeacher: true }).post(`${notificationUrl}sendhomeworknotif`, data);
};

export const getNotifTypes = () => {
	return apiCaller({ needToken: true }, { isTeacher: true }).get(`${notificationUrl}getnotiftype`);
}

export const updateHomeworkStatus = data => {
	return apiCaller({ needToken: true }, { isTeacher: true }).post(`${homeworkUrl}updatestatus`, data);
};