import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import { toast } from 'react-toastify';
import { merge, rest } from 'lodash';
import { AccountProfileAction } from '../actions';
import * as accountProfileApi from '../apis/accountProfile';
import { getLocalStorageWithExpiry, setLocalStorage, getLocalStorage } from '../helpers/storage';
import ExamCategoryType from "../enums/ExamCategoryType"

const getAllPublishers = state => state.Publisher.getAllPublishers.data;

export function* watcherUpdatePublisherRate() {
	yield takeLatest(types.GET_PUBLISHER_RATES_REQUEST, workerGetPublisherRates);
	yield takeLatest(types.UPDATE_PUBLISHER_RATES_REQUEST, workerUpdatePublisherRates);
	yield takeLatest(types.CHANGE_PASSWORD_REQUEST, workerChangePassword);
	yield takeLatest(types.GET_PROFILE_INFO_REQUEST, workerGetProfileInfo);
	yield takeLatest(types.UPDATE_PROFILE_REQUEST, workerUpdateProfile);
	yield takeLatest(types.UPDATE_USER_EXAM_CATEGORY, workerUpdateUserExamCategory);
}

function getPublisherRates(data) {
	return accountProfileApi.getPublisherRates(data);
}

function* workerGetPublisherRates(action) {
	try {
		const response = yield call(getPublisherRates, action.data);
		if (response.data && response.data.PublisherRates) {
			yield put(
				AccountProfileAction.getPublisherRatesSuccess(response.data.PublisherRates)
			);
		}
	} catch (error) {
		//console.log(error);
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AccountProfileAction.getPublisherRatesFailure());
	}
}

function updatePublisherRates(data) {
	return accountProfileApi.updatePublisherRates(data);
}

function* workerUpdatePublisherRates(action) {
	try {
		const response = yield call(updatePublisherRates, action.data);
		yield put(AccountProfileAction.updatePublisherRatesSuccess(response.data));
		const getPublisherRatesPostData = {
			UserId: getLocalStorageWithExpiry('userId'),
			ChannelCode: 'Web',
		};
		yield put(AccountProfileAction.getPublisherRates(getPublisherRatesPostData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AccountProfileAction.updatePublisherRatesFailure());
	}
}

function changePassword(data) {
	return accountProfileApi.changePassword(data);
}
function* workerChangePassword(action) {
	try {
		const response = yield call(changePassword, action.data);
		yield put(AccountProfileAction.changePsswordSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AccountProfileAction.changePasswordFailure());
	}
}

function getProfileInfo() {
	return accountProfileApi.getProfileInfo();
}
function* workerGetProfileInfo(action) {
	try {
		const response = yield call(getProfileInfo, action.data);
		yield put(AccountProfileAction.getProfileInfoSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AccountProfileAction.getProfileInfoFailure());
	}
}

function updateProfile(data) {
	return accountProfileApi.updateProfile(data);
}
function* workerUpdateProfile(action) {
	try {
		const response = yield call(updateProfile, action.data);
		const userInfo = getLocalStorage('userInfo');
		const updatedUserInfo = {
			...userInfo,
			Name: response.data.Name,
			ClassTypeId: response.data.ClassTypeId,
			Email: response.data.Email,
			EmailConfirmed: response.data.EmailConfirmed,
			PhoneNumber: response.data.PhoneNumber,
			SchoolId: response.data.SchoolId,
			Surname: response.data.Surname,
			TargetQuestion: response.data.TargetQuestion,
			UniversityExamSelection: response.data.UniversityExamSelection,
		};
		setLocalStorage('userInfo', updatedUserInfo);
		toast.success("Bilgilerin başarıyla kaydedildi");
		yield put(AccountProfileAction.updateProfileSuccess(response.data));
		yield put(AccountProfileAction.getProfileInfo(action.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			//toast.error(error.data.Error.toString());
		}
		yield put(AccountProfileAction.updateProfileFailure());
	}
}

function updateUserExamCategory(examCategory) {
	return accountProfileApi.updateUserExamCategory(examCategory);
}
function* workerUpdateUserExamCategory(action) {
	try {
		yield call(updateUserExamCategory, action.data);
		localStorage.setItem('userExamCategory', action.data);
		yield put(AccountProfileAction.updateUserExamCategorySuccess(action.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		localStorage.setItem('userExamCategory', ExamCategoryType.None);
		yield put(AccountProfileAction.updateUserExamCategoryFailure());
	}
}