import { takeLatest } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import { GptResponseAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherGptResponse() {
	yield takeLatest(types.HANDLE_GPT_RESPONSE, workerhandleGptResponse);
}

function handleGptResponse() {}
function* workerhandleGptResponse(action) {
	try {
		handleGptResponse(action.payLoad);
		GptResponseAction.handleGptResponse(action.payLoad);
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
	}
}
