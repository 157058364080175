import React from 'react';
import { NotFoundWrapper } from './style';
import { withRouter } from 'react-router';
import Button from '../button';
import { History } from '../../helpers/history';

export default withRouter(() => {
	return (
		<NotFoundWrapper>
			<div className='not-found-img' />
			<div className='not-found-title'>Page Not Found</div>
			<div className='not-found-description'>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est in orci sit cras commodo. Felis nunc ac, massa
				proin ha.
			</div>
			<Button type='primary' size='md' onClick={() => History.goBack()}>
				Go Back
			</Button>
		</NotFoundWrapper>
	);
});
