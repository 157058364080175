import * as types from '../constants/actionTypes';

export const setTYTCourses = (data) => ({
	type: types.SET_TYT_COURSES,
	data,
});

export const setAYTCourses = (data) => ({
	type: types.SET_AYT_COURSES,
	data,
});

export const setLGSCourses = (data) => ({
	type: types.SET_LGS_COURSES,
	data,
});

export const getDetails = (data) => ({
	type: types.GET_DETAILS,
	data,
});

export const getDetailsSuccess = (data) => ({
	type: types.GET_DETAILS_SUCCESS,
	data,
});

export const getDetailsFail = () => ({
	type: types.GET_DETAILS_FAILURE,
});

export const getUserSolvedProgress = (data) => ({
	type: types.GET_USER_SOLVED_PROGRESS,
	data,
});

export const getUserSolvedProgressSuccess = (data) => ({
	type: types.GET_USER_SOLVED_PROGRESS_SUCCESS,
	data,
});

export const getUserSolvedProgressFail = () => ({
	type: types.GET_USER_SOLVED_PROGRESS_FAILURE,
});
