import React, { Suspense } from 'react';

export default ({ Component, route }) => {
	return (
		<div className='h-screen w-screen'>
			<Suspense fallback={<div>Yükleniyor...</div>}>
				<Component route={route} />
			</Suspense>
		</div>
	);
};
