import * as types from '../constants/actionTypes';
import { OnlineExamStatus } from '../enums/TrialExams';

const initialState = {
	getTrackingOptions: {
		loading: false,
		data: [],
	},
	getTrialExamOptions: {
		loading: false,
		data: [],
	},
	getTrialExamRanking: {
		loading: false,
		data: [],
	},
	getTrialExamSummarySessions: {
		loading: false,
		data: [],
	},
	getTrialExamsRank: {
		loading: false,
		data: [],
	},
	getTrialExamSummary: {
		loading: false,
		data: null,
	},
	getUserPoints: {
		loading: false,
		data: null,
	},
	getTrackingList: {
		loading: false,
		data: null,
	},
	onlineExamStatus: OnlineExamStatus.None,
	getOnlineTrialExamPdfs: {
		loading: false,
		data: null,
	},
	showBookletListModal: false,
};

const TrialExam = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_TRACKING_OPTIONS:
			return {
				...state,
				getTrackingOptions: { ...state.getTrackingOptions, loading: true },
			};

		case types.GET_TRACKING_OPTIONS_SUCCESS:
			return {
				...state,
				getTrackingOptions: { data: payLoad, loading: false },
			};

		case types.GET_TRACKING_OPTIONS_FAILURE:
			return {
				...state,
				getTrackingOptions: { data: [], loading: false },
			};

		case types.GET_TRIAL_EXAM_OPTIONS:
			return {
				...state,
				getTrialExamOptions: { ...state.getTrialExamOptions, loading: true },
			};

		case types.GET_TRIAL_EXAM_OPTIONS_SUCCESS:
			return {
				...state,
				getTrialExamOptions: { data: payLoad, loading: false },
			};

		case types.GET_TRIAL_EXAM_OPTIONS_FAILURE:
		case types.CLEAR_TRIAL_EXAM_OPTIONS:
			return {
				...state,
				getTrialExamOptions: { data: [], loading: false },
			};

		case types.GET_TRIAL_EXAM_RANKING:
			return {
				...state,
				getTrialExamRanking: { ...state.getTrialExamRanking, loading: true },
			};

		case types.GET_TRIAL_EXAM_RANKING_SUCCESS:
			return {
				...state,
				getTrialExamRanking: { data: payLoad, loading: false },
			};

		case types.GET_TRIAL_EXAM_RANKING_FAILURE:
			return {
				...state,
				getTrialExamRanking: { data: [], loading: false },
			};

		case types.GET_TRIAL_EXAM_SUMMARY_SESSIONS:
			return {
				...state,
				getTrialExamSummarySessions: { ...state.getTrialExamSummarySessions, loading: true },
			};

		case types.GET_TRIAL_EXAM_SUMMARY_SESSIONS_SUCCESS:
			return {
				...state,
				getTrialExamSummarySessions: { data: payLoad, loading: false },
			};

		case types.GET_TRIAL_EXAM_SUMMARY_SESSIONS_FAILURE:
		case types.EMPTY_TRIAL_EXAM_SUMMARY_SESSIONS:
			return {
				...state,
				getTrialExamSummarySessions: { data: [], loading: false },
			};

		case types.GET_TRIAL_EXAMS_RANK:
			return {
				...state,
				getTrialExamsRank: { ...state.getTrialExamsRank, loading: true },
			};

		case types.GET_TRIAL_EXAMS_RANK_SUCCESS:
			return {
				...state,
				getTrialExamsRank: { data: payLoad, loading: false },
			};

		case types.GET_TRIAL_EXAMS_RANK_FAILURE:
			return {
				...state,
				getTrialExamsRank: { data: [], loading: false },
			};
		case types.GET_TRIAL_EXAM_SUMMARY:
			return {
				...state,
				getTrialExamSummary: { ...state.getTrialExamSummary, loading: true },
			};
		case types.GET_TRIAL_EXAM_SUMMARY_SUCCESS:
			return {
				...state,
				getTrialExamSummary: { data: payLoad, loading: false },
			};
		case types.GET_TRIAL_EXAM_SUMMARY_FAILURE:
			return {
				...state,
				getTrialExamSummary: { data: null, loading: false },
			};
		case types.CLEAR_TRIAL_EXAM_SUMMARY:
			return {
				...state,
				getTrialExamSummary: { data: null, loading: false },
			};
		case types.GET_USER_POINTS:
			return {
				...state,
				getUserPoints: { ...state.getUserPoints, loading: true },
			};
		case types.GET_USER_POINTS_SUCCESS:
			return {
				...state,
				getUserPoints: { data: payLoad, loading: false },
			};
		case types.GET_USER_POINTS_FAILURE:
			return {
				...state,
				getUserPoints: { data: null, loading: false },
			};
		case types.CLEAR_USER_POINTS:
			return {
				...state,
				getUserPoints: { data: null, loading: false },
			};
		case types.GET_TRACKING_LIST:
			return {
				...state,
				getTrackingList: { ...state.getTrackingList, loading: true },
			};
		case types.GET_TRACKING_LIST_SUCCESS:
			return {
				...state,
				getTrackingList: { data: payLoad, loading: false },
			};
		case types.GET_TRACKING_LIST_FAILURE:
			return {
				...state,
				getTrackingList: { data: null, loading: false },
			};
			case types.SET_ONLINE_TRIAL_EXAM_STATUS:
			return {
				...state,
				onlineExamStatus: payLoad,
			};

		case types.GET_ONLINE_TRIAL_EXAM_PDFS:
			return {
				...state,
				getOnlineTrialExamPdfs: { ...state.getOnlineTrialExamPdfs, loading: true },
			};

		case types.GET_ONLINE_TRIAL_EXAM_PDFS_SUCCESS:
			return {
				...state,
				getOnlineTrialExamPdfs: { data: payLoad, loading: false },
			};

		case types.GET_ONLINE_TRIAL_EXAM_PDFS_FAILURE:
			return {
				...state,
				getOnlineTrialExamPdfs: { data: null, loading: false },
			};
			case types.SET_SHOW_BOOKLET_LIST_MODAL:
			return {
				...state,
				showBookletListModal: payLoad,
			};
		default:
			return state;
	}
};

export default TrialExam;
