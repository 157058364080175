import * as types from '../constants/actionTypes';
const initialState = {
	getLectureSection: {
		loading: false,
		data: [],
	},
	getLecture: {
		loading: false,
		data: [],
	},
	createLectureSection: {
		loading: false,
		data: [],
	},
	updateLectureSection: {
		loading: false,
		data: [],
	},
	// lastSeenLectureOrder: 0,
};

const LectureSection = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_LECTURE_SECTION_REQUEST:
			return { ...state, getLectureSection: { ...state.getLectureSection, loading: true } };
		case types.GET_LECTURE_SECTION_SUCCESS:
			return { ...state, getLectureSection: { data: payLoad, loading: false } };
		case types.GET_LECTURE_SECTION_FAILURE:
			return { ...state, getLectureSection: { data: [], loading: false } };

		case types.GET_LECTURE_REQUEST:
			return { ...state, getLecture: { data: [], loading: true } };
		case types.GET_LECTURE_SUCCESS:
			return { ...state, getLecture: { data: payLoad, loading: false } };
		case types.GET_LECTURE_FAILURE:
			return { ...state, getLecture: { data: [], loading: false } };

		case types.UPDATE_LECTURE_SECTION_REQUEST:
			return { ...state, updateLectureSection: { ...state.updateLectureSection, loading: true } };
		case types.UPDATE_LECTURE_SECTION_SUCCESS:
			return { ...state, updateLectureSection: { data: payLoad, loading: false } };
		case types.UPDATE_LECTURE_SECTION_FAILURE:
			return { ...state, updateLectureSection: { data: [], loading: false } };

		case types.CREATE_LECTURE_SECTION_REQUEST:
			return { ...state, createLectureSection: { ...state.createLectureSection, loading: true } };
		case types.CREATE_LECTURE_SECTION_SUCCESS:
			return { ...state, createLectureSection: { data: payLoad, loading: false } };
		case types.CREATE_LECTURE_SECTION_FAILURE:
			return { ...state, createLectureSection: { data: [], loading: false } };

		// case types.GET_LAST_SEEN_LECTURE_ORDER:
		// 	return { ...state, lastSeenLectureOrder: state.lastSeenLectureOrder };
		// case types.SET_LAST_SEEN_LECTURE_ORDER:
		// 	return { ...state, lastSeenLectureOrder: payLoad };

		default:
			return state;
	}
};

export default LectureSection;
