import * as types from '../constants/actionTypes';

export const setQuestionToStore = question => {
	return {
		type: types.SET_QUESTION_TO_STORE,
		question,
	};
};
export const setAnswerToStore = answer => {
	return {
		type: types.SET_ANSWER_TO_STORE,
		answer,
	};
};
export const getQuestionFromStore = questionNumber => {
	return {
		type: types.GET_QUESTION_FROM_STORE,
		questionNumber,
	};
};
export const getAllQuestionFromStore = () => {
	return {
		type: types.GET_ALL_QUESTION_FROM_STORE,
	};
};
export const clearQuestionFromStore = () => {
	return {
		type: types.CLEAR_QUESTION_FROM_STORE,
	};
};
