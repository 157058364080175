import * as React from "react";
const TakesTimeOutlined = (props) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 12 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.84105 0.0401386C5.60253 0.164697 5.58398 0.503919 5.8066 0.649679C5.85695 0.684131 5.98681 0.705333 6.31278 0.729184C6.55395 0.747736 6.89317 0.792789 7.07073 0.829891C8.89405 1.19827 10.4073 2.53395 11.0062 4.30427C11.576 5.98448 11.2792 7.7972 10.2006 9.23095C10.0124 9.48272 9.67055 9.84314 9.41879 10.0525C9.30483 10.1479 9.20412 10.2248 9.19617 10.2248C9.18822 10.2248 9.18027 10.0287 9.18027 9.78749C9.18027 9.2919 9.15907 9.19915 9.01596 9.11169C8.83045 8.99773 8.58663 9.07459 8.50713 9.2707C8.47268 9.36081 8.46472 9.50657 8.46472 10.1797C8.46472 10.9112 8.47003 10.988 8.51508 11.0728C8.59988 11.2318 8.64494 11.2451 9.5778 11.3484C10.0522 11.4014 10.4868 11.4438 10.5451 11.4438C10.6988 11.4438 10.8075 11.367 10.8631 11.2212C10.9082 11.1073 10.9082 11.094 10.8658 10.9801C10.7942 10.7972 10.7174 10.7654 10.235 10.7097L9.82162 10.6647L10.1529 10.3467C10.3331 10.1744 10.5584 9.93325 10.6538 9.81664C11.2368 9.09314 11.6688 8.20003 11.8676 7.30957C11.9736 6.82194 12.0001 6.56222 12.0001 6.01099C12.0001 5.45975 11.9736 5.20003 11.8676 4.7124C11.7642 4.25127 11.6105 3.81399 11.3932 3.37406C11.0698 2.71152 10.7015 2.20533 10.1847 1.70445C9.11402 0.66558 7.76243 0.0825414 6.26508 0.00833646C5.97621 -0.0075646 5.92321 -0.00226425 5.84105 0.0401386Z"
			fill="#7B5AFF"
		/>
		<path
			d="M1.29064 0.631218C1.06802 0.766377 1.07862 1.10825 1.30919 1.22751C1.35424 1.25136 1.5636 1.28846 1.78357 1.31231L2.17844 1.35472L1.84982 1.67274C1.29859 2.20277 0.938162 2.69571 0.60689 3.37415C0.318021 3.96514 0.127208 4.58793 0.0318021 5.25578C-0.0106007 5.5632 -0.0106007 6.45896 0.0318021 6.76638C0.227915 8.14977 0.842756 9.37415 1.81537 10.3176C2.88339 11.3538 4.23763 11.9395 5.73498 12.0137C6.02385 12.0296 6.07685 12.0243 6.15901 11.9819C6.39753 11.8574 6.41608 11.5181 6.19346 11.3724C6.14311 11.3379 6.01325 11.3194 5.68993 11.2929C5.18904 11.2531 4.83657 11.1895 4.42314 11.0597C2.58922 10.4899 1.20848 8.96602 0.818904 7.0791C0.495583 5.52079 0.893109 3.90684 1.91078 2.64535C2.13074 2.37504 2.73763 1.7973 2.80389 1.7973C2.81184 1.7973 2.81979 1.99341 2.81979 2.23458C2.81979 2.73016 2.84099 2.82292 2.9841 2.91037C3.16961 3.02433 3.41343 2.94747 3.49293 2.75136C3.52738 2.66125 3.53534 2.51549 3.53534 1.84235C3.53534 1.1109 3.53003 1.03405 3.48498 0.94924C3.40018 0.787579 3.35512 0.776978 2.42226 0.673621C1.94788 0.620618 1.51855 0.578215 1.4682 0.578215C1.41784 0.578215 1.33834 0.602066 1.29064 0.631218Z"
			fill="#7B5AFF"
		/>
		<path
			d="M5.31086 2.11514C3.41069 2.46231 2.05115 4.08687 2.05115 6.0109C2.05115 7.91107 3.37359 9.51973 5.24991 9.89871C5.60768 9.97026 6.45309 9.96496 6.80026 9.88811C8.0697 9.60189 9.09267 8.78033 9.60415 7.63281C9.86917 7.04447 9.96457 6.54093 9.94072 5.86514C9.91952 5.2344 9.81086 4.80242 9.53525 4.24853C9.00256 3.17521 8.00874 2.40401 6.81351 2.13899C6.45044 2.05683 5.68984 2.04623 5.31086 2.11514ZM6.13242 4.27768C6.19337 4.30418 6.25963 4.35454 6.29143 4.40754C6.34178 4.48705 6.34443 4.5374 6.34443 5.17874V5.86249L6.89832 6.42167C7.25079 6.77415 7.46016 7.00471 7.47076 7.05242C7.53436 7.30418 7.2932 7.54535 7.04143 7.48175C6.99373 7.47114 6.73666 7.23528 6.31263 6.81125L5.65539 6.15666V5.3245C5.65539 4.38899 5.65539 4.38634 5.85415 4.28828C5.98136 4.22468 5.99726 4.22468 6.13242 4.27768Z"
			fill="#7B5AFF"
		/>
	</svg>
);
export default TakesTimeOutlined;
