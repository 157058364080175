import styled from 'styled-components';

export const Container = styled.div`
	.instagram {
		color: #8a3ab9;
		font-size: 1.2rem;
	}
	.youtube {
		color: #f00;
		font-size: 1.2rem;
	}
	.twitter {
		color: #2dafec;
		font-size: 1.2rem;
	}
	.info {
		color: #00f;
		font-size: 1.2rem;
	}
`;
