import * as types from '../constants/actionTypes';

const initialState = {
	printableTestFile: {
		loading: false,
		data: [],
	},
	printableTestParameters: {
		loading: false,
		data: [],
		failedCode: false
	},
	printableTestFileOpenNewTab: {
		loading: false,
		data: [],
	},
};

const PrintableTest = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_PRINTABLE_TEST_FILE:
			return {
				...state,
				printableTestFile: { ...state.printableTestFile, loading: true },
			};

		case types.GET_PRINTABLE_TEST_FILE_SUCCESS:
			return {
				...state,
				printableTestFile: { data: payLoad, loading: false },
			};

		case types.GET_PRINTABLE_TEST_FILE_FAILURE:
			return {
				...state,
				printableTestFile: { data: [], loading: false },
			};

		case types.GET_PARAMETERS:
			return {
				...state,
				printableTestParameters: { ...state.printableTestParameters, loading: true },
			};

		case types.GET_PARAMETERS_SUCCESS:
			return {
				...state,
				printableTestParameters: { data: payLoad, loading: false, failedCode: false },
			};

		case types.GET_PARAMETERS_FAILURE:
			return {
				...state,
				printableTestParameters: { data: [], loading: false, failedCode: payLoad },
			};

		case types.SET_PARAMETERS:
			return {
				...state,
				printableTestParameters: { data: payLoad, loading: false },
			};

		case types.GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB:
			return {
				...state,
				printableTestFileOpenNewTab: { ...state.printableTestFileOpenNewTab, loading: true },
			};

		case types.GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB_SUCCESS:
			return {
				...state,
				printableTestFileOpenNewTab: { data: payLoad, loading: false },
			};

		case types.GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB_FAILURE:
			return {
				...state,
				printableTestFileOpenNewTab: { data: [], loading: false },
			};
		case types.CLEAR_PDF_DATA_FOR_HOMEWORKS:
			return {
				...state,
				printableTestFileOpenNewTab: { data: [], loading: false },
				printableTestParameters: { data: [], loading: false, failedCode: false },
			};
		default:
			return state;
	}
};

export default PrintableTest;
