import * as types from '../constants/actionTypes';

export const setTrialExamType = (data) => ({
	type: types.SET_TRIAL_EXAM_TYPE,
	data,
});

export const setTrialExamTypeVal = (data) => ({
	type: types.SET_TRIAL_EXAM_TYPE_VAL,
	data,
});

export const setPartialExamSessionType = (data) => ({
	type: types.SET_PARTIAL_TRIAL_EXAM_SESSION_TYPE,
	data,
});

export const setPartialExamSessionTypeVal = (data) => ({
	type: types.SET_PARTIAL_TRIAL_EXAM_SESSION_TYPE_VAL,
	data,
});

export const setGeneralTrialExamSessionType = (data) => ({
	type: types.SET_GENERAL_TRIAL_EXAM_SESSION_TYPE,
	data,
});

export const setPartialTYTData = (data) => ({
	type: types.SET_PARTIAL_TYT_DATA,
	data,
});

export const setPartialAYTData = (data) => ({
	type: types.SET_PARTIAL_AYT_DATA,
	data,
});

export const setPartialYDTData = (data) => ({
	type: types.SET_PARTIAL_YDT_DATA,
	data,
});

export const setGeneralYKSData = (data) => ({
	type: types.SET_GENERAL_YKS_DATA,
	data,
});
export const setGeneralLGSData = (data) => ({
	type: types.SET_GENERAL_LGS_DATA,
	data,
});

export const setGeneralExamBanner = (data) => ({
	type: types.SET_GENERAL_EXAM_BANNER,
	data,
});

export const setHorizontalData = (data) => ({
	type: types.SET_HORIZONTAL_DATA,
	data,
});
