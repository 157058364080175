import ExamCategoryType from '../../../enums/ExamCategoryType';

export const onPressKey = (event, handleKey, nextHandler, prevHandler, achievementQuestionData, userExamCategory, showNextButton, showPrevButton) => {
	//(typeof event.key);
	switch (event.key.toUpperCase()) {
		case 'A':
			handleKey(event.key);
			break;
		case 'B':
			handleKey(event.key);
			break;

		case 'C':
			handleKey(event.key);
			break;

		case 'D':
			handleKey(event.key);
			break;
		case 'E':
			if (userExamCategory === ExamCategoryType.YKS) {
				handleKey(event.key);
			}
			break;
		case 'ARROWRIGHT':
			if (showNextButton) {
				nextHandler();
			}
			break;
		case 'ARROWLEFT':
			if (showPrevButton) {
				prevHandler();
			}
			break;
		default:
			break;
	}
};