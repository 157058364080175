import * as types from '../constants/actionTypes';

export const getPromotionBanner = data => {
	return {
		type: types.GET_PROMOTION_BANNER,
		payLoad: data,
	};
};

export const getPromotionBannerSuccess = data => {
	return {
		type: types.GET_PROMOTION_BANNER_SUCCESS,
		payLoad: data,
	};
};

export const getPromotionBannerFailure = data => {
	return {
		type: types.GET_PROMOTION_BANNER_FAILURE,
		payLoad: data,
	};
};