export const setLastSeen = (testSectionId, questionNumber) => {
	const lastSeenQuestion = getLastSeen(testSectionId);
	console.log('CHCH', questionNumber, lastSeenQuestion);
	if (questionNumber > lastSeenQuestion) localStorage.setItem(testSectionId + '_LastSeen', questionNumber);
};
export const getLastSeen = testSectionId => {
	let lastSeenQuestion = localStorage.getItem(testSectionId + '_LastSeen');
	if (lastSeenQuestion) return Number(lastSeenQuestion);
	return 0;
};
export const setSummery = (testSectionId, list) => {
	localStorage.setItem(testSectionId + 'Summery', JSON.stringify(list));
};

export const isSummeryContains = (testSectionId, questionNumber) => {
	let list = [];
	let local = localStorage.getItem(testSectionId + 'Summery');
	if (local) list = JSON.parse(local);
	//return list.some(x=>x.QuestionNumber===questionNumber&&x.TestSectionElementId!==0);
	return list.some(item => item.TestSectionSummaryDetails?.some(detail => detail.QuestionNumber == questionNumber && detail.TestSectionElementId !== 0));
};
export const addToSummery = (testSectionId, question) => {
	let list = [];
	let local = localStorage.getItem(testSectionId + 'Summery');
	if (local) list = JSON.parse(local);
	setSummery(testSectionId, [...list,question]);
};
export default setLastSeen;
