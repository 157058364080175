export const TrialExamType = {
	None: 0,
	Partial: 1,
	General: 2,
};

export const OnlineExamStatus = {
	None: 0,
	Promote: 1,
	Active: 2,
};

export const ExamSubTypeId = { //TrackingExamType
	None: 0,
	TYT: 1,
	AYT: 2,
	YDT: 3,
	LGS: 4,
	KPSS: 5,
};

export const TrialExamDifficultyLevel = {
	VeryEasy: 1,
	Easy: 2,
	Normal: 3,
	Hard: 4,
	VeryHard: 5,
};

export const SessionNames = {
	1: 'TYT',
	2: 'AYT',
	3: 'YDT',
	4: 'LGS Sözel',
	5: 'LGS Sayısal',
	6: 'LGS',
	7: 'KPSS',
};

export const FinishExamModalType = {
	Finish: 0,
	Continue: 1,
};

export const TrialExamFinishType = {
	OldVersion: 0,
	FinishSession: 1,
	FinishALl: 2,
};

export const OnlineTrialExamStages = {
	None: 0,
	PDF: 1,
	Solving: 2,
	Calculating: 3,
	Result: 4,
};

export const OnlineTrialExamSessionStages = {
	PDF: 1,
	ExamTypeStarting: 2,
	ExamTypeStarted: 3,
	ExamTypeFinished: 4,
	ExamCompleted: 5,
	ResultAnnouncement: 6,
	ResultAnnounced: 7,
};

export const OnlineTrialExamSessionStatus = {
	None: 0,
	Pdf: 1,
	Start: 2,
	Continue: 3,
	Unsolved: 4,
	Result: 5,
	Locked: 6,
	SeenUnSolved: 7,
	JoinedLock: 8,
};
