import * as types from '../constants/actionTypes';
const initialState = {
	getStudyStatistics: {
		loading: false,
		data: [],
	},
	getUserSolved: {
		loading: false,
		data: [],
	},
	getUserSolvedSummary: {
		loading: false,
		data: [],
	},
	getDailyStatistics: {
		loading: false,
		data: [],
	},
	getTestSectionSummaryAnalytics: {
		loading: false,
		data: [],
	},
	getElementSummary: {
		loading: false,
		data: [],
	},
	showSummaryQuestions: false
};

const UserStatistic = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_STUDY_STATISTICS_REQUEST:
			return {
				...state,
				getStudyStatistics: { ...state.getStudyStatistics, loading: true },
			};
		case types.GET_STUDY_STATISTICS_SUCCESS:
			return {
				...state,
				getStudyStatistics: { data: payLoad, loading: false },
			};

		case types.GET_USER_DAILY:
			return {
				...state,
				getDailyStatistics: { ...state.getDailyStatistics, loading: true },
			};
		case types.GET_USER_DAILY_SUCCESS:
			return {
				...state,
				getDailyStatistics: { data: payLoad, loading: false },
			};
		case types.GET_STUDY_STATISTICS_FAILURE:
			return {
				...state,
				getStudyStatistics: { data: [], loading: false },
			};

		case types.GET_USER_SOLVED_REQUEST:
			return {
				...state,
				getUserSolved: { ...state.getUserSolved, loading: true },
			};
		case types.GET_USER_SOLVED_SUCCESS:
			return {
				...state,
				getUserSolved: { data: payLoad, loading: false },
			};
		case types.GET_USER_SOLVED_FAILURE:
			return {
				...state,
				getUserSolved: { ...state.getUserSolved, loading: false },
			};

		case types.GET_USER_SOLVED_SUMMARY_REQUEST:
			return {
				...state,
				getUserSolvedSummary: { ...state.getUserSolvedSummary, loading: true },
			};
		case types.GET_USER_SOLVED_SUMMARY_SUCCESS:
			return {
				...state,
				getUserSolvedSummary: { data: payLoad, loading: false },
			};
		case types.GET_USER_SOLVED_SUMMARY_FAILURE:
			return {
				...state,
				getUserSolvedSummary: { data: [], loading: false },
			};

		case types.GET_TEST_SECTION_SUMMARY_ANALYTICS:
			return {
				...state,
				getTestSectionSummaryAnalytics: {
					...state.getTestSectionSummaryAnalytics,
					loading: true,
				},
			};
		case types.GET_TEST_SECTION_SUMMARY_ANALYTICS_SUCCESS:
			return {
				...state,
				getTestSectionSummaryAnalytics: { data: payLoad, loading: false },
			};
		case types.GET_TEST_SECTION_SUMMARY_ANALYTICS_FAILURE:
			return {
				...state,
				getTestSectionSummaryAnalytics: {
					data: [],
					loading: false,
				},
			};
		case types.GET_ELEMENT_SUMMARY:
			return {
				...state,
				getElementSummary: { ...state.getElementSummary, loading: true },
			};
		case types.GET_ELEMENT_SUMMARY_SUCCESS:
			return {
				...state,
				getElementSummary: { data: payLoad, loading: false },
			};
		case types.GET_ELEMENT_SUMMARY_FAILURE:
			return {
				...state,
				getElementSummary: { data: [], loading: false },
			};
		case types.SET_SHOW_SUMMARY_QUESTIONS:
			return {
				...state,
				showSummaryQuestions: payLoad,
			};

		default:
			return state;
	}
};

export default UserStatistic;
