import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as lectureSectionApi from '../apis/lectureSection';
import { LectureSectionAction, LectureAction } from '../actions';
import { toast } from 'react-toastify';
import { getLocalStorageWithExpiry, getUniqueDeviceId } from '../helpers/storage';

export function* watcherLectureSection() {
	yield takeLatest(types.GET_LECTURE_SECTION_REQUEST, workerGetLectureSection);
	yield takeLatest(types.CREATE_LECTURE_SECTION_REQUEST, workerCreateLectureSection);
	yield takeLatest(types.UPDATE_LECTURE_SECTION_REQUEST, workerUpdateLectureSection);
	yield takeLatest(types.GET_LECTURE_REQUEST, workerGetLecture);
}

const lectures = store => store.Lecture.getLectureBySubCourseTopicId.data;
const lastSeenLectureOrder = store => store.LectureSection.lastSeenLectureOrder;

function getLectureSection(data) {
	return lectureSectionApi.getLectureSection(data);
}
function* workerGetLectureSection(action) {
	try {
		const response = yield call(getLectureSection, action.data);
		yield put(LectureSectionAction.getLectureSectionSuccess(response.data));
		yield put(LectureSectionAction.getLectureSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(LectureSectionAction.getLectureSectionFailure());
	}
}

function* workerGetLecture(action) {
	try {
		const UserId = getLocalStorageWithExpiry('userId');
		if (action.data.OrderNumber > Number(sessionStorage.getItem('lastSeenLectureOrder'))) {
			const postData = { UserId, LectureId: action.data.LectureId, DeviceId:getUniqueDeviceId() };
			yield put(LectureSectionAction.createLectureSection(postData));
		} else {
			const postData = { UserId, LectureId: action.data.LectureId, OrderNumber: action.data.OrderNumber };
			yield put(LectureSectionAction.getLectureSection(postData));
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(LectureSectionAction.getLectureFailure());
	}
}

function createLectureSection(data) {
	return lectureSectionApi.createLectureSection(data);
}
function* workerCreateLectureSection(action) {
	try {
		const response = yield call(createLectureSection, action.data);
		const lectureOrder = Number(sessionStorage.getItem('lastSeenLectureOrder')) + 1;
		sessionStorage.setItem('lastSeenLectureOrder', lectureOrder);
		yield put(LectureSectionAction.createLectureSectionSuccess(response.data));
		yield put(LectureSectionAction.getLectureSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(LectureSectionAction.createLectureSectionFailure());
	}
}

function updateLectureSection(data) {
	return lectureSectionApi.updateLectureSection(data);
}
function* workerUpdateLectureSection(action) {
	try {
		const response = yield call(updateLectureSection, action.data);
		yield put(LectureSectionAction.updateLectureSectionSuccess(response.data));
	} catch (error) {
		//console.log(error);
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(LectureSectionAction.updateLectureSectionFailure());
	}
}
