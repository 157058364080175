import * as types from '../constants/actionTypes';

export const getAllPublishers = () => {
	return {
		type: types.GET_ALL_PUBLISHERS_REQUEST,
	};
};

export const getAllPublishersSuccess = data => {
	return {
		type: types.GET_ALL_PUBLISHERS_SUCCESS,
		payload: data,
	};
};

export const getAllPublishersFailure = () => {
	return {
		type: types.GET_ALL_PUBLISHERS_FAILURE,
	};
};
