import * as types from '../constants/actionTypes';
const initialState = {
	getLectureBySubCourseTopicId: {
		loading: false,
		data: [],
	},
	getLectureInfoByLectureId: {
		loading: false,
		data: [],
	},
};

const Lecture = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_REQUEST:
			return {
				...state,
				getLectureBySubCourseTopicId: { ...state.getLectureBySubCourseTopicId, loading: true },
			};
		case types.GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_SUCCESS:
			return { ...state, getLectureBySubCourseTopicId: { data: payLoad, loading: false } };
		case types.GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_FAILURE:
			return {
				...state,
				getLectureBySubCourseTopicId: { data: [], loading: false },
			};

		case types.GET_LECTURE_INFO_BY_LECTURE_ID_REQUEST:
			return {
				...state,
				getLectureInfoByLectureId: { ...state.getLectureInfoByLectureId, loading: true },
			};
		case types.GET_LECTURE_INFO_BY_LECTURE_ID_SUCCESS:
			return { ...state, getLectureInfoByLectureId: { data: payLoad, loading: false } };
		case types.GET_LECTURE_INFO_BY_LECTURE_ID_FAILURE:
			return {
				...state,
				getLectureInfoByLectureId: { data: [], loading: false },
			};

		default:
			return state;
	}
};

export default Lecture;
