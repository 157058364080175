import { apiCaller } from '../helpers/apiCaller';

export const createLectureSection = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/LectureSection/create`, data);
};

export const getLectureSection = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/LectureSection/get`, data);
};

export const updateLectureSection = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/LectureSection/update`, data);
};
