import * as types from '../constants/actionTypes';

export const getLectureBySubCourseTopicId = subCourseTopicId => {
	return {
		type: types.GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_REQUEST,
		subCourseTopicId,
	};
};
export const getLectureBySubCourseTopicIdSuccess = data => {
	return {
		type: types.GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_SUCCESS,
		payLoad: data,
	};
};
export const getLectureBySubCourseTopicIdFailure = () => {
	return {
		type: types.GET_LECTURE_BY_SUB_COURSE_TOPIC_ID_FAILURE,
	};
};

export const getLectureInfoByLectureId = lectureId => {
	return {
		type: types.GET_LECTURE_INFO_BY_LECTURE_ID_REQUEST,
		lectureId,
	};
};
export const getLectureInfoByLectureIdSuccess = data => {
	return {
		type: types.GET_LECTURE_INFO_BY_LECTURE_ID_SUCCESS,
		payLoad: data,
	};
};
export const getLectureInfoByLectureIdFailure = () => {
	return {
		type: types.GET_LECTURE_INFO_BY_LECTURE_ID_FAILURE,
	};
};
