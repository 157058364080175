import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/app';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './store';
import firebase from "firebase/app";
import "firebase/analytics";

import 'react-responsive-carousel/lib/styles/carousel.min.css'; 

const store = configureStore();
var firebaseConfig = {
    apiKey: "AIzaSyBIFQ5igE5cxD5OSnsF915pBZ6Jj3SW0Ro",
    authDomain: "pakodemy-94f0d.firebaseapp.com",
    databaseURL: "https://pakodemy-94f0d.firebaseio.com",
    projectId: "pakodemy-94f0d",
    storageBucket: "pakodemy-94f0d.appspot.com",
    messagingSenderId: "339323175211",
    appId: "1:339323175211:web:8302acc63e92b40282c56e",
    measurementId: "G-68DCK109X5"
  };
  
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
