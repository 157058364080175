import React from 'react';
const Login = React.lazy(() => import('../containers/auth/login'));
const Register = React.lazy(() => import('../containers/auth/register'));
const CodeLogin = React.lazy(() => import('../containers/auth/codeLogin'));
const ForgetPassword = React.lazy(() => import('../containers/auth/forgetPassword'));
const PrivacyAndPolicy = React.lazy(() => import('../containers/privacyAndPolicy'));
const DistanceSellingContract = React.lazy(() => import('../containers/distanceSellingContract'));
const CancellationAndReturnPolicies = React.lazy(() => import('../containers/cancellationAndReturnPolicies'));
const Terms = React.lazy(() => import('../containers/terms'));
const Contract = React.lazy(() => import('../containers/contract'));

const publicRoute = [
	{
		path: '/login',
		component: Login,
		layout: 'public',
	},
	{
		path: '/register',
		component: Register,
		layout: 'public',
	},
	{
		path: '/codeLogin',
		component: CodeLogin,
		layout: 'public',
	},
	{
		path: '/forget-password',
		component: ForgetPassword,
		layout: 'public',
	},
	{
		path: '/privacy-and-policy',
		component: PrivacyAndPolicy,
		layout: 'public',
	},
	{
		path: '/distance-selling-contract',
		component: DistanceSellingContract,
		layout: 'public',
	},
	{
		path: '/cancellation-and-return-policies',
		component: CancellationAndReturnPolicies,
		layout: 'public',
	},
	{
		path: '/terms',
		component: Terms,
		layout: 'public',
	},
	{
		path: '/contract',
		component: Contract,
		layout: 'public',
	},
];
export default publicRoute;
