import Favorite from './Favorite';
import FavoriteOutlined from './FavoriteOutlined';
import Like from './Like';
import LikeOutlined from './LikeOutlined';
import NoIdea from './NoIdea';
import NoIdeaOutlined from './NoIdeaOutlined';
import NoneOutlined from './NoneOutlined';
import PassDirect from './PassDirect';
import PassDirectOutlined from './PassDirectOutlined';
import TakesTime from './TakesTime';
import TakesTimeOutlined from './TakesTimeOutlined';

export {
	Favorite,
	FavoriteOutlined,
	Like,
	LikeOutlined,
	NoIdea,
	NoIdeaOutlined,
	NoneOutlined,
	PassDirect,
	PassDirectOutlined,
	TakesTime,
	TakesTimeOutlined,
};
