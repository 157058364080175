import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import RootSaga from '../sagas';
import Reducer from '../reducers';
import createSagaMiddleware from 'redux-saga';
import { History } from '../helpers/history';

// todo: use following code to manage development and production
// console.log(process.env.NODE_ENV);

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// dev tools middleware
let Middlewares = compose(applyMiddleware(routerMiddleware(History), sagaMiddleware));
if (process.env.NODE_ENV === 'development') {
	let reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

	if (reduxDevTools) {
		Middlewares = compose(applyMiddleware(routerMiddleware(History), sagaMiddleware), reduxDevTools);
	} else {
		Middlewares = compose(applyMiddleware(routerMiddleware(History), sagaMiddleware));
	}
}

export default function configureStore() {
	const store = createStore(Reducer(History), Middlewares);
	// run the saga
	sagaMiddleware.run(RootSaga);
	return store;
}
