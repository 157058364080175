import { apiCaller } from '../helpers/apiCaller';

const trackingUrl =`${process.env.REACT_APP_API_URL}/api/TrackingExam/`

export const getTrackingOptions = async () => {
  const url = `${trackingUrl}options`;
  return apiCaller({ needToken: true }).get(url);
};

export const updateTrackingOptions = async (request) => {
  const url = `${trackingUrl}options`;
  return apiCaller({ needToken: true }).post(url, request);
};
export const getTrackingList = async () => {
  const url = `${trackingUrl}getv2`;
  return apiCaller({ needToken: true }).get(url);
};

export const createTracking = async (request) => {
  const url = `${trackingUrl}create`;
  return apiCaller({ needToken: true }).post(url, request);
};
export const updateTracking = async (request) => {
  const url = `${trackingUrl}update`;
  return apiCaller({ needToken: true }).post(url, request);
};
export const deleteTrackingApi = async (request) => {
  const url = `${trackingUrl}delete`;
  return apiCaller({ needToken: true }).post(url, request);
};

export const getUserPoints = async (request) => {
  const url = `${trackingUrl}getuserpoints`;
  return apiCaller({ needToken: true }).post(url, request);
}

export const updatePoints = async (request) => {
  const url = `${trackingUrl}updateayttrackingparent`;
  return apiCaller({ needToken: true }).post(url, request);
}
