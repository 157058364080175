import * as types from '../constants/actionTypes';

const initialState = {
	getDailyTask: {
		loading: false,
		data: [],
	},
	addDailyTask: {
		loading: false,
		data: [],
	},
	deleteDailyTask: {
		loading: false,
		data: [],
	},
	getIsAddedPlan: {
		loading: false,
		data: [],
	},
};

const DailyTask = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_DAILY_TASK_REQUEST:
			return {
				...state,
				getDailyTask: { ...state.getDailyTask, loading: true },
			};
		case types.GET_DAILY_TASK_SUCCESS:
			return {
				...state,
				getDailyTask: { data: payLoad, loading: false },
			};
		case types.GET_DAILY_TASK_FAILURE:
			return {
				...state,
				getDailyTask: { ...state.getDailyTask, loading: false },
			};

		case types.ADD_DAILY_TASK_REQUEST:
			return {
				...state,
				addDailyTask: { ...state.addDailyTask, loading: true },
			};
		case types.ADD_DAILY_TASK_SUCCESS:
			return {
				...state,
				addDailyTask: { data: payLoad, loading: false },
			};
		case types.ADD_DAILY_TASK_FAILURE:
			return {
				...state,
				addDailyTask: { ...state.addDailyTask, loading: false },
			};

		case types.DELETE_DAILY_TASK_REQUEST:
			return {
				...state,
				deleteDailyTask: { ...state.deleteDailyTask, loading: true },
			};
		case types.DELETE_DAILY_TASK_SUCCESS:
			return {
				...state,
				deleteDailyTask: { data: payLoad, loading: false },
			};
		case types.DELETE_DAILY_TASK_FAILURE:
			return {
				...state,
				deleteDailyTask: { ...state.deleteDailyTask, loading: false },
			};
		case types.GET_IS_ADDED_PLAN:
			return {
				...state,
				getIsAddedPlan: { ...state.getIsAddedPlan, loading: true },
			};
		case types.GET_IS_ADDED_PLAN_SUCCESS:
			return {
				...state,
				getIsAddedPlan: { data: payLoad, loading: false },
			};
		case types.GET_IS_ADDED_PLAN_FAILURE:
			return {
				...state,
				getIsAddedPlan: { ...state.getIsAddedPlan, loading: false },
			};

		default:
			return state;
	}
};

export default DailyTask;
