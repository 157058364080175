import { KttSelectedActions } from "../constants/actionTypes";
export const initialState = {
    CourseId: undefined,
    SubCourseTopicId: undefined,
    SubCourseTopicName: undefined,
    QuestionCount: undefined,
    AverageDifficulty: undefined
  };
  
  const KttSelectedTopicReducer = (state = initialState, action) => {
    switch (action.type) {
        case KttSelectedActions.SET_COURSE_ID:
            return { ...state, CourseId: action.payload };
        case KttSelectedActions.SET_SUBCOURSE_TOPIC_ID:
            return { ...state, SubCourseTopicId: action.payload };
        case KttSelectedActions.SET_SUBCOURSE_TOPIC_NAME:
            return { ...state, SubCourseTopicName: action.payload };
        case KttSelectedActions.SET_QUESTION_COUNT:
            return { ...state, QuestionCount: action.payload };
        case KttSelectedActions.SET_AVERAGE_DIFFICULTY:
            return { ...state, AverageDifficulty: action.payload };
        default:
            return state;
    }
};
  export default KttSelectedTopicReducer