import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as dailyTaskApi from '../apis/dailyTask';
import { DailyTaskAction } from '../actions';
import { toast } from 'react-toastify';
import { FlagHelper } from '../helpers/FlagHelper';

export function* watcherDailyTask() {
	yield takeLatest(types.GET_DAILY_TASK_REQUEST, workerGetDailyTask);
	yield takeLatest(types.ADD_DAILY_TASK_REQUEST, workerAddDailyTask);
	yield takeLatest(types.DELETE_DAILY_TASK_REQUEST, workerDeleteDailyTask);
	yield takeLatest(types.GET_IS_ADDED_PLAN, workerGetIsAddedPlan);
}

function getDailyTask() {
	return dailyTaskApi.getDailyTask();
}
function* workerGetDailyTask(action) {
	try {
		const response = yield call(getDailyTask, action.data);
		yield put(DailyTaskAction.getDailyTaskSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(DailyTaskAction.getDailyTaskFailure());
	}
}

function addDailyTask(data) {
	FlagHelper.set('Tasks');
	return dailyTaskApi.addDailyTask(data);
}
function* workerAddDailyTask(action) {
	try {
		const response = yield call(addDailyTask, action.data);
		yield put(DailyTaskAction.addDailyTaskSuccess(response.data));
		yield put(DailyTaskAction.getDailyTask());
		if (response.data.ResponseData === 0) {
			toast.warn(
				'En iyi plan uygulanan plandır. Görevlerime en fazla 10 tane konu ekleyebilirsin. Şimdi seçmiş olduğun konulardan çalışmaya başlayabilirsin.',
			);
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(DailyTaskAction.addDailyTaskFailure());
	}
}

function deleteDailyTask(id) {
	FlagHelper.set('Tasks');
	return dailyTaskApi.deleteDailyTask(id);
}
function* workerDeleteDailyTask(action) {
	try {
		const response = yield call(deleteDailyTask, action.id);
		yield put(DailyTaskAction.deleteDailyTaskSuccess(response.data));
		yield put(DailyTaskAction.getDailyTask());
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(DailyTaskAction.deleteDailyTaskFailure());
	}
}

function getIsAddedPlan(data) {
	return dailyTaskApi.getIsAddedPlan(data);
}

function* workerGetIsAddedPlan(action) {
	try {
		const response = yield call(getIsAddedPlan, action.data);
		yield put(DailyTaskAction.getIsAddedPlanSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(DailyTaskAction.getIsAddedPlanFailure());
	}
}
