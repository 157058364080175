import * as types from '../constants/actionTypes';

export const getDailyTask = () => {
	return {
		type: types.GET_DAILY_TASK_REQUEST,
	};
};
export const getDailyTaskSuccess = data => {
	return {
		type: types.GET_DAILY_TASK_SUCCESS,
		payLoad: data,
	};
};
export const getDailyTaskFailure = () => {
	return {
		type: types.GET_DAILY_TASK_FAILURE,
	};
};

export const addDailyTask = data => {
	return {
		type: types.ADD_DAILY_TASK_REQUEST,
		data,
	};
};
export const addDailyTaskSuccess = data => {
	return {
		type: types.ADD_DAILY_TASK_SUCCESS,
		payLoad: data,
	};
};
export const addDailyTaskFailure = () => {
	return {
		type: types.ADD_DAILY_TASK_FAILURE,
	};
};

export const deleteDailyTask = id => {
	return {
		type: types.DELETE_DAILY_TASK_REQUEST,
		id,
	};
};
export const deleteDailyTaskSuccess = data => {
	return {
		type: types.DELETE_DAILY_TASK_SUCCESS,
		payLoad: data,
	};
};
export const deleteDailyTaskFailure = () => {
	return {
		type: types.DELETE_DAILY_TASK_FAILURE,
	};
};

export const getIsAddedPlan = data => {
	return {
		type: types.GET_IS_ADDED_PLAN,
		data,
	};
};

export const getIsAddedPlanSuccess = data => {
	return {
		type: types.GET_IS_ADDED_PLAN_SUCCESS,
		payLoad: data,
	};
};

export const getIsAddedPlanFailure = () => {
	return {
		type: types.GET_IS_ADDED_PLAN_FAILURE,
	};
};
