import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import ExamCategoryType from '../../../enums/ExamCategoryType';
import { whatsAppInfo } from '../../../apis/dashboard';

// Remove the isTeacher variable
// const isTeacher = JSON.parse(localStorage.getItem('userInfo'))?.Role;

const userExamCategory = parseInt(localStorage.getItem('userExamCategory'));

const NavBar = ({ role, collapse, toggleLeftSidebar }) => {
	const [info, setInfo] = useState();
	const [studentName, setStudentName] = useState(null);
	const isFromSPHomeworks = localStorage.getItem('isFromSPHomeworks');
	const isFromSPHomeworkInfo = localStorage.getItem('isFromSPHomeworkInfo');
	const homeworkId = parseInt(localStorage.getItem('homeworkId'));
	const homeworkType = parseInt(localStorage.getItem('homeworkType'));

	const whatsappData = async () => {
		const res = await whatsAppInfo();
		if (res.status == 200) {
			setInfo(res.data.ResponseData);
		}
	};

	const getStudentInfo = async () => {
		const studentToken = await JSON.parse(localStorage.getItem('studentInfo'));
		setStudentName(studentToken);
	};

	const removeStudentInfo = () => {
		localStorage.removeItem('isFromSPHomeworks');
		localStorage.removeItem('isFromSPHomeworkInfo');
		localStorage.removeItem('homeworkId');
		localStorage.removeItem('homeworkType');
		localStorage.removeItem('studentInfo');
		localStorage.removeItem('studentToken');
		window.location.reload();
	};

	useEffect(() => {
		whatsappData();
		getStudentInfo();
	}, []);

	const textSplit = (text) => {
		if (collapse) {
			return text
				.split(' ')
				.map((word) => word.charAt(0))
				.join('');
		} else {
			return text;
		}
	};

	// Adjust navList to use role instead of isTeacher
	const navList = [
		{
			title: 'Anasayfa',
			id: 1,
			icon: 'anasayfa.svg',
			link: '/',
			hasChild: false,
		},
		{
			title: 'Dersler',
			id: 3,
			icon: 'lessons.svg',
			link: '/dersler',
			hasChild: false,
		},
		{
			title: 'Çıktı Teste Git',
			id: 22,
			icon: 'pdfCode.svg',
			link: '/pdf-kod',
			hasChild: false,
		},
		{
			title: 'Testler',
			id: 2,
			icon: 'tests.svg',
			link: '/testlerim',
			hasChild: false,
		},
		{
			title: 'Denemeler',
			id: 4,
			icon: 'trailExam.svg',
			link: '/denemeler',
			hasChild: false,
		},
		{
			title: 'Canlı Dersler',
			id: 5,
			icon: 'onlineLecture.svg',
			link: '/onlineLecture',
			hasChild: false,
		},
		{
			title: 'Kurumsal Kodunu Gir',
			id: 21,
			icon: 'corporate.svg',
			link: '/kurumsal-kod',
			hasChild: false,
		},
		{
			title: 'İstatistik',
			id: 6,
			icon: 'statistics.svg',
			link: '/statistics',
			hasChild: false,
		},
		{
			title: 'Plan',
			id: 7,
			icon: 'plan.svg',
			link: '/gorevlerim',
			hasChild: false,
		},
		{
			title: 'Çekilişler',
			id: 8,
			icon: 'lottery.svg',
			link: '/cekilisler',
			hasChild: false,
		},
		{
			title: 'Pakodemy Forum',
			id: 9,
			icon: 'forum.svg',
			click: 'forum',
			link: '/digerleri-ne-yapiyor',
			hasChild: false,
		},
		{
			title: 'Favori Yayınevleri',
			id: 10,
			icon: 'publishers.svg',
			link: '/yayinci-tercihlerim',
			hasChild: false,
		},
		{
			title: 'Diğerleri Ne Yapıyor',
			id: 11,
			icon: 'whatOthersDo.svg',
			link: '/digerleri-ne-yapiyor',
			hasChild: false,
		},
		{
			title: 'Profilim',
			id: 12,
			icon: 'profile.svg',
			link: '/profilim',
			hasChild: true,
			childs: [
				{ id: 1, title: 'Profil Bilgileri', link: '/profilim' },
				{ id: 2, title: 'Şifreni Değiştir', link: '/profilim/sifre-degistir' },
			],
		},
		{
			title: 'Üyelik Bilgilerim',
			id: 13,
			icon: 'uyelikBilgileri.svg',
			link: '/uyelik-bilgileri',
			hasChild: false,
		},
		{
			title: 'Arkadaşlarını Davet Et',
			id: 14,
			icon: 'davet.svg',
			link: '/arkadaslarini-davet-et-kazan',
			hasChild: false,
		},
		{
			title: 'Bize Geri Bildirim Ver',
			id: 15,
			icon: 'feedBack.svg',
			link: '',
			mailto: 'mailto:info@pakodemy.com',
			hasChild: false,
		},
		{
			title: 'Çıkış Yap',
			id: 16,
			icon: 'logOut.svg',
			link: '/logout',
			hasChild: false,
		},
	];
	if (role === 'Teachers') {
		navList.splice(
			1,
			0,
			{
				title: 'ÖP Ödevler',
				id: 24,
				icon: 'spFilter.svg',
				link: '/schoolPanel/homeworks',
				hasChild: false,
			},
			{
				title: 'ÖP Filitre Ekle/Çıkar',
				id: 17,
				icon: 'spFilter.svg',
				link: '/schoolPanel',
				hasChild: false,
			},
			{
				title: 'ÖP Dersler',
				id: 18,
				icon: 'spLecture.svg',
				link: '/schoolPanel/lectures',
				hasChild: false,
			},
			{
				title: 'ÖP İstatistik',
				id: 19,
				icon: 'spStatistics.svg',
				link: '/schoolPanel/statistics',
				hasChild: false,
			},
		);
	}

	if (userExamCategory === ExamCategoryType.YKS) {
		navList.splice(7, 0, {
			title: 'Tercih Simulatörü',
			id: 20,
			icon: 'tercihSimulatoru.svg',
			link: '/universities',
			hasChild: false,
		});
		navList.splice(5, 0, {
			title: 'Öğrenci Koçluğu',
			id: 23,
			icon: 'womanInOnlineMeeting.svg',
			link: '/coaching',
			hasChild: false,
		});
	}

	const number = info?.WhatsAppNumber;

	return (
		<nav className="mt-4" style={{ marginBottom: 25 }}>
			{studentName ? (
				<div
					onClick={() => (collapse ? toggleLeftSidebar() : {})}
					style={{ backgroundColor: '#ee5c42', padding: 10 }}
					className={`font-semibold text-lg rounded-lg flex justify-between mb-1 ${
						collapse ? 'cursor-pointer' : 'cursor-default'
					}`}
				>
					<p className="text-white">{textSplit(studentName.name + ' ' + studentName.surname)}</p>
					{!collapse ? (
						<button className="text-white" onClick={removeStudentInfo}>
							<Link
								to={isFromSPHomeworks ? '/schoolPanel/homeworks' : isFromSPHomeworkInfo ? `/schoolPanel/homeworkInfo/${homeworkId}/${homeworkType}` : '/schoolPanel/statistics'}>
								<img className="h-6" src="/icons/KttLevels/remove_student.svg" alt="" />
							</Link>
						</button>
					) : null}
				</div>
			) : null}

			<ul>
				{navList.map((item, key) => {
					return (
						<li className="mb-1 font-semibold" key={item.id}>
							{item.click ? (
								<NavLink
									exact
									onClick={() => {
										const userExamCategory = parseInt(localStorage.getItem('userExamCategory'));
										const u = userExamCategory === ExamCategoryType.LGS ? 'lgs/' : userExamCategory === ExamCategoryType.YKS ? '' : ''; //todo: KPSS
										let forumUrl = `forum.pakodemy.com/${u}`;
										window.open(
											`//${forumUrl}`,
											'_blank', // <- This is what makes it open in a new window.
										);
									}}
									to={''}
									className={`
								${
										collapse
											? 'flex items-center justify-center bg-white h-14 py-5 px-4 rounded-lg text-gray-500 hover:bg-blue-100'
											: 'flex items-center  bg-white h-14 py-5 px-4 rounded-lg text-gray-500 hover:bg-blue-100'
									}
								`}
								>
									<img className="mr-2" src={`/icons/newNavBar/${item.icon}`} alt="Pakodemy" />
									{!collapse ? <span className="text-sm nav-link">{item.title}</span> : null}
								</NavLink>
							) : item.mailto ? (
								<NavLink
									exact
									onClick={() => {
										window.open(
											`https://web.whatsapp.com/send?phone=${number?.replace('+', '')}&text=Merhaba&app_absent=0`,
											'_blank',
										);
									}}
									to={''}
									className={`
								${
										collapse
											? 'flex items-center justify-center bg-white h-14 py-5 px-4 rounded-lg text-gray-500 hover:bg-blue-100'
											: 'flex items-center  bg-white h-14 py-5 px-4 rounded-lg text-gray-500 hover:bg-blue-100'
									}
								`}
								>
									<img className="mr-2" src={`/icons/newNavBar/${item.icon}`} alt="Pakodemy" />
									{!collapse ? <span className="text-sm nav-link">{item.title}</span> : null}
								</NavLink>
							) : (
								<NavLink
									exact
									to={item.link}
									style={{ minHeight: '76px' }}
									className={`
								${
										collapse
											? 'flex items-center justify-center bg-white py-5 px-4 rounded-lg text-gray-500 hover:bg-blue-100'
											: 'flex items-center  bg-white h-14 py-5 px-4 rounded-lg text-gray-500 hover:bg-blue-100'
									}
								`}
									activeClassName="bg-blue-800 hover:bg-blue-800 text-blue-100"
								>
									<img className="mr-2" src={`/icons/newNavBar/${item.icon}`} alt="Pakodemy" />
									{!collapse ? <span className="text-sm nav-link">{item.title}</span> : null}
								</NavLink>
							)}
							{/* {!collapse && item.hasChild && (
								<>
									<ul className={`bg-white ${profileChildes ? 'block' : 'hidden'} px-4`}>
										{item.childs.map(i => {
											return (
												<li key={i.id} className='pl-4 py-2'>
													<NavLink
														exact
														to={i.link}
														className='flex items-center justify-start text-gray-400 text-sm'
														activeClassName='text-blue-800'
													>
														{i.title}
													</NavLink>
												</li>
											);
										})}
									</ul>
								</>
							)} */}
						</li>
					);
				})}
			</ul>
			<p style={{ fontSize: 10, color: '#c0c0c0', textAlign: 'center' }}>Versiyon : {process.env.REACT_APP_VERSION}</p>
			<br></br>
			<p style={{ fontSize: 10, color: '#c0c0c0', textAlign: 'center' }}>
				Pakodemy ® tescilli ticari markadır. Copyright © 2020 TOS Analytics Yapay Zeka A.Ş. Tüm hakları saklıdır.
			</p>
		</nav>
	);
};

export default NavBar;