import * as types from '../constants/actionTypes';
const initialState = {
	collapse: false,
};

const NavBar = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.HANDLE_COLLAPSE:
			console.log("reducerNavBar",payLoad)
			return {
				...state,
				collapse: payLoad,
			};

		default:
			return state;
	}
};

export default NavBar;
