import { apiCaller } from '../helpers/apiCaller';
import examCategory from '../mobileAssets/enums/ExamCategory';

const getTrailExams = (id) => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/trialExam/get?examCategoryId=${id}`);
};
const getTrailDetail= (id,data) => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/trialExam/detailv3`,{TrialExamId:id,ExamCategoryId:data});
};
const getOptions= () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/TrackingExam/options`);
};
const saveOptions= (params) => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/TrackingExam/options`,params);
};
export default getTrailExams;
export { getTrailExams ,getTrailDetail,getOptions,saveOptions};

const trialExam =`${process.env.REACT_APP_API_URL}/api/trialExam/`
const createTest =`${process.env.REACT_APP_API_URL}/api/testsection/`

export const getNewTrialExams = async () => {
  return apiCaller({needToken:true}).get(trialExam);
};

export const getTrialExamsDetail = async (request) => {
  const url = `${trialExam}detail`;
  return apiCaller({needToken:true}).post(url, request);
};

export const getUserRank = async (request) => {
  const url = `${trialExam}ranking`;
  return apiCaller({needToken:true}).post(url, request);
};

export const getResultsLgs = async (request) => {
  const url = `${trialExam}lgs-ranking`;
  return apiCaller({needToken:true}).post(url, request);
};

export const getPartialTrialExams = async (request) => {
  const url = `${trialExam}getpartialtrialexams`;
  return apiCaller({needToken:true}).post(url, request);
}

export const getGeneralTrialExam = async (request) => {
  const url = `${trialExam}getgeneraltrialexam`;
  return apiCaller({needToken:true}).post(url, request);
}

export const getOnlineTrialExamBanner = async (request1, request2) => {
  return apiCaller({ needToken: true }).get(`${trialExam}getOnlineTrialExamBanner?isDarkMode=${request1}&examCategoryId=${request2}`);
};

export const getAllLastTrialExam = async (request) => {
  const url = `${trialExam}getalllasttrialexam`;
  return apiCaller({needToken:true}).post(url, request);
}

export const createTrialExam = async (request) => {
  const url = `${createTest}createtestsectionv6`;
  return apiCaller({needToken:true}).post(url, request);
};

export const updateTrialExam = async (request) => {
  const url = `${createTest}updatetestsectionv2`;
  return apiCaller({needToken:true}).put(url, request);
};

export const getTrialExamSectionElement = async (request) => {
  const url = `${createTest}gettestsectionelementv2`;
  return apiCaller({needToken:true}).post(url, request);
};

export const createTrialExamSectionElement = async (request) => {
  const url = `${createTest}createtrialexamelement`;
   return apiCaller({needToken:true}).post(url, request);
};

export const getFinishedTrialExamSummary = async (request) => {
  const url = `${createTest}getfinishedtestsectionsummary`;
   return apiCaller({needToken:true}).post(url, request);
};
// export const getTrialExamSummary = async (request) => {
//   const url = `${createTest}gettestsectionsummary`;
//    return apiCaller({needToken:true}).post(url, request);
// };
export const checkFinishTrialExam = async (request) => {
  const url = `${trialExam}check-finish`;
   return apiCaller({needToken:true}).post(url, request);
};
export const finishTrialExam = async (request) => {
  const url = `${trialExam}finishv2`;
   return apiCaller({needToken:true}).post(url, request);
};

export const getTrialExamPoint = async (request) => {
  const url = `${trialExam}point`;
   return apiCaller({needToken:true}).post(url, request);
};

export const updateTestSectionElementV2 = async (request) => {
	const url = `${createTest}updatetestsectionelementv2`;
	return apiCaller({needToken:true}).post(url, request);
  };

export const trialExamPdf = async (request) => {
  const url = `${process.env.REACT_APP_API_URL}/api/printabletest/get-trialexampdf`;
  return apiCaller({ needToken: true }).post(url, request);
};
export const trialExamAnswerForm = async (request) => {
  const url = `${process.env.REACT_APP_API_URL}/api/printabletest/get-trialexamanswerformWeb`;
  return apiCaller({ needToken: true }).post(url, request);
};

export const getTrialExamOptions = async (request) => {
  const url = `${trialExam}gettrialexamoptions`;
   return apiCaller({needToken:true}).post(url, request);
};

export const getTrialExamRanking = async (request) => {
  const url = `${trialExam}gettrialexamranking`;
   return apiCaller({needToken:true}).post(url, request);
};

export const getTrialExamSummarySessions = async (request) => {
  const url = `${trialExam}gettrialexamsummarysessions`;
   return apiCaller({needToken:true}).post(url, request);
};

export const getTrialExamsRank = async (request) => {
  const url = `${trialExam}rank`;
   return apiCaller({needToken:true}).post(url, request);
};

export const getTrialExamDetail = async (request) => {
  const url = `${trialExam}gettrialexamdetail`;
   return apiCaller({needToken:true}).post(url, request);
}

export const getTrialExamSummary = async (request) => {
  const url = `${trialExam}gettrialexamsummaryweb`;
  return apiCaller({ needToken: true }).post(url, request);
};

export const getOnlineExamStatus = async (examCategoryId) => {
  const url = `${trialExam}getonlinetrialexamstatus?examCategoryId=${examCategoryId}`;
  return apiCaller({ needToken: true }).get(url);
}

export const getOnlineTrialExam = async (examCategoryId) => {
  const url = `${trialExam}onlinetrialexam?examCategoryId=${examCategoryId}`;
  return apiCaller({ needToken: true }).get(url);
}

export const getOnlineTrialExamSessions = async (request) => {
  const url = `${trialExam}getonlinetrialexamsessions`;
  return apiCaller({ needToken: true }).post(url, request);
}

export const setLockedOnlineTrialExam = async (request) => {
  const url = `${trialExam}setlockedonlinetrialexam`;
  return apiCaller({ needToken: true }).post(url, request);
}

export const getOnlineTrialExamPdfs = async (request) => {
  const url = `${process.env.REACT_APP_API_URL}/api/PrintableTest/getonlinetrialexampdfs`;
  return apiCaller({ needToken: true }).post(url, request);
}
