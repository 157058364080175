import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as BookApi from '../apis/book';
import * as UserStatistic from '../apis/userStatistic';
import { BookAction, UserStatisticAction } from '../actions';
import { toast } from 'react-toastify';
import ResponseStatus from '../mobileAssets/enums/ResponseStatus';

export function* watcherBook() {
	yield takeLatest(types.GET_NESTED_TITLES_REQUEST, workerGetNestedTitles);
	yield takeLatest(types.GET_COURSES, workerGetCourses);
	yield takeLatest(types.GET_SUB_COURSE_TOPICS, workerGetSubCourseTopics);
	yield takeLatest(types.CHECK_LECTURE_CONTENT, workerCheckLectureContent);
	yield takeLatest(types.GET_ACHIEVEMENTS, workerGetAchievements);
	yield takeLatest(types.GET_ACHIEVEMENT_USER_PROGRESS, workerGetAchievementUserProgress);
	yield takeLatest(types.UPDATE_ACHIEVEMENT_VIDEO_USER, workerUpdateAchievementVideoUser);
	yield takeLatest(types.ADD_ACHIEVEMENT_VIDEO_DURATION, workerAddAchievementVideoDuration);
	yield takeLatest(types.GET_ACHIEVEMENT_VIDEO_REWARD, workerGetAchievementVideoReward);
	yield takeLatest(types.GET_SUB_COURSES, workerGetSubCourses);
	yield takeLatest(types.GET_ACHIEVEMENT_REWARDED_GIFT, workerGetAchievementRewardedGift);
	yield takeLatest(types.GET_ACHIEVEMENT_TEST_DETAIL, workerGetAchievementTestDetail);
	yield takeLatest(types.GET_ACHIEVEMENT_TEST_INSTRUCTION, workerGetAchievementTestInstruction);
	yield takeLatest(types.GET_ACHIEVEMENT_REWARD, workerGetAchievementReward);
}

function getNestedTitles(sourceTypeId) {
	return BookApi.getNestedTitles(sourceTypeId);
}

function* workerGetNestedTitles(action) {
	try {
		const response = yield call(getNestedTitles, action.sourceTypeId);
		localStorage.setItem('courses', JSON.stringify(response.data));
		yield put(BookAction.getNestedTitlesSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getNestedTitlesFailure());
	}
}

function getCourses(examCategoryId) {
	return BookApi.getCourses(examCategoryId);
}

function* workerGetCourses(action) {
	try {
		const response = yield call(getCourses, action.examCategoryId);
		yield put(BookAction.getCoursesSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getCoursesFailure());
	}
}

function getSubCourseTopics(postData) {
	return BookApi.getSubCourseTopics(postData);
}

function* workerGetSubCourseTopics(action) {
	try {
		const response = yield call(getSubCourseTopics, action.postData);
		yield put(BookAction.getSubCourseTopicsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getSubCourseTopicsFailure());
	}
}

function checkLectureContent(id) {
	return BookApi.checkLectureContentv2(id);
}

function* workerCheckLectureContent(action) {
	try {
		const response = yield call(checkLectureContent, action.id);
		yield put(BookAction.checkLectureContentSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.checkLectureContentFailure());
	}
}

function getAchievements(id) {
	return BookApi.getAchievements(id);
}

function* workerGetAchievements(action) {
	try {
		const response = yield call(getAchievements, action.id);
		yield put(BookAction.getAchievementsSuccess(response.data?.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getAchievementsFailure());
	}
}

function getAchievementUserProgress(postData) {
	return BookApi.getAchievementUserProgress(postData);
}

function* workerGetAchievementUserProgress(action) {
	try {
		const response = yield call(getAchievementUserProgress, action.postData);
		yield put(BookAction.getAchievementUserProgressSuccess(response.data?.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getAchievementUserProgressFailure());
	}
}

function updateAchievementVideoUser(postData) {
	return BookApi.updateAchievementVideoUser(postData);
}

function* workerUpdateAchievementVideoUser(action) {
	try {
		const response = yield call(updateAchievementVideoUser, action.postData);
		yield put(BookAction.updateAchievementVideoUserSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.updateAchievementVideoUserFailure());
	}
}

function addAchievementVideoDuration(postData) {
	return BookApi.addAchievementVideoDuration(postData);
}

function* workerAddAchievementVideoDuration(action) {
	try {
		const response = yield call(addAchievementVideoDuration, action.postData);
		yield put(BookAction.addAchievementVideoDurationSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.addAchievementVideoDurationFailure());
	}
}

function getAchievementVideoReward(postData) {
	return BookApi.getAchievementVideoReward(postData);
}

function* workerGetAchievementVideoReward(action) {
	try {
		const response = yield call(getAchievementVideoReward, action.postData);
		yield put(BookAction.getAchievementVideoRewardSuccess(response.data));
		if (response.data.ResponseStatus !== 1) {
			toast.warn(response.data.ResponseMessage);
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		} else {
			toast.error('Beklenmedik bir hata oluştu');
		}
		yield put(BookAction.getAchievementVideoRewardFailure());
	}
}

function getSubCourses(examCategoryId) {
	return BookApi.getSubCourses(examCategoryId);
}

function* workerGetSubCourses(action) {
	try {
		const response = yield call(getSubCourses, action.payLoad);
		yield put(BookAction.getSubCoursesSuccess(response.data?.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getSubCoursesFailure());
	}
}

function getAchievementRewardedGift(subCourseTopicUniqueId) {
	return BookApi.getAchievementRewardedGift(subCourseTopicUniqueId);
}

function* workerGetAchievementRewardedGift(action) {
	try {
		const response = yield call(getAchievementRewardedGift, action.subCourseTopicUniqueId);
		yield put(BookAction.getAchievementRewardedGiftSuccess(response.data?.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getAchievementRewardedGiftFailure());
	}
}

function getAchievementTestInstruction(postData) {
	return BookApi.getAchievementTestInstruction(postData);
}

function* workerGetAchievementTestInstruction(action) {
	try {
		const response = yield call(getAchievementTestInstruction, action.postData);
		yield put(BookAction.getAchievementTestInstructionSuccess(response.data?.ResponseData));
		yield put(BookAction.setShowTestInstructionModal(true));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getAchievementTestInstructionFailure());
	}
}

function getElementCount(postData) {
	return UserStatistic.getElementCount(postData);
}

function* workerGetAchievementTestDetail(action) {
	sessionStorage.removeItem('achievementsTestMarkTypeIds');
	sessionStorage.removeItem('achievementsTestElementStatuses');
	const actionGetElementCount = action.requests.actionGetElementCount;
	const actionGetAchievementTestInstruction = action.requests.actionGetAchievementTestInstruction;
	try {
		const [responseAchievementTestInstruction, responseElementCount] = yield all([
			call(getAchievementTestInstruction, actionGetAchievementTestInstruction),
			call(getElementCount, actionGetElementCount),
		]);
		yield put(BookAction.getAchievementTestInstructionSuccess(responseAchievementTestInstruction.data?.ResponseData));
		yield put(UserStatisticAction.getElementCountSuccess(responseElementCount.data?.ResponseData));
		yield put(BookAction.setShowAchievementDetailModal(true));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error("Beklenmedik bir hata oluştu");
		}
		yield put(BookAction.getAchievementTestInstructionFailure());
		yield put(UserStatisticAction.getElementCountFailure());
	}
}


function getAchievementReward(postData) {
	return BookApi.getAchievementReward(postData);
}

function* workerGetAchievementReward(action) {
	try {
		const response = yield call(getAchievementReward, action.postData.request)
		if (response.data.ResponseStatus === ResponseStatus.Success) {
			yield put(BookAction.getAchievementRewardSuccess(response.data));
			yield put(BookAction.setShowAchievementRewardAnimation(true));
		} else {
			const responseTestInstruction = yield call(getAchievementTestInstruction, action.postData.testInstructionReq);
			yield put(BookAction.getAchievementTestInstructionSuccess(responseTestInstruction.data?.ResponseData));
			yield put(BookAction.setShowTestInstructionModal(true));
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error("Beklenmedik bir hata oluştu");
		}
		yield put(BookAction.getAchievementRewardFailure());
	}
}