import TestSectionElementStatus from '../../../enums/TestSectionElementStatus';


export const shouldRenderNextButton = (
	achievementQuestionData,
	achievementQuestionIndex,
	getElementSummaryDataSummary,
	isAnswerVisible,
	userAnswer,
	fromSummary,
	lastTrueElementId,
	lastThreeAchievementQuestionData,
) => {
	const isFullTest = lastThreeAchievementQuestionData?.length === 3 &&
		lastThreeAchievementQuestionData.every((x) => x === TestSectionElementStatus.True);
	const isLastElementMatching = fromSummary
		? achievementQuestionIndex === getElementSummaryDataSummary?.length - 1
		: achievementQuestionData.TestSectionElementId === lastTrueElementId;

	const isMainConditionMet = !(isFullTest ? isLastElementMatching : false);

	const isLastElementOfSummary = fromSummary && achievementQuestionIndex === getElementSummaryDataSummary?.length - 1;

	return (userAnswer || isAnswerVisible) && isMainConditionMet && !isLastElementOfSummary;
};