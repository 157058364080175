import styled from 'styled-components';

export const Header = styled.div`
	position: 'fixed';
	top: 0;
	z-index: 50;
	width: 100%;
	.arrow {
		background-color: #fff;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		cursor: pointer;
		background-color: #e2e8f0;

		&:hover {
			background-color: #f8f8f8;
			transition: 0.2s;
		}
		.arrow-left {
			transform: rotate(180deg);
		}
		.arrow-right {
			transform: rotate(0deg);
		}
	}
	.crumb-link {
		color: #1e42d3;
		margin-right: 4px;
		&:first-child {
			color: #999999;
		}
	}
`;
