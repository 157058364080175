import React from 'react';
import { Logo } from './style';

export default ({ toggleLeftSidebar, collapse }) => {
	return (
		<Logo
			className={`h-16 border-b-2  border-gray-100 pointer ${collapse ? 'pl-5 lg:pl-8' : 'pl-8'}`}
			onClick={toggleLeftSidebar}
		>
			<img alt="Pakdoemy" src='/assets/logo.svg' className={`w-32 ${collapse ? 'hidden' : 'block'}`} />
			<img alt="Pakdoemy" src='/icons/small_logo.svg' className={`${collapse ? 'block' : 'hidden'}`} />
		</Logo>
	);
};
