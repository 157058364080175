import { apiCaller } from '../helpers/apiCaller';

export const getTestSectionsV4 = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/gettestsectionsv4`,
		data,
	);
};

export const updateTestSectionElement = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/updatetestsectionelementWeb`,
		data,
	);
};
export const updateTestSectionElementV2 = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/updatetestsectionelementWebv2`,
		data,
	);
};
export const finishSectionV2 = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/TestSection/finishsectionv3`, data);
};

export const createTestSectionV6 = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/createtestsectionv6`,
		data,
	);
};

export const createTestSectionElementV2 = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/createtestsectionelementv3`,
		data,
	);
};

export const getTestSectionElement = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/gettestsectionelementv2`,
		data,
	);
};

export const getFinishedTestSectionSummary = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/getfinishedtestsectionsummaryv3`,
		data,
	);
};

export const getTestSectionSubCourseSummary = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/gettestsectionsubcoursesummary`,
		data,
	);
};

export const getTestSectionCourseSummary = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/gettestsectioncoursesummary`,
		data,
	);
};

export const getTestSectionSummary = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/gettestsectionsummaryv2`,
		data,
	);
};
export const getTestSectionSummaryV3 = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/gettestsectionsummaryv3`,
		data,
	);
};
export const getTestSectionSummaryv1 = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/gettestsectionsummary`,
		data,
	);
};

export const updateTestSectionV2 = data => {
	return apiCaller({ needToken: true }).put(
		`${process.env.REACT_APP_API_URL}/api/TestSection/updatetestsectionv2`,
		data,
	);
};

export const deleteTestSection = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/deletetestsection`,
		data,
	);
};
export const getTestSectionById = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/gettestsectionbyid`,
		data,
	);
};

export const getSummary = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/successLevel/getSummary`, data);
};
export const chatGptInfo = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/ChatGpt/getprompt`, data);
};
export const getTestSectionMessage = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/ChatGpt/getTestSectionMessagev2`,
		data,
	);
};
export const saveGptResponse = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/ChatGpt/saveresponse`, data);
};

export const updateReaction = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/ChatGpt/updatereaction`, data);
};

export const updateUserMessage = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/ChatGpt/updateusermessage`, data);
};

export const rateChoiceMessage = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/ChatGpt/ratechoicemessage`);
};

export const getUserScoreNet = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/TestSection/getuserscorenet`, data);
};

export const getAllUsersScoreNet = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/getalluserscorenet`,
		data,
	);
};

export const getTestSectionElements = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/gettestsectionelements`,
		data,
	);
};

export const getLastUnfinishedTest = subCourseTopicUniqueId => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/lastunfinishedtest`,
		subCourseTopicUniqueId,
	);
};

export const createAchievementElement = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/createachievementelement`,
		data,
	);
};

export const getQuestion = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/getquestion`,
		data,
	);
};

export const getLastThreeAchievementQuestion = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/getlastthreeachievementquestion`,
		data,
	);
};

export const getTestSectionElementById = data => {
	return apiCaller({ needToken: true }).post(
		`${process.env.REACT_APP_API_URL}/api/TestSection/gettestsectionelementbyid`,
		data,
	);
};