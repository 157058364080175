import * as React from "react";
const PassDirect = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		{...props}
	>
		<path
			opacity={0.1}
			d="M16 3.00049C8.83187 3.00049 3 8.83236 3 16.0005C3 23.1686 8.83187 29.0005 16 29.0005C23.1681 29.0005 29 23.1686 29 16.0005C29 8.83236 23.1681 3.00049 16 3.00049Z"
			fill="#EE5942"
		/>
		<path d="M20 12.0005L12 20.0005" stroke="#EE5942" strokeLinecap="round" />
		<path d="M20 20.0005L12 12.0005" stroke="#EE5942" strokeLinecap="round" />
	</svg>
);
export default PassDirect;
