import * as types from '../constants/actionTypes';

export const getPrintableTestFile = (data) => {
	return {
		type: types.GET_PRINTABLE_TEST_FILE,
		payLoad: data,
	};
};

export const getPrintableTestFileSuccess = data => {
	return {
		type: types.GET_PRINTABLE_TEST_FILE_SUCCESS,
		payLoad: data,
	};
};

export const getPrintableTestFileFailure = () => {
	return {
		type: types.GET_PRINTABLE_TEST_FILE_FAILURE,
	};
};

export const getParameters = data => {
	return {
		type: types.GET_PARAMETERS,
		payLoad: data,
	};
}

export const getParametersSuccess = data => {
	return {
		type: types.GET_PARAMETERS_SUCCESS,
		payLoad: data,
	};
}

export const getParametersFailure = data => {
	return {
		type: types.GET_PARAMETERS_FAILURE,
		payLoad: data,
	};
}
export const setParameters = data => {
	return {
		type: types.SET_PARAMETERS,
		payLoad: data,
	};
}

export const getPrintableTestFileOpenNewTab = (data) => {
	return {
		type: types.GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB,
		payLoad: data,
	};
}

export const getPrintableTestFileOpenNewTabSuccess = data => {
	return {
		type: types.GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB_SUCCESS,
		payLoad: data,
	};
}

export const getPrintableTestFileOpenNewTabFailure = () => {
	return {
		type: types.GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB_FAILURE,
	};
}


export const clearPdfDataForHomeworks = () => {
	return {
		type: types.CLEAR_PDF_DATA_FOR_HOMEWORKS,
	};
}