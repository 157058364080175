import * as types from '../constants/actionTypes';
const initialState = {
	login: {
		loading: false,
		data: [],
	},
	register: {
		loading: false,
		data: [],
	},
	verifyCode: {
		loading: false,
		data: [],
		showPopup: false,
	},
	updateEmail: {
		loading: false,
		data: [],
		showPopup: false,
	},
	activeCredits: [],
	lottery: 0,
	coupon: 0,
};

const Account = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.LOGIN_REQUEST:
			return { ...state, login: { ...state.login, loading: true } };
		case types.LOGIN_SUCCESS:
			return { ...state, login: { data: payLoad, loading: false } };
		case types.LOGIN_FAILURE:
			return { ...state, login: { ...state.login, loading: false } };

		case types.REGISTER_REQUEST:
			return { ...state, register: { ...state.register, loading: true } };
		case types.REGISTER_SUCCESS:
			return { ...state, register: { data: payLoad, loading: false } };
		case types.REGISTER_FAILURE:
			return { ...state, register: { ...state.register, loading: false } };

		case types.GET_VERIFY_CODE_REQUEST:
			return {
				...state,
				verifyCode: { data: [], loading: true, showPopup: false },
			};
		case types.GET_VERIFY_CODE_SUCCESS:
			return {
				...state,
				verifyCode: { data: payLoad, loading: false, showPopup: true },
			};
		case types.GET_VERIFY_CODE_FAILURE:
			return {
				...state,
				verifyCode: { data: [], loading: false, showPopup: false },
			};

		case types.UPDATE_EMAIL_REQUEST:
			return { ...state, updateEmail: { data: [], loading: true, showPopup: false } };
		case types.UPDATE_EMAIL_SUCCESS:
			return { ...state, updateEmail: { data: payLoad, loading: false, showPopup: true } };
		case types.UPDATE_EMAIL_FAILURE:
			return { ...state, updateEmail: { data: [], loading: false, showPopup: false } };

		case types.SET_COUPON:
			return { ...state, coupon: payLoad };
		case types.SET_LOTTERY:
			return { ...state, lottery: payLoad };

		case types.SET_ACTIVE_CREDITS:
			return { ...state, activeCredits: payLoad };

		default:
			return state;
	}
};

export default Account;
