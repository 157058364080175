import { apiCaller } from '../helpers/apiCaller';

export const getActiveCredits = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/Credit/getActiveCreditAsync`);
};

export const getLottery = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/Lottery/detailv2`);
};
export const getLotteryProgress = async (lotteryId) => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/lottery/corporatelotteryprogress`, lotteryId);
};
export const getLotteryDefinitions = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/Lottery/definitions`);
};
export const getLotteryCompleted = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/Lottery/completed`);
};
export const getCoporateLotteryCompleted = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/Lottery/completed-corporate`);
};
export const getCoupon = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/Coupon/detail`);
};
export const getCouponDefinitions = () => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/Coupon/definitions`);
};
export const updateCoupon = async () => {
	let adType;
	const rnd = Math.floor(Math.random() * 4) + 1;
	if (rnd === 3) adType = 'RewardAdClicked';
	else adType = 'RewardAd';
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/Coupon/update`, {
		ConfigTypeName: adType,
	});
};
export const corporateConfig = (data) =>{
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/credit/getcorporateconfig?codeEntry=${data}`)
}
export const izycoPayment = async (productData) => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/credit/iyzicoinitializecheckoutform`, productData);
};