import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as Content from '../apis/content';
import { ContentAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherContent() {
	yield takeLatest(types.GET_PROMOTION_BANNER, workerBanner);
}

function getAllBanners(data) {
	return Content.getPromotionBanner(data);
}
function* workerBanner(action) {
	try {
		const response = yield call(getAllBanners, action.payLoad);
		yield put(ContentAction.getPromotionBannerSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(ContentAction.getPromotionBannerFailure());
	}
}