import * as types from '../constants/actionTypes';

export const getAllCoachesAction = () => {
	return {
		type: types.GET_ALL_COACHES,
	};
};

export const getAllCoachesActionSuccess = data => {
	return {
		type: types.GET_ALL_COACHES_SUCCESS,
		payLoad: data,
	};
};

export const getAllCoachesActionFailure = () => {
	return {
		type: types.GET_ALL_COACHES_FAILURE,
	};
};

export const getUserCanTakeCoachAction = () => ({
	type: types.GET_USER_CAN_TAKE_COACH,
});

export const getUserCanTakeCoachActionSuccess = data => ({
	type: types.GET_USER_CAN_TAKE_COACH_SUCCESS,
	payLoad: data,
});

export const getUserCanTakeCoachActionFailure = data => ({
	type: types.GET_USER_CAN_TAKE_COACH_FAILURE,
	payLoad: data,
});

export const getUserHasCoachAction = () => ({
	type: types.GET_USER_HAS_COACH,
});

export const getUserHasCoachActionSuccess = data => ({
	type: types.GET_USER_HAS_COACH_SUCCESS,
	payLoad: data,
});

export const getUserHasCoachActionFailure = () => ({
	type: types.GET_USER_HAS_COACH_FAILURE,
});

export const takeSeatAction = data => ({
	type: types.TAKE_SEAT,
	data,
});

export const takeSeatActionSuccess = data => ({
	type: types.TAKE_SEAT_SUCCESS,
	payLoad: data,
});

export const takeSeatActionFailure = () => ({
	type: types.TAKE_SEAT_FAILURE,
});

export const handleBottomContainer = data => ({
	type: types.HANDLE_BOTTOM_CONTAINER,
	payLoad: data,
});

export const getTutorialAction = () => ({
	type: types.GET_TUTORIAL,
});

export const getTutorialActionSuccess = data => ({
	type: types.GET_TUTORIAL_SUCCESS,
	payLoad: data,
});

export const getTutorialActionFailure = () => ({
	type: types.GET_TUTORIAL_FAILURE,
});