import * as React from 'react';

const TakesTime = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		{...props}
	>
		<path
			opacity={0.1}
			d="M16.0001 29.3338C23.3334 29.3338 29.3334 23.3338 29.3334 16.0004C29.3334 8.66711 23.3334 2.66711 16.0001 2.66711C8.66675 2.66711 2.66675 8.66711 2.66675 16.0004C2.66675 23.3338 8.66675 29.3338 16.0001 29.3338Z"
			fill="#7B5AFF"
		/>
		<path
			d="M15.788 8.05396C15.47 8.22004 15.4452 8.67234 15.742 8.86668C15.8092 8.91262 15.9823 8.94089 16.417 8.97269C16.7385 8.99743 17.1908 9.0575 17.4276 9.10697C19.8587 9.59813 21.8763 11.3791 22.6749 13.7395C23.4346 15.9798 23.0389 18.3967 21.6007 20.3084C21.3498 20.6441 20.894 21.1246 20.5583 21.4038C20.4064 21.531 20.2721 21.6335 20.2615 21.6335C20.2509 21.6335 20.2403 21.372 20.2403 21.0504C20.2403 20.3897 20.212 20.266 20.0212 20.1494C19.7739 19.9974 19.4488 20.0999 19.3428 20.3614C19.2968 20.4815 19.2862 20.6759 19.2862 21.5734C19.2862 22.5487 19.2933 22.6511 19.3534 22.7642C19.4664 22.9762 19.5265 22.9939 20.7703 23.1317C21.4028 23.2024 21.9823 23.2589 22.0601 23.2589C22.265 23.2589 22.4099 23.1564 22.4841 22.9621C22.5442 22.8101 22.5442 22.7925 22.4876 22.6405C22.3922 22.3967 22.2898 22.3543 21.6466 22.2801L21.0954 22.22L21.5371 21.796C21.7774 21.5663 22.0777 21.2448 22.2049 21.0893C22.9823 20.1246 23.5583 18.9338 23.8233 17.7465C23.9647 17.0964 24 16.7501 24 16.0151C24 15.2801 23.9647 14.9338 23.8233 14.2836C23.6855 13.6688 23.4806 13.0858 23.1908 12.4992C22.7597 11.6158 22.2686 10.9409 21.5795 10.273C20.1519 8.88789 18.3498 8.1105 16.3534 8.01156C15.9682 7.99036 15.8975 7.99743 15.788 8.05396Z"
			fill="#7B5AFF"
		/>
		<path
			d="M9.72085 8.84187C9.42403 9.02208 9.43816 9.47791 9.74558 9.63692C9.80565 9.66872 10.0848 9.71819 10.3781 9.75L10.9046 9.80653L10.4664 10.2306C9.73145 10.9373 9.25088 11.5945 8.80919 12.4991C8.42403 13.2871 8.16961 14.1175 8.0424 15.0079C7.98587 15.4178 7.98587 16.6122 8.0424 17.0221C8.30389 18.8666 9.12367 20.4991 10.4205 21.7571C11.8445 23.1387 13.6502 23.9196 15.6466 24.0185C16.0318 24.0397 16.1025 24.0327 16.212 23.9761C16.53 23.8101 16.5548 23.3578 16.258 23.1634C16.1908 23.1175 16.0177 23.0928 15.5866 23.0574C14.9187 23.0044 14.4488 22.9196 13.8975 22.7465C11.4523 21.9867 9.61131 19.9549 9.09187 17.439C8.66078 15.3613 9.19081 13.2094 10.5477 11.5274C10.841 11.167 11.6502 10.3966 11.7385 10.3966C11.7491 10.3966 11.7597 10.6581 11.7597 10.9797C11.7597 11.6405 11.788 11.7641 11.9788 11.8807C12.2261 12.0327 12.5512 11.9302 12.6572 11.6687C12.7032 11.5486 12.7138 11.3542 12.7138 10.4567C12.7138 9.48145 12.7067 9.37897 12.6466 9.2659C12.5336 9.05035 12.4735 9.03621 11.2297 8.89841C10.5972 8.82773 10.0247 8.7712 9.9576 8.7712C9.89046 8.7712 9.78445 8.803 9.72085 8.84187Z"
			fill="#7B5AFF"
		/>
		<path
			d="M15.0812 10.8207C12.5476 11.2836 10.7349 13.4496 10.7349 16.015C10.7349 18.5486 12.4981 20.6935 14.9999 21.1988C15.4769 21.2942 16.6041 21.2871 17.067 21.1846C18.7596 20.803 20.1236 19.7076 20.8055 18.1776C21.1589 17.3931 21.2861 16.7217 21.2543 15.8207C21.226 14.9797 21.0812 14.4037 20.7137 13.6652C20.0034 12.2341 18.6783 11.2058 17.0847 10.8525C16.6006 10.7429 15.5865 10.7288 15.0812 10.8207ZM16.1766 13.7041C16.2578 13.7394 16.3462 13.8065 16.3886 13.8772C16.4557 13.9832 16.4592 14.0504 16.4592 14.9055V15.8171L17.1978 16.5627C17.6677 17.0327 17.9469 17.3401 17.961 17.4037C18.0458 17.7394 17.7243 18.061 17.3886 17.9761C17.325 17.962 16.9822 17.6475 16.4168 17.0822L15.5405 16.2094V15.0998C15.5405 13.8525 15.5405 13.8489 15.8055 13.7182C15.9751 13.6334 15.9963 13.6334 16.1766 13.7041Z"
			fill="#7B5AFF"
		/>
	</svg>
);
export default TakesTime;
