import React, { Suspense, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Sidebar from './sidebar';
import UsefulInformation from '../../components/asideWidgets/usefulInformation';
import Header from './component/header';
import { useSessionStorage } from '../../helpers/sessionStorage';
import './index.css';
const Dashboard =  ({ Component, route, breadCrumbs,navBarCollapseData,role }) => {
	const { pathname } = route.location;
	// const [rightSidebarHide, setRightSidebarHide] = useSessionStorage('rightSidebarHide', false);
	const [collapse, setCollapse] = useSessionStorage('collapse', false);
	// const screenWidth = window.screen.width;
	const toggleLeftSidebar = () => {
		if (!(pathname === '/' || pathname.includes('/pdf-kod'))) {
			setCollapse(!collapse);
		}
	};
	useEffect(()=>{
		if(route.location.search.includes("deneme-test-sonucu")){
			setCollapse(navBarCollapseData)
		} else if (route.location.pathname.includes("deneme-test-sonucu") || route.location.pathname.includes("deneme-analizi") ||  route.location.pathname.includes("topics")) {
			setCollapse(navBarCollapseData)
		} else {
			setCollapse(false)
		}
	},[navBarCollapseData])
	return (
		<main className='font-body'>
			<div className='bg-white w-screen h-screen flex justify-start items-start overflow-hidden'>
				<div
					className={` pb-12 bg-white border-r-2 border-gray-100 
						${!collapse ? 'min-w-md lg:min-w-lg w-1/4 xl:w-1/5' : 'w-20 lg:w-24'}
						`}
				>
					<Sidebar role={role} collapse={collapse} toggleLeftSidebar={toggleLeftSidebar} />
				</div>

				<div className={`flex flex-col ${collapse ? 'main-container' : 'w-3/4 xl:w-4/5'}`}>
					{/* <Header breadCrumbs={breadCrumbs} rightSidebarHide={rightSidebarHide} /> */}
					<div className='flex justify-start items-start mt-0'>
						<div className='p-3 w-full body h-screen'>
							<Suspense fallback={<div className='py-8 px-4 xl:px-8 bg-white w-full h-screen'>Yükleniyor...</div>}>
								<Component route={route} />
							</Suspense>
						</div>

						<div
							className={`border-l-2 border-gray-100 w-16 h-screen sidebar 
							`}
						>
							<div className='px-2'>
								<UsefulInformation />
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

const mapStateToProps = store => {
	return {
		navBarCollapseData: store.NavBar.collapse,

	};
};


export default withRouter(connect(mapStateToProps)(Dashboard));
