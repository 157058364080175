import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as PhoneConfirmation from '../apis/phoneConfirmation';
import { PhoneConfirmationAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherPhoneConfirmation() {
	yield takeLatest(types.GET_SMS_CODE, workerSmsCode);
	yield takeLatest(types.CHECK_SMS_CODE, workerCheckSmsCode);
}

function getSmsCode(req) {
	return PhoneConfirmation.getSmsCode(req);
}
function* workerSmsCode(action) {
	try {
		const response = yield call(getSmsCode, action.data);
		yield put(PhoneConfirmationAction.getSmsCodeSuccess(response.data.ResponseData.KeyExpireTime));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(PhoneConfirmationAction.getSmsCodeFailure());
	}
}

function checkSmsCode(req) {
	return PhoneConfirmation.checkSmsCode(req);
}
function* workerCheckSmsCode(action) {
	try {
		const response = yield call(checkSmsCode, action.data);
		if (response.data.ResponseStatus) {
			toast.success(response.data.ResponseMessage);
			yield put(PhoneConfirmationAction.checkSmsCodeSuccess(false));
			yield put(
				PhoneConfirmationAction.getUserPhoneConfirmSuccess(
					true
				),
			);
		} else {
			toast.error(response.data.ResponseMessage);
			yield put(PhoneConfirmationAction.checkSmsCodeSuccess(true));
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(PhoneConfirmationAction.checkSmsCodeFailure());
	}
}