import { apiCaller } from '../helpers/apiCaller';
import ConvertJsonToQueryString from '../helpers/convertJsonToQueryString';
export const getNestedTitles = sourceTypeId => {
	return apiCaller({ needToken: true }).get(
		`${process.env.REACT_APP_API_URL}/api/Book/getnestedtitles?${ConvertJsonToQueryString({ sourceTypeId })}`
	);
};

export const getSuggestedPlans = () => {
	return apiCaller({ needToken: true }).get(
		`${process.env.REACT_APP_API_URL}/api/Book/getsuggestedplans`
	);
};

export const getLectureContent = (data)=>{
	return apiCaller({needToken: true}).get(`${process.env.REACT_APP_API_URL}/api/Book/checklecturecontent?subCourseTopicUniqueId=${data}`)
}

export const getOfflineVideo = (data)=>{
	return apiCaller({needToken: true}).get(`${process.env.REACT_APP_API_URL}/api/subcoursetopicvideo/geturl?subCourseTopicUniqueId=${data}`)
}
export const getOnlineVideo = (data)=>{
	return apiCaller({needToken: true}).get(`${process.env.REACT_APP_API_URL}/api/onlinelecture/getv2?subCourseTopicUniqueId=${data}`)
}
export const getLecture = (data)=>{
	return apiCaller({needToken: true}).get(`${process.env.REACT_APP_API_URL}/api/Lecture/get?subCourseTopicUniqueId=${data}`)
}

export const getCourses = async (examCategoryId) => {
	return await apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/Book/coursesv2?examCategoryId=${examCategoryId}`);
}

export const getSubCourseTopics = async (postData) => {
	return await apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/Book/subcoursetopicsv2`, postData);
};

export const checkLectureContentv2 = id => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/Book/checklecturecontentv2?subCourseTopicUniqueId=${id}`);
}

export const getAchievements = (id)=>{
	return apiCaller({needToken: true}).get(`${process.env.REACT_APP_API_URL}/api/Book/getachievements?subCourseTopicUniqueId=${id}`)
}

export const getAchievementUserProgress = (postData)=>{
	return apiCaller({needToken: true}).post(`${process.env.REACT_APP_API_URL}/api/Book/getachievementuserprogress`,postData)
}

export const updateAchievementVideoUser = (postData)=>{
	return apiCaller({needToken: true}).post(`${process.env.REACT_APP_API_URL}/api/Book/updateachievementvideouser`,postData)
}

export const addAchievementVideoDuration = (postData)=>{
	return apiCaller({needToken: true}).post(`${process.env.REACT_APP_API_URL}/api/Book/addachievementvideoduration`,postData)
}

export const getAchievementVideoReward = (postData)=>{
	return apiCaller({needToken: true}).post(`${process.env.REACT_APP_API_URL}/api/Book/getachievementvideoreward`,postData)
}

export const getSubCourses = (examTypeId) => {
	return apiCaller({ needToken: true }, { isTeacher: true }).post(`${process.env.REACT_APP_API_URL}/api/Book/getsubCourses`, { examTypeId });
};

export const getAchievementRewardedGift = (subCourseTopicUniqueId) => {
	return apiCaller({ needToken: true }).get(`${process.env.REACT_APP_API_URL}/api/Book/achievementrewardedgift?subCourseTopicUniqueId=${subCourseTopicUniqueId}`);
};

export const getAchievementTestInstruction = (postData) => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/Book/getachievementtestinstruction`, postData);
}

export const getAchievementReward = (postData) => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/Book/getachievementreward`, postData);
}