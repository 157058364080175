import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as Admission from '../apis/admission';
import * as TrialExam from '../apis/trialExam';
import { AdmissionAction } from '../actions';
import { toast } from 'react-toastify';
import ExamSubType from '../enums/ExamSubType';

export function* watcherAdmission() {
	yield takeLatest(types.GET_YKS_POINT, workerAdmission);
	yield takeLatest(types.HANDLE_GRADE_POINT, workerGradePoint);
	yield takeLatest(types.SAVE_USER_RECORDS, workerSaveUserRecords);
	yield takeLatest(types.GET_LIST, workerGetList);
	yield takeLatest(types.POST_LIST, workerPostList);
	yield takeLatest(types.ADD_OPTION, workerAddOption);
	yield takeLatest(types.GET_OPTION, workerGetOption);
	yield takeLatest(types.GET_RESULT, workerGetResult);
	yield takeLatest(types.GET_UNIVERSITY_RANKS, workerGetUniversityRanks);
	yield takeLatest(types.GET_ALL_RANK, workerGetAllRank);
	yield takeLatest(types.GET_UNIVERSITY_DETAILS, workerGetUniversityDetails);
	yield takeLatest(types.GET_UNIVERSITY_CONDITION_DETAILS, workerGetUniversityConditionDetails);
}

function getYksPoint(data) {
	return Admission.getYksPoint(data);
}
function* workerAdmission(action) {
	try {
		const response = yield call(getYksPoint, action.payLoad);
		yield put(AdmissionAction.getYksPointSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AdmissionAction.getYksPointFailure());
	}
}

function saveGradePoint(data) {
	return TrialExam.saveOptions(data);
}
function* workerGradePoint(action) {
	try {
		const response = yield call(saveGradePoint, action.payLoad);
		yield put(AdmissionAction.saveGradePointSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AdmissionAction.saveGradePointFailure());
	}
}

function saveUserRecords(data) {
	return Admission.saveUserRecords(data);
}
function* workerSaveUserRecords(action) {
	try {
		const response = yield call(saveUserRecords, action.payLoad);
		yield put(AdmissionAction.saveUserRecordsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AdmissionAction.saveUserRecordsFailure());
	}
}

function getList() {
	return Admission.getList();
}
function* workerGetList() {
	try {
		const response = yield call(getList);
		yield put(AdmissionAction.getListSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AdmissionAction.getListFailure());
	}
}

function postList(data) {
	return Admission.postList(data);
}
function* workerPostList(action) {
	try {
		const response = yield call(postList, action.payLoad);
		yield put(AdmissionAction.postListSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AdmissionAction.postListFailure());
	}
}

function addOption(data) {
	return Admission.addOptionList(data);
}
function* workerAddOption(action) {
	try {
		const response = yield call(addOption, action.payLoad);
		yield put(AdmissionAction.addOptionSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AdmissionAction.addOptionFailure());
	}
}

function getOption() {
	return Admission.getOptionList();
}
function* workerGetOption() {
	try {
		const response = yield call(getOption);
		yield put(AdmissionAction.getOptionSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AdmissionAction.getOptionFailure());
	}
}

function getResult() {
	return Admission.getResult();
}
function* workerGetResult() {
	try {
		const response = yield call(getResult);
		yield put(AdmissionAction.getResultSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AdmissionAction.getResultFailure());
	}
}

function getUniversityRanks(data) {
	return Admission.getUniversityRanks(data);
}
function* workerGetUniversityRanks(action) {
	try {
		const response = yield call(getUniversityRanks, action.payLoad);
		yield put(AdmissionAction.getUniversityRanksSuccess(response.data));

		const records = response.data.map(item => {
			let score_type;
			switch (item.scoreType) {
				case ExamSubType.Sayısal:
					score_type = 'say';
					break;
				case ExamSubType.Sözel:
					score_type = 'soz';
					break;
				case ExamSubType.EA:
					score_type = 'ea';
					break;
				case ExamSubType.TYT:
					score_type = 'tyt';
					break;
				case ExamSubType.YDT:
				case ExamSubType.Dil:
					score_type = 'dil';
					break;
				case ExamSubType.LGS:
					score_type = 'lgs';
					break;
				default:
					score_type = '';
			}

			return {
				score_type: score_type,
				score_points: '0.00000',
				score_rank: item.rank,
			};
		});
		saveUserRecords({ records });
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(AdmissionAction.getUniversityRanksFailure());
	}
}

function getAllRank(data) {
	return Admission.getAllRank(data);
}
function* workerGetAllRank(action) {
	try {
		const response = yield call(getAllRank, action.payLoad);
		yield put(AdmissionAction.getAllRankSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		} else {
			toast.error('Sıralamanız getirilirken bir hata oluştu.');
		}
		yield put(AdmissionAction.getAllRankFailure());
	}
}

function getUniversityDetails(data) {
	return Admission.getUniversityDetails(data);
}
function* workerGetUniversityDetails(action) {
	try {
		const response = yield call(getUniversityDetails, action.payLoad);
		yield put(AdmissionAction.getUniversityDetailsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		} else {
			toast.error('Üniversite detayları getirilirken bir hata oluştu.');
		}
		yield put(AdmissionAction.getUniversityDetailsFailure());
	}
}

function getUniversityConditionDetails(data) {
	return Admission.getUniversityConditionDetails(data);
}
function* workerGetUniversityConditionDetails(action) {
	try {
		const {data: universityDetails} = yield call(getUniversityDetails, action.payLoad);
		const response = yield call(getUniversityConditionDetails, universityDetails.conditions);
		yield put(AdmissionAction.getUniversityConditionDetailsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		} else {
			toast.error('Üniversite detayları getirilirken bir hata oluştu.');
		}
		yield put(AdmissionAction.getUniversityConditionDetailsFailure());
	}
}
