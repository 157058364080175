import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as printableTest from '../apis/PrintableTest';
import { PrintableTestAction } from '../actions';
import { toast } from 'react-toastify';
import { History } from '../helpers/history';
import TestSectionTypes from '../enums/TestSectionTypes';
import { FlagHelper, FlagType } from '../helpers/FlagHelper';

export function* watcherPrintableTest() {
	yield takeLatest(types.GET_PRINTABLE_TEST_FILE, workerGetPrintableTestFile);
	yield takeLatest(types.GET_PRINTABLE_TEST_FILE_OPEN_NEW_TAB, workerGetPrintableTestFileOpenNewTab);
	yield takeLatest(types.GET_PARAMETERS, workerGetParameters);

}

function getPrintableTestFile(Code) {
	return printableTest.getPrintableTestFile({ Code });
}

function* workerGetPrintableTestFile(action) {
	const setFlagsToUpdateData = () => {
		FlagHelper.set(FlagType.TestSection, 'true');
		FlagHelper.set(FlagType.Solved, 'true');
		FlagHelper.set(FlagType.Dashboard, 'true');
		FlagHelper.set(FlagType.Statistics, 'true');
	};

	const { SubCourseTopicName, CourseName, testSectionType, Code } = action.payLoad;
	try {
		const response = yield call(getPrintableTestFile, Code);
		yield put(PrintableTestAction.getPrintableTestFileSuccess(response.data.Url));
		if (response.data.Url.includes('.pdf')) {
			setFlagsToUpdateData();
			localStorage.setItem('pdfURL', response.data.Url);
			if (SubCourseTopicName === 'KTT' || testSectionType === TestSectionTypes.KTT) {
				History.push({
					pathname: `/pdf-reader/${2}/${Code}`,
					state: { testSectionType: TestSectionTypes.KTT },
				});
			} else {
				History.push(`/pdf-reader/${2}/${Code}`);
			}
		} else {
			toast.error('Doğru kod girdiğinizden emin olun');
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(PrintableTestAction.getPrintableTestFileFailure());
	}
}


function getParameters(Code) {
	return printableTest.getParameters({ Code });
}

function* workerGetParameters(action) {
	const { Code } = action.payLoad;
	try {
		const response = yield call(getParameters, Code);
		yield put(PrintableTestAction.getParametersSuccess(response.data?.ResponseData));
		if(!response.data.ResponseData && response.data.ResponseMessage) {
			toast.error(response.data.ResponseMessage);
			yield put(PrintableTestAction.getParametersFailure(true));
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(PrintableTestAction.getParametersFailure());
	}
}

function getPrintableTestFileOpenNewTab(Code) {
	return printableTest.getPrintableTestFile({ Code });
}

function* workerGetPrintableTestFileOpenNewTab(action) {
	const { Code } = action.payLoad;
	try {
		const response = yield call(getPrintableTestFileOpenNewTab, Code);
		yield put(PrintableTestAction.getPrintableTestFileOpenNewTabSuccess(response.data.Url));
		window.open(response.data.Url, '_blank');
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(PrintableTestAction.getPrintableTestFileOpenNewTabFailure());
	}
}
