import * as types from '../constants/actionTypes';
import moment from 'moment';

const initialState = {
	data: [],
	addmissions: [],
	options: {},
};

const Tracking = (state = initialState, action) => {

	switch (action.type) {
		case types.ADD_TRACKING_ACTION:
			return {
				...state,
				data: [action.tracking, ...state.data].sort((a, b) => {
					return moment(b.ExamDate) - moment(a.ExamDate);
				}),
			};
		case types.UPDATE_TRACKING_ACTION:
			const newArr1 = state.data.filter((x) => x.ExamId !== action.tracking.ExamId);
			return {
				...state,
				data: [action.tracking, ...newArr1].sort((a, b) => {
					return moment(b.ExamDate) - moment(a.ExamDate);
				}),
			};
		case types.DELETE_TRACKING:
			const newArr = state.data.filter((x) => x.ExamId !== action.id);

			return {
				...state,
				data: newArr,
			};
		case types.SET_ALL:
			return {
				...state,
				data: action.data,
				options: action.options,
			};
		default:
			return state;
	}
};

export default Tracking;
