import * as React from "react";
const NoneOutlined = (props) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.651 20.909C12.294 21.0303 11.706 21.0303 11.349 20.909C8.304 19.9079 1.5 15.7315 1.5 8.65281C1.5 5.52809 4.1145 3 7.338 3C9.249 3 10.9395 3.88989 12 5.26517C13.0605 3.88989 14.7615 3 16.662 3C19.8855 3 22.5 5.52809 22.5 8.65281C22.5 15.7315 15.696 19.9079 12.651 20.909Z"
			stroke="#054EDD"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default NoneOutlined;
