import * as types from '../constants/actionTypes';
const initialState = {
	gptResponse: null,
};

const GptResponse = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.HANDLE_GPT_RESPONSE:
			return {
				...state,
				gptResponse: payLoad,
			};

		default:
			return state;
	}
};

export default GptResponse;
