import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Account from './account';
import Dashboard from './dashboard';
import UserStatistic from './userStatistic';
import DailyTask from './dailyTask';
import Book from './book';
import TestSection from './testSection';
import Question from './question';
import Publisher from './publisher';
import AccountProfile from './accountProfile';
import Lecture from './lecture';
import LectureSection from './lectureSection';
import PaymentReducer from './paymentReducer';
import KttSelectedTopicReducer from './kttSelectedTopics';
import KttReducer from './kttReducer';
import SchoolPanel from './schoolPanel';
import DatePicker from './datePicker';
import ExamType from './examType';
import NavBar from './navBar';
import GptResponse from './gptPrompt';
import Admission from './admission';
import TrialExam from './trialExam';
import Coaching from './coaching';
import PhoneConfirmation from './phoneConfirmation';
import TrialExams from './trialExams';
import Tracking from './tracking';
import Content from './content';
import PrintableTest from './printableTest';
import Courses from './courses';
import MediaFile from './mediaFile';

export default history =>
	combineReducers({
		router: connectRouter(history),
		Account,
		Dashboard,
		UserStatistic,
		DailyTask,
		Book,
		TestSection,
		Question,
		Publisher,
		AccountProfile,
		Lecture,
		LectureSection,
		PaymentReducer,
		KttSelectedTopicReducer,
		KttReducer,
		SchoolPanel,
		DatePicker,
		ExamType,
		NavBar,
		GptResponse,
		Admission,
		TrialExam,
		Coaching,
		PhoneConfirmation,
		TrialExams,
		Tracking,
		Content,
		PrintableTest,
		Courses,
		MediaFile,
	});
