import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as trialExamApi from '../apis/trialExam';
import * as trackingStatesApi from '../apis/TrackingStates';
import { TrialExamAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherTrialExam() {
	yield takeLatest(types.GET_TRACKING_OPTIONS, workerGetTrackingOptions);
	yield takeLatest(types.SAVE_TRACKING_OPTIONS, workerSaveTrackingOptions);
	yield takeLatest(types.GET_TRIAL_EXAM_OPTIONS, workerGetTrialExamOptions);
	yield takeLatest(types.GET_TRIAL_EXAM_RANKING, workerGetTrialExamRanking);
	yield takeLatest(types.GET_TRIAL_EXAM_SUMMARY_SESSIONS, workerGetTrialExamSummarySessions);
	yield takeLatest(types.GET_TRIAL_EXAMS_RANK, workerGetTrialExamsRank);
	yield takeLatest(types.GET_TRIAL_EXAM_SUMMARY, workerGetTrialExamSummary);
	yield takeLatest(types.GET_USER_POINTS, workerGetUserPoints);
	yield takeLatest(types.GET_TRACKING_LIST, workerGetTrackingList);
	yield takeLatest(types.SET_ONLINE_TRIAL_EXAM_STATUS, workerSetOnlineTrialExamStatus);
	yield takeLatest(types.GET_ONLINE_TRIAL_EXAM_PDFS, workerGetOnlineTrialExamPdfs);
}

function getTrackingOptions() {
	return trialExamApi.getOptions();
}
function* workerGetTrackingOptions() {
	try {
		const response = yield call(getTrackingOptions);
		yield put(TrialExamAction.getTrackingOptionsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TrialExamAction.getTrackingOptionsFailure());
	}
}

function saveTrackingOptions(data) {
	return trialExamApi.saveOptions(data);
}
function* workerSaveTrackingOptions(action) {
	try {
		const response = yield call(saveTrackingOptions, action.payLoad);
		yield put(TrialExamAction.saveTrackingOptionsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TrialExamAction.saverackingOptionsFailure());
	}
}

function getTrialExamOptions(data) {
	return trialExamApi.getTrialExamOptions(data);
}
function* workerGetTrialExamOptions(action) {
	try {
		const response = yield call(getTrialExamOptions, action.payLoad);
		yield put(TrialExamAction.getTrialExamOptionsSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TrialExamAction.getTrialExamOptionsFailure());
	}
}

function getTrialExamRanking(data) {
	return trialExamApi.getTrialExamRanking(data);
}
function* workerGetTrialExamRanking(action) {
	try {
		const response = yield call(getTrialExamRanking, action.payLoad);
		yield put(TrialExamAction.getTrialExamRankingSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TrialExamAction.getTrialExamRankingFailure());
	}
}

function getTrialExamSummarySessions(data) {
	return trialExamApi.getTrialExamSummarySessions(data);
}
function* workerGetTrialExamSummarySessions(action) {
	try {
		const response = yield call(getTrialExamSummarySessions, action.payLoad);
		yield put(TrialExamAction.getTrialExamSummarySessionsSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TrialExamAction.getTrialExamSummarySessionsFailure());
	}
}

function getTrialExamsRank(data) {
	return trialExamApi.getTrialExamsRank(data);
}
function* workerGetTrialExamsRank(action) {
	try {
		const response = yield call(getTrialExamsRank, action.payLoad);
		yield put(TrialExamAction.getTrialExamsRankSuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(TrialExamAction.getTrialExamsRankFailure());
	}
}

function getTrialExamSummary(data) {
	return trialExamApi.getTrialExamSummary(data);
}

function* workerGetTrialExamSummary(action) {
	try {
		const response = yield call(getTrialExamSummary, action.payLoad);
		yield put(TrialExamAction.getTrialExamSummarySuccess(response.data.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		} else if ( error )
			toast.error("Beklenmedik bir hata oluştu");
		yield put(TrialExamAction.getTrialExamSummaryFailure());
	}
}

function getUserPoints(data) {
	return trackingStatesApi.getUserPoints(data);
}

function* workerGetUserPoints(action) {
	try {
		const response = yield call(getUserPoints, action.payLoad);
		yield put(TrialExamAction.getUserPointsSuccess(response.data.ResponseData));
	} catch (error) {
		yield put(TrialExamAction.getUserPointsFailure());
	}
}

function getTrackingList(data) {
	return trackingStatesApi.getTrackingList(data);
}

function* workerGetTrackingList(action) {
	try {
		const response = yield call(getTrackingList, action.payLoad);
		yield put(TrialExamAction.getTrackingListSuccess(response.data));
	} catch (error) {
		yield put(TrialExamAction.getTrackingListFailure());
	}
}

function setOnlineTrialExamStatus(data) {
	return trialExamApi.getOnlineExamStatus(data);
}

function* workerSetOnlineTrialExamStatus(action) {
	try {
		const response = yield call(setOnlineTrialExamStatus, action.payLoad);
		yield put(TrialExamAction.setOnlineTrialExamStatusSuccess(response.data));
	} catch (error) {
		yield put(TrialExamAction.setOnlineTrialExamStatusFailure());
	}
}

function getOnlineTrialExamPdfs(data) {
	return trialExamApi.getOnlineTrialExamPdfs(data);
}

function* workerGetOnlineTrialExamPdfs(action) {
	try {
		const response = yield call(getOnlineTrialExamPdfs, action.payLoad);
		yield put(TrialExamAction.getOnlineTrialExamPdfsSuccess(response.data.ResponseData));
		yield put(TrialExamAction.setShowBookletListModal(true));
	} catch (error) {
		yield put(TrialExamAction.setShowBookletListModal(false));
		yield put(TrialExamAction.getOnlineTrialExamPdfsFailure());
	}
}
