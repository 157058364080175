export const setCookie = (cookieName, cookieValue, expireTime, domain, path) => {
	const date = new Date();
	date.setTime(date.getTime() + expireTime);
	const expires = 'expires=' + date.toUTCString();
	document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';domain=' + domain + ';path=' + path;
	document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';domain=' + domain + ';path=' + path;
};
export const getCookie = cookieName => {
	const name = cookieName + '=';
	const cookieArray = document.cookie.split(';');
	for (let i = 0; i < cookieArray.length; i++) {
		let cookie = cookieArray[i];
		while (cookie.charAt(0) === ' ') {
			cookie = cookie.substring(1);
		}
		if (cookie.indexOf(name) === 0) {
			return cookie.substring(name.length, cookie.length);
		}
	}
	return false;
};

export const deleteCookie = (cookieName, domains) => {
	const expirationDate = 'expires=Thu, 01 Jan 1970 00:00:00 GMT';
	const path = 'path=/';

	domains.forEach(domain => {
		document.cookie = `${cookieName}=; ${expirationDate}; domain=${domain}; ${path}`;
	});

	document.cookie = `${cookieName}=; ${expirationDate}; ${path}`;
};

