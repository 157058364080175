import React from 'react';
import { Header } from './style';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

export default withRouter(({ breadCrumbs }) => {
	return (
		<>
			<Header className='bg-white border-b-2 border-gray-100 h-16'>
				<div className='w-full h-16 border-b-2 border-gray-100 flex justify-between items-center'>
					<div className='ml-6 xl:ml-10 flex justify-start items-center'>
						{breadCrumbs &&
							breadCrumbs.map((crumbs, key) => {
								return (
									<div key={key} className='crumb-link'>
										{key !== 0 && <span className='text-black'>/</span>}{' '}
										{breadCrumbs.length === key + 1 ? (
											<span key={key} className='text-blue-800 text-md font-semibold'>
												{crumbs.name}
											</span>
										) : (
											<NavLink key={key} className='text-gray-400 text-md font-semibold' to={crumbs.path}>
												{crumbs.name}
											</NavLink>
										)}
									</div>
								);
							})}
					</div>
				</div>
			</Header>
		</>
	);
});
