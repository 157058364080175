import * as types from '../constants/actionTypes';
const initialState = {
	getAllPublishers: {
		loading: false,
		data: [],
	},
};

const Publisher = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.GET_ALL_PUBLISHERS_REQUEST:
			return {
				...state,
				getAllPublishers: { ...state.getAllPublishers, loading: true },
			};
		case types.GET_ALL_PUBLISHERS_SUCCESS:
			return {
				...state,
				getAllPublishers: { data: payload, loading: false },
			};
		case types.GET_ALL_PUBLISHERS_FAILURE:
			return {
				...state,
				getAllPublishers: { ...state.getAllPublishers, loading: false },
			};
		default:
			return state;
	}
};

export default Publisher;
