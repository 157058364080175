import * as types from '../constants/actionTypes';
import ExamCategoryType from '../enums/ExamCategoryType';
const initialState = {
	getPublisherRates: {
		loading: false,
		data: [],
	},
	updatePublisherRates: {
		loading: false,
		data: [],
	},
	changePassword: {
		loading: false,
		data: [],
		showPopup: false,
	},
	getProfileInfo: {
		loading: false,
		data: {UniversityExams: []},
	},
	updateProfile: {
		loading: false,
		data: [],
		showPopup: false,
	},
	userExamCategory: {
		loading: false,
		data: ExamCategoryType.None,
	}
};

const AccountProfile = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.GET_PUBLISHER_RATES_REQUEST:
			return {
				...state,
				getPublisherRates: { ...state.getPublisherRates, loading: true },
			};
		case types.GET_PUBLISHER_RATES_SUCCESS:
			return {
				...state,
				getPublisherRates: { data: payload, loading: false },
			};
		case types.GET_PUBLISHER_RATES_FAILURE:
			return {
				...state,
				getPublisherRates: { data: payload, loading: false },
			};

		case types.UPDATE_PUBLISHER_RATES_REQUEST:
			return {
				...state,
				updatePublisherRates: { data: [], loading: true },
			};
		case types.UPDATE_PUBLISHER_RATES_SUCCESS:
			return {
				...state,
				updatePublisherRates: { data: payload, loading: false },
			};
		case types.UPDATE_PUBLISHER_RATES_FAILURE:
			return {
				...state,
				updatePublisherRates: { data: [], loading: false },
			};

		case types.CHANGE_PASSWORD_REQUEST:
			return {
				...state,
				changePassword: { data: [], loading: true },
			};
		case types.CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				changePassword: { data: payload, showPopup: true, loading: false },
			};
		case types.CHANGE_PASSWORD_FAILURE:
			return {
				...state,
				changePassword: { data: [], showPopup: false, loading: false },
			};

		case types.GET_PROFILE_INFO_REQUEST:
			return {
				...state,
				getProfileInfo: { data: [], loading: true },
			};
		case types.GET_PROFILE_INFO_SUCCESS:
			return {
				...state,
				getProfileInfo: { data: payload, loading: false },
			};
		case types.GET_PROFILE_INFO_FAILURE:
			return {
				...state,
				getProfileInfo: { data: [], loading: false },
			};

		case types.UPDATE_PROFILE_REQUEST:
			return {
				...state,
				updateProfile: { data: [], loading: true, showPopup: false },
			};

		case types.UPDATE_PROFILE_SUCCESS:
			return {
				...state,
				updateProfile: { data: payload, showPopup: true, loading: false },
			};
		case types.UPDATE_PROFILE_FAILURE:
			return {
				...state,
				updateProfile: { data: [], showPopup: false, loading: false },
			};

		case types.UPDATE_USER_EXAM_CATEGORY:
			return {
				...state,
				userExamCategory: { loading: true },
			};

		case types.UPDATE_USER_EXAM_CATEGORY_SUCCESS:
			return {
				...state,
				userExamCategory: { data: payload, loading: false },
			};

		case types.UPDATE_USER_EXAM_CATEGORY_FAILURE:
			return {
				...state,
				userExamCategory: { data: ExamCategoryType.None, loading: false },
			};

		default:
			return state;
	}
};

export default AccountProfile;
