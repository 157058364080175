import { KttActions } from "../constants/actionTypes";

const initialState = {
  KttSelectedTopicList: [],
  selectedCourse: {},
};

const KttReducer = (state = initialState, action) => {
  switch (action.type) {
    case KttActions.SET_KTT_TOPIC_LIST:
      return {
        ...state,
        KttSelectedTopicList: action.payload,
      };
    case KttActions.SET_KTT_SELECTED_COURSE:
      return {
        ...state,
        selectedCourse: action.payload,
      };
    default:
      return state;
  }
};

export default KttReducer;
