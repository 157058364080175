import * as types from '../constants/actionTypes';
const initialState = {
	getDashboardInfo: {
		loading: false,
		data: [],
	},
};

const Dashboard = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_DASHBOARD_INFO_REQUEST:
			return {
				...state,
				getDashboardInfo: { ...state.getDashboardInfo, loading: true },
			};
		case types.GET_DASHBOARD_INFO_SUCCESS:
			return { ...state, getDashboardInfo: { data: payLoad, loading: false } };
		case types.GET_DASHBOARD_INFO_FAILURE:
			return {
				...state,
				getDashboardInfo: { ...state.getDashboardInfo, loading: false },
			};

		default:
			return state;
	}
};

export default Dashboard;
