import * as types from '../constants/actionTypes';

const initialState = {
	getAllCoaches: {
		loading: false,
		data: [],
	},
	canUserTakeCoach: {
		loading: false,
		canTakeCoach: false,
	},
	isUserCoachExist: {
		coachExist: false,
	},
	isTakeSeatSuccess: {
		loading: false,
		takeSeat: false,
	},
	tutorials: {
		loading: false,
		data: [],
	},
};

const Coaches = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_ALL_COACHES:
			return {
				...state,
				getAllCoaches: { ...state.getAllCoaches, loading: true },
			};

		case types.GET_ALL_COACHES_SUCCESS:
			return {
				...state,
				getAllCoaches: { data: payLoad, loading: false },
			};

		case types.GET_ALL_COACHES_FAILURE:
			return {
				...state,
				getAllCoaches: { data: [], loading: false },
			};

		case types.GET_USER_CAN_TAKE_COACH:
			return {
				...state,
				canUserTakeCoach: { ...state.canUserTakeCoach, loading: true },
			};

		case types.GET_USER_CAN_TAKE_COACH_SUCCESS:
			return {
				...state,
				canUserTakeCoach: { canTakeCoach: payLoad, loading: false },
			};

		case types.GET_USER_CAN_TAKE_COACH_FAILURE:
			return {
				...state,
				canUserTakeCoach: { canTakeCoach: false, loading: false },
			};

		case types.GET_USER_HAS_COACH:
			return {
				...state,
				isUserCoachExist: { ...state.isUserCoachExist, loading: true },
			};

		case types.GET_USER_HAS_COACH_SUCCESS:
			return {
				...state,
				isUserCoachExist: { loading: false, coachExist: payLoad },
			};

		case types.GET_USER_HAS_COACH_FAILURE:
			return {
				...state,
				isUserCoachExist: { loading: false, coachExist: false },
			};

		case types.TAKE_SEAT:
			return {
				...state,
				isTakeSeatSuccess: { ...state.isTakeSeatSuccess, loading: true },
			};

		case types.TAKE_SEAT_SUCCESS:
			return {
				...state,
				isTakeSeatSuccess: { loading: false, takeSeat: payLoad },
			};

		case types.TAKE_SEAT_FAILURE:
			return {
				...state,
				isTakeSeatSuccess: { loading: false, takeSeat: false },
			};

		case types.GET_TUTORIAL:
			return {
				...state,
				tutorials: { ...state.tutorials, loading: true },
			};

		case types.GET_TUTORIAL_SUCCESS:
			return {
				...state,
				tutorials: { loading: false, data: payLoad },
			};

		case types.GET_TUTORIAL_FAILURE:
			return {
				...state,
				tutorials: { loading: false, data: [] },
			};

		default:
			return state;
	}
};

export default Coaches;