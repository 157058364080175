import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as publisherApi from '../apis/publishers';
import { PublisherAction, AccountProfileAction } from '../actions';
import { toast } from 'react-toastify';
import { getLocalStorageWithExpiry } from '../helpers/storage';

export function* watcherGetAllPublishers() {
	yield takeLatest(types.GET_ALL_PUBLISHERS_REQUEST, workerGetAllPublishers);
}

function getAllPublishers() {
	return publisherApi.getAllPublishers();
}

function* workerGetAllPublishers() {
	try {
		
		const getPublisherRatesPostData = {
			UserId: getLocalStorageWithExpiry('userId').toString()
		};
		yield put(AccountProfileAction.getPublisherRates(getPublisherRatesPostData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(PublisherAction.getAllPublishersFailure());
		yield put(AccountProfileAction.getPublisherRatesFailure());
	}
}
