import { apiCaller } from '../helpers/apiCaller';
import { getToken } from '../helpers/tokenManager';

export const getYksPoint = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/trackingexam/getykspoint`, data);
};

export const saveUserRecords = async data => {
	const studentToken = localStorage.getItem('studentToken');
	let token;
	if (studentToken) {
		token = studentToken;
	} else {
		token = getToken('pakodemyToken');
	}
	const req = {
		Token: token,
		Api: `${process.env.REACT_APP_ADMISSION_API_URL}/api/record/add/bulk`,
		Method: 'POST',
		RequestBody: JSON.stringify(data),
	};
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/admissionapi/send`, req);
};

// export const saveUserRecords = data => {
// 	return apiCaller({ needToken: true }).post(`/api/record/add/bulk`, data);
// };

export const getList = async () => {
	const studentToken = localStorage.getItem('studentToken');
	let token;
	if (studentToken) {
		token = studentToken;
	} else {
		token = getToken('pakodemyToken');
	}
	const req = {
		Token: token,
		Api: `${process.env.REACT_APP_ADMISSION_API_URL}/api/record/list`,
		Method: 'GET',
		RequestBody: '',
	};
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/admissionapi/send`, req);
};

// export const getList = () => {
// 	return apiCaller({ needToken: true }).get(`/api/record/list`);
// };

export const postList = async data => {
	const studentToken = localStorage.getItem('studentToken');
	let token;
	if (studentToken) {
		token = studentToken;
	} else {
		token = getToken('pakodemyToken');
	}
	const req = {
		Token: token,
		Api: `${process.env.REACT_APP_ADMISSION_API_URL}/api/program/list`,
		Method: 'POST',
		RequestBody: JSON.stringify(data),
	};
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/admissionapi/send`, req);
};

// export const postList = data => {
// 	return apiCaller({ needToken: true }).post(`/api/program/list`, data);
// };

export const getOptionList = async () => {
	const studentToken = localStorage.getItem('studentToken');
	let token;
	if (studentToken) {
		token = studentToken;
	} else {
		token = getToken('pakodemyToken');
	}
	const req = {
		Token: token,
		Api: `${process.env.REACT_APP_ADMISSION_API_URL}/api/option/list`,
		Method: 'GET',
		RequestBody: '',
	};
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/admissionapi/send`, req);
};

// export const getOptionList = () => {
// 	return apiCaller({ needToken: true }).get(`/api/option/list`);
// };

export const addOptionList = async data => {
	const studentToken = localStorage.getItem('studentToken');
	let token;
	if (studentToken) {
		token = studentToken;
	} else {
		token = getToken('pakodemyToken');
	}
	const req = {
		Token: token,
		Api: `${process.env.REACT_APP_ADMISSION_API_URL}/api/option/add/bulk`,
		Method: 'POST',
		RequestBody: JSON.stringify(data),
	};
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/admissionapi/send`, req);
};

// export const addOptionList = (data) => {
// 	return apiCaller({ needToken: true }).post(`/api/option/add/bulk`, data);
// };

export const getResult = async () => {
	const studentToken = localStorage.getItem('studentToken');
	let token;
	if (studentToken) {
		token = studentToken;
	} else {
		token = getToken('pakodemyToken');
	}
	const req = {
		Token: token,
		Api: `${process.env.REACT_APP_ADMISSION_API_URL}/api/result/complex?apiversion=2`,
		Method: 'GET',
		RequestBody: '',
	};
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/admissionapi/send`, req);
};

// export const getResult = () => {
// 	return apiCaller({ needToken: true }).get(`/api/result/complex?apiversion=2`);
// };

export const getUniversityRanks = (data) => {
	return apiCaller({ needToken: true }).post(`/api/university/ranks`, data);
};

// export const getUniversityRanks = async data => {
// 	const studentToken = localStorage.getItem('studentToken');
// 	let token;
// 	if (studentToken) {
// 		token = studentToken;
// 	} else {
// 		token = getToken('pakodemyToken');
// 	}
// 	const req = {
// 		Token: token,
// 		Api: 'https://admission-info.tosanalytics.com/api/university/ranks',
// 		Method: 'POST',
// 		RequestBody: JSON.stringify(data),
// 	};
// 	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/admissionapi/send`, req);
// };

export const getAllRank = (data) => {
	return apiCaller({ needToken: true }).post(`/api/university/getallrank`, data);
};

export const getUniversityDetails = async code => {
	const studentToken = localStorage.getItem('studentToken');
	let token;
	if (studentToken) {
		token = studentToken;
	} else {
		token = getToken('pakodemyToken');
	}
	const req = {
		Token: token,
		Api: `${process.env.REACT_APP_ADMISSION_INFO_API_URL}/api/university/details?code=${code}`,
		Method: 'GET',
		RequestBody: JSON.stringify(code),
	};
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/admissionapi/send`, req);
};

export const getUniversityConditionDetails = async conditions => {
	const studentToken = localStorage.getItem('studentToken');
	let token;
	if (studentToken) {
		token = studentToken;
	} else {
		token = getToken('pakodemyToken');
	}
	const req = {
		Token: token,
		Api: `${process.env.REACT_APP_ADMISSION_INFO_API_URL}/api/university/conditions?condition_number=${conditions}`,
		Method: 'GET',
		RequestBody: JSON.stringify(conditions),
	};
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/admissionapi/send`, req);
};