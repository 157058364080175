import { all } from 'redux-saga/effects';
import { watcherAccount } from './account';
import { watcherDashboard } from './dashboard';
import { watcherUserStatistic } from './userStatistic';
import { watcherDailyTask } from './dailyTask';
import { watcherBook } from './book';
import { watcherTestSection } from './testSection';
import { watcherGetAllPublishers } from './publisher';
import { watcherUpdatePublisherRate } from './accountProfile';
import { watcherLecture } from './lecture';
import { watcherLectureSection } from './lectureSection';
import { watcherSchoolPanel } from './schoolPanel';
import { watcherDatePicker } from './datePicker';
import { watcherExamType } from './examType';
import { watcherNavBar } from './navBar';
import { watcherGptResponse } from './gptResponse';
import { watcherAdmission } from './admission';
import { watcherTrialExam } from './trialExam';
import { watcherCoaching } from './coaching';
import { watcherPhoneConfirmation } from './phoneConfirmation';
import { watcherContent } from './content';
import { watcherPrintableTest } from './printableTest';
import {watcherCourses} from './courses';
import {watcherMediaFile} from './mediaFile';

export default function* rootSaga() {
	yield all([
		watcherAccount(),
		watcherDashboard(),
		watcherUserStatistic(),
		watcherDailyTask(),
		watcherBook(),
		watcherTestSection(),
		watcherGetAllPublishers(),
		watcherUpdatePublisherRate(),
		watcherLecture(),
		watcherLectureSection(),
		watcherSchoolPanel(),
		watcherDatePicker(),
		watcherExamType(),
		watcherNavBar(),
		watcherGptResponse(),
		watcherAdmission(),
		watcherTrialExam(),
		watcherCoaching(),
		watcherPhoneConfirmation(),
		watcherContent(),
		watcherPrintableTest(),
		watcherCourses(),
		watcherMediaFile(),
	]);
}
